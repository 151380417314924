import React from "react";
import styled from "styled-components";

import { fixNumber } from "../utils/utils";

import { NavLink } from "react-router-dom";

import { shade } from "polished";

const Container = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  //border: 1px solid black;

  box-sizing: border-box;
`;

/*const Label = styled.p`
  width: 100%;
  margin: 0;
  padding: 20px 0;
  font-weight: bold;
  text-align: center;
  word-break: break-word;
  font-family: "Inconsolata", monospace;
`;*/

const Button = styled.button`
  width: 100%;
  border: none;
  background-color: #544bff;
  color: white;
  padding: 21px 10px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#544bff")};
  }

  @media (max-width: 1232px) {
    font-size: 0.6rem;
  }

  @media (max-width: 1200px) {
    font-size: 0.7rem;
  }
`;

const Table = styled.table`
  margin-top: 2%;
  width: 100%;
  border-bottom: 0px;
  margin-bottom: 30px;
  border: 0.5px solid #000000;
  border-collapse: collapse;
  box-shadow: 5px 5px 10px #00000029;
`;

const Ths = styled.td`
  font-size: 1rem;
  font-weight: bold;
  padding: 20px 5px;
  text-align: center;
  border: 0.5px solid #000000;
  border-collapse: collapse;
  font-family: "Poppins", sans-serif;
  text-align: left;

  @media (max-width: 1200px) {
    font-size: 0.7rem;
  }
`;

const Rows = styled.td`
  font-size: 1rem;
  padding: 10px 10px;
  word-wrap: break-word;
  text-align: left;
  border: 0.5px solid #000000;
  border-collapse: collapse;
  font-family: "Inconsolata", monospace;

  @media (max-width: 1200px) {
    font-size: 0.7rem;
  }
`;

/**
 * Cria lista de informação de usuários
 * @param {any} data Resposta da requisição
 * @param {number} mapIndex Index no mapeamento
 */
const DetailsUserTransactions = ({ tableData }) => {
  return (
    <Container>
      <Table>
        <thead>
          <Ths>Valor Contribuido</Ths>
          <Ths>Data</Ths>
          <Ths>Recompensa</Ths>
          <Ths></Ths>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <>
                <Rows>R$ {fixNumber(row.valor_contribuicao.toFixed(2))}</Rows>
                <Rows>{row.data_contribuicao}</Rows>
                <Rows>{row.recompensa_contribuicao}</Rows>
                <Rows>
                  <NavLink
                    to={`/transacoes/${row.id_contribuicao}`}
                    exact={true}
                  >
                    <Button>Ver Transação</Button>
                  </NavLink>
                </Rows>
              </>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default DetailsUserTransactions;

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import WebPaymentPage from "./Pages/WebPaymentPage";
import MemberTransactions from "./Pages/MemberTransactions";
import AllTransactions from "./Pages/AllTransactions";
import Login from "./Pages/Login";
import MemberTransactionsInfo from "./Pages/MemberTransactionsInfo";
import EditProfileMember from "./Pages/EditProfileMember";
import EditProfileStaff from "./Pages/EditProfileStaff";
import EditSubscription from "./Pages/EditSubscription";
import SubscriptionCard from "./Pages/SubscriptionCard";
import SearchMembers from "./Pages/SearchMembers";
import MemberTransactionsInfoStaff from "./Pages/MemberTransactionsInfoStaff";
import AcessControl from "./Pages/AcessControl";
import EditProfileMemberByStaff from "./Pages/EditProfileMemberByStaff";
import Users from "./Pages/Users";
import UsersInfo from "./Pages/UsersInfo";
import Staffs from "./Pages/Staffs";
import StaffInfo from "./Pages/StaffInfo";
import Custom from "./Pages/Custom";
import EditReward from "./Pages/EditReward";
import CreateReward from "./Pages/CreateReward";
import EditUpdate from "./Pages/EditUpdate";
import CreateUpdate from "./Pages/CreateUpdate";
import Settings from "./Pages/Settings";
import Finances from "./Pages/Finances";
import Withdrawal from "./Pages/Withdrawal";
import BlockTransactions from "./Pages/BlockTransactions";
import Logout from "./Pages/Logout";
import PaymentSuccess from "./Pages/PaymentSuccess";
import UserEntries from "./Pages/UserEntries";
import Dashboard from "./Pages/Dashboard";

import Main from "./Main";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticate = localStorage.getItem("token_access");
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticate ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/parceiro/:project_id" component={WebPaymentPage} exact />
      <Route
        path="/paymentSuccess/:language"
        component={PaymentSuccess}
        exact
      />
      <PrivateRoute path="/" component={Main} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);

export const RoutesMember = ({ userObjectData }) => (
  <>
    <PrivateRoute
      path="/"
      component={
        userObjectData["type_user"] === "member"
          ? MemberTransactions
          : MemberTransactions /* AllTransactions - O que eu estava mexendo antes **/
      }
      exact
    />
    <PrivateRoute path="/carteirinha" component={SubscriptionCard} exact />
    <PrivateRoute path="/busca-cpf" component={SearchMembers} exact />
    <PrivateRoute path="/entradas" component={AcessControl} exact />
    <PrivateRoute path="/entradas/:entry_id" component={UserEntries} exact />
    <PrivateRoute path="/usuarios" component={Users} exact />
    <PrivateRoute path="/usuarios/:user_id" component={UsersInfo} exact />
    <PrivateRoute path="/colaboradores" component={Staffs} exact />
    <PrivateRoute path="/colaboradores/:user_id" component={StaffInfo} exact />
    <PrivateRoute
      path="/editar-perfil/:user_id"
      component={EditProfileMemberByStaff}
      exact
    />
    <PrivateRoute path="/editar-apoio" component={EditSubscription} exact />
    <PrivateRoute path="/customizacao" component={Custom} exact />
    <PrivateRoute path="/configuracoes" component={Settings} exact />
    <PrivateRoute path="/financeiro" component={Finances} exact />
    <PrivateRoute
      path="/customizacao/recompensa/:reward_id"
      component={EditReward}
      exact
    />
    <PrivateRoute
      path="/customizacao/criar-recompensa"
      component={CreateReward}
      exact
    />
    <PrivateRoute
      path="/customizacao/atualizacoes/:update_id"
      component={EditUpdate}
      exact
    />
    <PrivateRoute
      path="/customizacao/criar-atualizacao"
      component={CreateUpdate}
      exact
    />
    <PrivateRoute path="/financeiro/saque" component={Withdrawal} exact />
    <PrivateRoute
      path="/financeiro/bloqueadas"
      component={BlockTransactions}
      exact
    />
    <PrivateRoute
      path="/transacoes"
      component={
        userObjectData["type_user"] === "member"
          ? MemberTransactions
          : MemberTransactions /* AllTransactions - O que eu estava mexendo antes **/
      }
      exact
    />
    <PrivateRoute
      path="/transacoes/:transactions_id"
      component={
        userObjectData["type_user"] === "member"
          ? MemberTransactionsInfo
          : MemberTransactionsInfoStaff
      }
      exact
    />
    <PrivateRoute
      path="/editar-perfil"
      component={
        userObjectData["type_user"] === "member"
          ? EditProfileMember
          : EditProfileStaff
      }
      exact
    />
    <Route path="/logout" component={Logout} exact />
    {/*<Redirect to="/editar-perfil" />*/}
  </>
);

export default Routes;

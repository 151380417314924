import axios from "axios";

import { apiUrl } from "../config.json";

const tokenKey = "token_access";

const getCurrentUser = async () => {
  try {
    const user = await axios.get(apiUrl + "/me/essential-member", {
      params: {
        access_token: localStorage.getItem(tokenKey),
      },
    });
    return user.data;
  } catch (error) {
    return null;
  }
};

const logout = async () => {
  localStorage.removeItem(tokenKey);
};

const login = async (username, password) => {
  const data = {
    grant_type: "password",
    client_id: "aAqZrDs08biTilyGqxaFiex5gzdPGZnpu9tbO7y2",
    client_secret:
      "6q1b8ehlSAd4SxXU2bamryFWuAR9ODOOuu2FKFv93N4T1EUWJKmPeMLfpVwaaNEqAzKJSZ9QturyUx54vo1mCJXnaj99MsLHKXOTaY8tRo1ZGQqfR7GLpjiO0DqO4Dqz",
    username: username,
    password: password,
  };

  const token = await axios.post(
    apiUrl + "/auth/token/",
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  localStorage.setItem(tokenKey, token.data.access_token);

  const user = await getCurrentUser();

  if (!user) {
    logout();
    throw "Usuário não autorizado";
  }
  return user;
};

export default {
  logout,
  login,
  getCurrentUser,
};

const countries_json = [
  { id: "AF", country: "Afghanistan" },
  { id: "AX", country: "Åland Islands" },
  { id: "AL", country: "Albania" },
  { id: "DZ", country: "Algeria" },
  { id: "AS", country: "American Samoa" },
  { id: "AD", country: "Andorra" },
  { id: "AO", country: "Angola" },
  { id: "AI", country: "Anguilla" },
  { id: "AQ", country: "Antarctica" },
  { id: "AG", country: "Antigua and Barbuda" },
  { id: "AR", country: "Argentina" },
  { id: "AM", country: "Armenia" },
  { id: "AW", country: "Aruba" },
  { id: "AU", country: "Australia" },
  { id: "AT", country: "Austria" },
  { id: "AZ", country: "Azerbaijan" },
  { id: "BS", country: "Bahamas" },
  { id: "BH", country: "Bahrain" },
  { id: "BD", country: "Bangladesh" },
  { id: "BB", country: "Barbados" },
  { id: "BY", country: "Belarus" },
  { id: "BE", country: "Belgium" },
  { id: "BZ", country: "Belize" },
  { id: "BJ", country: "Benin" },
  { id: "BM", country: "Bermuda" },
  { id: "BT", country: "Bhutan" },
  { id: "BO", country: "Bolivia, Plurinational State of" },
  { id: "BQ", country: "Bonaire, Sint Eustatius and Saba" },
  { id: "BA", country: "Bosnia and Herzegovina" },
  { id: "BW", country: "Botswana" },
  { id: "BV", country: "Bouvet Island" },
  { id: "BR", country: "Brasil" },
  { id: "IO", country: "British Indian Ocean Territory" },
  { id: "BN", country: "Brunei Darussalam" },
  { id: "BG", country: "Bulgaria" },
  { id: "BF", country: "Burkina Faso" },
  { id: "BI", country: "Burundi" },
  { id: "KH", country: "Cambodia" },
  { id: "CM", country: "Cameroon" },
  { id: "CA", country: "Canada" },
  { id: "CV", country: "Cape Verde" },
  { id: "KY", country: "Cayman Islands" },
  { id: "CF", country: "Central African Republic" },
  { id: "TD", country: "Chad" },
  { id: "CL", country: "Chile" },
  { id: "CN", country: "China" },
  { id: "CX", country: "Christmas Island" },
  { id: "CC", country: "Cocos (Keeling) Islands" },
  { id: "CO", country: "Colombia" },
  { id: "KM", country: "Comoros" },
  { id: "CG", country: "Congo" },
  { id: "CD", country: "Congo, the Democratic Republic of the" },
  { id: "CK", country: "Cook Islands" },
  { id: "CR", country: "Costa Rica" },
  { id: "CI", country: "Côte d'Ivoire" },
  { id: "HR", country: "Croatia" },
  { id: "CU", country: "Cuba" },
  { id: "CW", country: "Curaçao" },
  { id: "CY", country: "Cyprus" },
  { id: "CZ", country: "Czech Republic" },
  { id: "DK", country: "Denmark" },
  { id: "DJ", country: "Djibouti" },
  { id: "DM", country: "Dominica" },
  { id: "DO", country: "Dominican Republic" },
  { id: "EC", country: "Ecuador" },
  { id: "EG", country: "Egypt" },
  { id: "SV", country: "El Salvador" },
  { id: "GQ", country: "Equatorial Guinea" },
  { id: "ER", country: "Eritrea" },
  { id: "EE", country: "Estonia" },
  { id: "ET", country: "Ethiopia" },
  { id: "FK", country: "Falkland Islands (Malvinas)" },
  { id: "FO", country: "Faroe Islands" },
  { id: "FJ", country: "Fiji" },
  { id: "FI", country: "Finland" },
  { id: "FR", country: "France" },
  { id: "GF", country: "French Guiana" },
  { id: "PF", country: "French Polynesia" },
  { id: "TF", country: "French Southern Territories" },
  { id: "GA", country: "Gabon" },
  { id: "GM", country: "Gambia" },
  { id: "GE", country: "Georgia" },
  { id: "DE", country: "Germany" },
  { id: "GH", country: "Ghana" },
  { id: "GI", country: "Gibraltar" },
  { id: "GR", country: "Greece" },
  { id: "GL", country: "Greenland" },
  { id: "GD", country: "Grenada" },
  { id: "GP", country: "Guadeloupe" },
  { id: "GU", country: "Guam" },
  { id: "GT", country: "Guatemala" },
  { id: "GG", country: "Guernsey" },
  { id: "GN", country: "Guinea" },
  { id: "GW", country: "Guinea-Bissau" },
  { id: "GY", country: "Guyana" },
  { id: "HT", country: "Haiti" },
  { id: "HM", country: "Heard Island and McDonald Islands" },
  { id: "VA", country: "Holy See (Vatican City State)" },
  { id: "HN", country: "Honduras" },
  { id: "HK", country: "Hong Kong" },
  { id: "HU", country: "Hungary" },
  { id: "IS", country: "Iceland" },
  { id: "IN", country: "India" },
  { id: "ID", country: "Indonesia" },
  { id: "IR", country: "Iran, Islamic Republic of" },
  { id: "IQ", country: "Iraq" },
  { id: "IE", country: "Ireland" },
  { id: "IM", country: "Isle of Man" },
  { id: "IL", country: "Israel" },
  { id: "IT", country: "Italy" },
  { id: "JM", country: "Jamaica" },
  { id: "JP", country: "Japan" },
  { id: "JE", country: "Jersey" },
  { id: "JO", country: "Jordan" },
  { id: "KZ", country: "Kazakhstan" },
  { id: "KE", country: "Kenya" },
  { id: "KI", country: "Kiribati" },
  { id: "KP", country: "Korea, Democratic People's Republic of" },
  { id: "KR", country: "Korea, Republic of" },
  { id: "KW", country: "Kuwait" },
  { id: "KG", country: "Kyrgyzstan" },
  { id: "LA", country: "Lao People's Democratic Republic" },
  { id: "LV", country: "Latvia" },
  { id: "LB", country: "Lebanon" },
  { id: "LS", country: "Lesotho" },
  { id: "LR", country: "Liberia" },
  { id: "LY", country: "Libya" },
  { id: "LI", country: "Liechtenstein" },
  { id: "LT", country: "Lithuania" },
  { id: "LU", country: "Luxembourg" },
  { id: "MO", country: "Macao" },
  { id: "MK", country: "Macedonia, the former Yugoslav Republic of" },
  { id: "MG", country: "Madagascar" },
  { id: "MW", country: "Malawi" },
  { id: "MY", country: "Malaysia" },
  { id: "MV", country: "Maldives" },
  { id: "ML", country: "Mali" },
  { id: "MT", country: "Malta" },
  { id: "MH", country: "Marshall Islands" },
  { id: "MQ", country: "Martinique" },
  { id: "MR", country: "Mauritania" },
  { id: "MU", country: "Mauritius" },
  { id: "YT", country: "Mayotte" },
  { id: "MX", country: "Mexico" },
  { id: "FM", country: "Micronesia, Federated States of" },
  { id: "MD", country: "Moldova, Republic of" },
  { id: "MC", country: "Monaco" },
  { id: "MN", country: "Mongolia" },
  { id: "ME", country: "Montenegro" },
  { id: "MS", country: "Montserrat" },
  { id: "MA", country: "Morocco" },
  { id: "MZ", country: "Mozambique" },
  { id: "MM", country: "Myanmar" },
  { id: "NA", country: "Namibia" },
  { id: "NR", country: "Nauru" },
  { id: "NP", country: "Nepal" },
  { id: "NL", country: "Netherlands" },
  { id: "NC", country: "New Caledonia" },
  { id: "NZ", country: "New Zealand" },
  { id: "NI", country: "Nicaragua" },
  { id: "NE", country: "Niger" },
  { id: "NG", country: "Nigeria" },
  { id: "NU", country: "Niue" },
  { id: "NF", country: "Norfolk Island" },
  { id: "MP", country: "Northern Mariana Islands" },
  { id: "NO", country: "Norway" },
  { id: "OM", country: "Oman" },
  { id: "PK", country: "Pakistan" },
  { id: "PW", country: "Palau" },
  { id: "PS", country: "Palestinian Territory, Occupied" },
  { id: "PA", country: "Panama" },
  { id: "PG", country: "Papua New Guinea" },
  { id: "PY", country: "Paraguay" },
  { id: "PE", country: "Peru" },
  { id: "PH", country: "Philippines" },
  { id: "PN", country: "Pitcairn" },
  { id: "PL", country: "Poland" },
  { id: "PT", country: "Portugal" },
  { id: "PR", country: "Puerto Rico" },
  { id: "QA", country: "Qatar" },
  { id: "RE", country: "Réunion" },
  { id: "RO", country: "Romania" },
  { id: "RU", country: "Russian Federation" },
  { id: "RW", country: "Rwanda" },
  { id: "BL", country: "Saint Barthélemy" },
  { id: "SH", country: "Saint Helena, Ascension and Tristan da Cunha" },
  { id: "KN", country: "Saint Kitts and Nevis" },
  { id: "LC", country: "Saint Lucia" },
  { id: "MF", country: "Saint Martin (French part)" },
  { id: "PM", country: "Saint Pierre and Miquelon" },
  { id: "VC", country: "Saint Vincent and the Grenadines" },
  { id: "WS", country: "Samoa" },
  { id: "SM", country: "San Marino" },
  { id: "ST", country: "Sao Tome and Principe" },
  { id: "SA", country: "Saudi Arabia" },
  { id: "SN", country: "Senegal" },
  { id: "RS", country: "Serbia" },
  { id: "SC", country: "Seychelles" },
  { id: "SL", country: "Sierra Leone" },
  { id: "SG", country: "Singapore" },
  { id: "SX", country: "Sint Maarten (Dutch part)" },
  { id: "SK", country: "Slovakia" },
  { id: "SI", country: "Slovenia" },
  { id: "SB", country: "Solomon Islands" },
  { id: "SO", country: "Somalia" },
  { id: "ZA", country: "South Africa" },
  { id: "GS", country: "South Georgia and the South Sandwich Islands" },
  { id: "SS", country: "South Sudan" },
  { id: "ES", country: "Spain" },
  { id: "LK", country: "Sri Lanka" },
  { id: "SD", country: "Sudan" },
  { id: "SR", country: "Suriname" },
  { id: "SJ", country: "Svalbard and Jan Mayen" },
  { id: "SZ", country: "Swaziland" },
  { id: "SE", country: "Sweden" },
  { id: "CH", country: "Switzerland" },
  { id: "SY", country: "Syrian Arab Republic" },
  { id: "TW", country: "Taiwan, Province of China" },
  { id: "TJ", country: "Tajikistan" },
  { id: "TZ", country: "Tanzania, United Republic of" },
  { id: "TH", country: "Thailand" },
  { id: "TL", country: "Timor-Leste" },
  { id: "TG", country: "Togo" },
  { id: "TK", country: "Tokelau" },
  { id: "TO", country: "Tonga" },
  { id: "TT", country: "Trinidad and Tobago" },
  { id: "TN", country: "Tunisia" },
  { id: "TR", country: "Turkey" },
  { id: "TM", country: "Turkmenistan" },
  { id: "TC", country: "Turks and Caicos Islands" },
  { id: "TV", country: "Tuvalu" },
  { id: "UG", country: "Uganda" },
  { id: "UA", country: "Ukraine" },
  { id: "AE", country: "United Arab Emirates" },
  { id: "GB", country: "United Kingdom" },
  { id: "US", country: "United States" },
  { id: "UM", country: "United States Minor Outlying Islands" },
  { id: "UY", country: "Uruguay" },
  { id: "UZ", country: "Uzbekistan" },
  { id: "VU", country: "Vanuatu" },
  { id: "VE", country: "Venezuela, Bolivarian Republic of" },
  { id: "VN", country: "Viet Nam" },
  { id: "VG", country: "Virgin Islands, British" },
  { id: "VI", country: "Virgin Islands, U.S." },
  { id: "WF", country: "Wallis and Futuna" },
  { id: "EH", country: "Western Sahara" },
  { id: "YE", country: "Yemen" },
  { id: "ZM", country: "Zambia" },
  { id: "ZW", country: "Zimbabwe" },
];

export default {
  countries_json,
};

import React from "react";
import styled from "styled-components";
import Arrow from "../Icons/caret.svg";
import { labelsLanguage } from "../utils/language";
import { useLanguageState } from "../Context/Language";

import close from "../assets/close.png";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  //width: fit-content;
  //justify-content: center;
  align-items: center;
  //min-height: 30px;
  //border: 1px solid red;
  padding-bottom: 5px;

  //width: 100%;
  height: fit-content;

  justify-content: flex-end;

  img {

    &:hover {
      opacity: 0.5;
    }
  }
`;

const SelectBox = styled.select`
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  width: fit-content;
  box-sizing: border-box;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 15px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;
  padding-right: 40px;
  font-family: "Inconsolata", monospace;

  margin-left: 18px;
  margin-right: 18px;

  @media (max-width: 920px) {
    padding: 10px;
    //margin-bottom: 20px;
    padding-right: 40px;
  }
  background-color: #ffffff;
`;

/*const LabelInput = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "Inconsolata", monospace;

  @media (max-width: 800px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;*/

const LanguageSwitcher = () => {
  const { language, setLanguage } = useLanguageState();

  return (
    <Container>
      {/**<LabelInput>{labelsLanguage[language]["nationality"]}</LabelInput>**/}
      {/**<SelectBox disabled selected>
        <option value="pt">R$</option>
      </SelectBox>**/}
      <SelectBox
        onChange={(event) => setLanguage(event.target.value)}
        value={language}
      >
        <option value="pt">{labelsLanguage[language]["langPt"]}</option>
        <option value="en">{labelsLanguage[language]["langEn"]}</option>
      </SelectBox>

      <a href="https://www.inhotim.org.br/?gclid=CjwKCAjw_L6LBhBbEiwA4c46uo_fy6qTxMcPyEwKlVEIICvvJX3bekAyCMZGJ6RN8QY1d6h3kSnoHxoC0VUQAvD_BwE">
        <img alt="Close" src={close} />
      </a>
    </Container>
  );
};

export default LanguageSwitcher;

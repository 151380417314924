import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import TableMembershipMobile from "../Components/TableMembershipMobile";

import { FaMoneyCheckAlt, FaLock } from "react-icons/fa";

const Container = styled.div`
  width: 95%;
  margin: auto;
  padding: 40px 0;
  font-family: "Inconsolata", monospace;

  @media (max-width: 900px) {
    padding: 0;
    margin-bottom: 50px;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CardLink = styled(NavLink)`
  margin-top: 3%;
  width: 44%;
  height: 200px;
  border: 1px solid black;
  position: relative;
  text-decoration: none;
  color: black;

  cursor: pointer;

  &:hover {
    color: #544bff;
  }

  @media (max-width: 1200px) {
    width: 48%;
    height: 230px;
  }

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`;

const CardTitle = styled.h2`
  margin: 0;
  padding: 3% 5%;
  font-family: "Poppins", sans-serif;
`;

const CardValue = styled.h2`
  margin: 0;
  padding: 0 5%;
  font-family: "Poppins", sans-serif;
`;

const CardInfo = styled.p`
  margin: 0;
  padding: 5% 5%;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  @media (max-width: 900px) {
    display: none;
  }
`;

const IconDraw = styled(FaMoneyCheckAlt)`
  position: absolute;
  top: 10px;
  right: 30px;
`;

const IconLock = styled(FaLock)`
  position: absolute;
  top: 10px;
  right: 30px;
`;

const TableTransactions = styled.div`
  width: 100%;
  min-height: 80vh;
  border: 1px solid black;
  border-top: none;
  box-sizing: border-box;
  margin-top: 10%;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const BoxTransactionsHeader = styled.div`
  border: 1px solid black;
  border-right: none;

  box-sizing: border-box;
  width: 25%;
  padding: 20px;
  font-size: 1.5rem;
  text-align: center;
  position: relative;

  font-weight: bold;
`;

const BoxTransactionsRow = styled.div`
  width: 25%;
  padding: 15px;
  text-align: center;
  font-weight: bold;
`;

const TableHeader = styled.div`
  display: flex;
  height: auto;
`;

const columns_Transactions = [
  {
    id: 1,
    name: "Nome",
    width: "25%",
    key_value: "name",
  },
  {
    id: 2,
    name: "Email",
    width: "25%",
    key_value: "email",
  },
  {
    id: 3,
    name: "Total",
    width: "25%",
    key_value: "total",
  },
  {
    id: 4,
    name: "Data",
    width: "25%",
    key_value: "data",
  },
];

const rows_Transactions = [
  {
    total: "R$ 10,00",
    name: "Daniel Souza",
    email: "daniel@evoe.cc",
    data: "01/01/2021",
  },
  {
    total: "R$ 10,00",
    name: "Daniel Souza",
    email: "daniel@evoe.cc",
    data: "01/01/2021",
  },
];

const CardFinancialInfo = ({ title, financialInfo, type_account }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <CardContainer>
        <CardLink to={`/financeiro/saque?account=${type_account}`} exact={true}>
          <IconDraw size={50} />
          <CardTitle>Realizar o saque</CardTitle>
          <CardValue>R$ {financialInfo.soma.toFixed(2)}</CardValue>
          {financialInfo !== null ? (
            <CardInfo>Você ainda não fez um saque.</CardInfo>
          ) : (
            <CardInfo>
              Seu ultimo saque foi no dia {financialInfo.data_ultimo_saque}
            </CardInfo>
          )}
        </CardLink>
        <CardLink
          to={`/financeiro/bloqueadas?account=${type_account}`}
          exact={true}
        >
          <IconLock size={50} />
          <CardTitle>Ver Movimentações Bloqueadas</CardTitle>
          <CardValue>R$ {financialInfo.soma_blocked.toFixed(2)}</CardValue>
          <CardInfo>
            Este é o total de cobranças feitas no cartão com menos de 30 dias.
            Após o prazo de 30 dias estes valores serão liberados para o saque
          </CardInfo>
        </CardLink>
      </CardContainer>
      <TableMembershipMobile
        columns={columns_Transactions}
        rows={rows_Transactions}
        url_button={null}
      />
      <TableTransactions>
        <TableHeader>
          <BoxTransactionsHeader style={{ borderLeft: "none" }}>
            Nome
          </BoxTransactionsHeader>
          <BoxTransactionsHeader>Email</BoxTransactionsHeader>
          <BoxTransactionsHeader>Total</BoxTransactionsHeader>
          <BoxTransactionsHeader>Data</BoxTransactionsHeader>
        </TableHeader>
        {financialInfo.operations.map((data) => (
          <TableHeader key={data.id}>
            <BoxTransactionsRow style={{ borderLeft: "none" }}>
              {data.label}
            </BoxTransactionsRow>
            <BoxTransactionsRow>Email</BoxTransactionsRow>
            <BoxTransactionsRow>{data.valor}</BoxTransactionsRow>
            <BoxTransactionsRow>{data.data}</BoxTransactionsRow>
          </TableHeader>
        ))}
      </TableTransactions>
    </Container>
  );
};

export default CardFinancialInfo;

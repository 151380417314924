import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Lottie from "react-lottie";

import animation_data from "../json_objects/animations/loading_json.json";

import apiJson from "../api/callApi";
import { inhotimProjectData } from "../utils/constants";

import ArrowIcon from "../Icons/caret.svg";

const Container = styled.div`
  width: 100%;

  padding: 2% 0;

  @media (max-width: 800px) {
    border: 1px solid black;
    margin-bottom: 50px;
  }
`;

const Reward = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 2%;
  border-bottom: 1.5px solid #c4c4c4;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
`;

const ContainerDescription = styled.div`
  width: 80%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ContainerButtons = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 800px) {
    width: 100%;
    margin: 20px 0;
  }
`;

const ContainerAddReward = styled.div`
  width: 100%;
  border-top: 1px solid black;
  margin-top: 50px;
  padding: 30px 5%;
  box-sizing: border-box;

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

const TitleReward = styled.h4`
  margin: 0;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;

  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
`;

const DescReward = styled.p`
  margin: 0;
  white-space: pre-line;
  font-family: "Poppins", sans-serif;
`;

const SelectBox = styled.select`
  font-size: 1rem;
  width: 100%;
  padding: 5px;
  border: 3px solid black;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position: right 5px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;
`;

const Button = styled.button`
  font-size: 1rem;
  width: 100%;
  padding: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  margin-top: 10px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
`;

const ButtonAddReward = styled(Button)`
  width: 15%;
  padding: 10px 0;
  font-family: "Poppins", sans-serif;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ListRewards = () => {
  const defaultOptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animation_data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  const [rewards, setRewards] = useState([]);

  const getData = async () => {
    const result = await apiJson.getRewards(inhotimProjectData.projectId);

    setRewards(result.data.results);
    console.log(result.data.results);
    setDataIsLoaded(true);
  };

  const handleSubmitChange = async (e, reward_id, valor_recompensa) => {
    const rewardObject = {};
    rewardObject["invisivel"] = e.target.value;
    rewardObject["valor_recompensa"] = valor_recompensa;

    await apiJson.updateSpecificReward(reward_id, rewardObject);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Container>
      {dataIsLoaded ? (
        <>
          {rewards.map((data) => (
            <Reward key={data.id}>
              <ContainerDescription>
                <TitleReward>
                  {data.nome_recompensa} - R${" "}
                  {Number(data.valor_recompensa).toFixed(2)}
                </TitleReward>
                <DescReward>{data.descricao}</DescReward>
              </ContainerDescription>
              <ContainerButtons>
                <SelectBox
                  selected={data.invisivel}
                  onChange={(e) =>
                    handleSubmitChange(e, data.id, data.valor_recompensa)
                  }
                >
                  <option
                    value={false}
                    selected={!data.invisivel && "selected"}
                  >
                    Ativado
                  </option>
                  <option value={true} selected={data.invisivel && "selected"}>
                    Desativado
                  </option>
                </SelectBox>
                <NavLink
                  to={`/customizacao/recompensa/${data["id"]}`}
                  exact={true}
                >
                  <Button>Editar</Button>
                </NavLink>
              </ContainerButtons>
            </Reward>
          ))}
        </>
      ) : (
        <Lottie options={defaultOptionsLottie} height={400} width={400} />
      )}

      <ContainerAddReward>
        <TitleReward>Adicionar outra recompensa</TitleReward>

        <NavLink to={`/customizacao/criar-recompensa`} exact={true}>
          <ButtonAddReward>Adicionar</ButtonAddReward>
        </NavLink>
      </ContainerAddReward>
    </Container>
  );
};

export default ListRewards;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useUserObjectData } from "../Context/UserObjectData";
import apiJson from "../api/callApi";

import { FaTools } from 'react-icons/fa';

import Reward from "../Components/Reward";

const Container = styled.div`
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const ContainerCard = styled.div`
  width: 60%;
  display: flex;
  height: 600px;

  & > button {
    width: 100%;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    min-height: 600px;
  }
`;

const Title = styled.h1`
  width: 100%;
`;

const OverlayEmBreve = styled.div`
  height: 100vh;
  width: 90%;
  background-color: #fff;
  position: absolute;
  z-index: 100;

  @media (max-width: 800px) {
    width: 95%;
  }
`;


const SubscriptionCard = () => {
  const [reward, setReward] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const { userObjectData } = useUserObjectData();

  const getData = async () => {
    const reward_data = await apiJson.getMemberReward(
      userObjectData["user_id"]
    );
    const member_data = await apiJson.getMemberUser(userObjectData["user_id"]);

    console.log(member_data);

    setReward(reward_data.data);
    setMemberData(member_data.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Container>
   
    <OverlayEmBreve>
      <FaTools style={{ fontSize: "30px", marginTop: "20px" }}/>
      <h1 style={{ color: "#000" }}>Essa página estará disponível em breve!</h1>
      <h3>Enquanto isso qualquer dado, informação ou ação necessária pode ser solicitado para contato@evoe.cc</h3>
    </OverlayEmBreve>
      {reward && (
        <>
          <Title>Recompensa</Title>
          <Reward
            title={`${reward.nome_recompensa} - R$ ${reward.valor_recompensa}`}
            description={reward.descricao}
          />
          {memberData && (
            <ContainerCard>
              <object
                data={memberData.card_file}
                type="application/pdf"
                width="100%"
                height="100%"
              ></object>
            </ContainerCard>
          )}
        </>
      )}
    </Container>
  );
};

export default SubscriptionCard;

{
  /* <ContainerButtons>
<Button>Download Carteirinha</Button>
</ContainerButtons> */
}

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 35%;
  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 5px;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
`;

const Description = styled.p`
  margin-top: 0px;
  margin-bottom: 30px;
  margin-left: 15px;
  white-space: pre-line;
  line-height: 25px;
  font-family: "Inconsolata", monospace;
`;

const Reward = ({ children, title, description }) => {
  return (
    <Container>
      {children}
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default Reward;

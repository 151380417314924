import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Greetings from "../Components/Greetings";
import DirectAccount from "../Components/DirectAccount";
import ContainerOptions from "../Components/ContainerOptions";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import apiJson from "../api/callApi";
import LoadingComponent from "../Components/LoadingComponent";

import { shade } from "polished";

import moment from "moment";

import { ExternalLink } from "react-external-link";

const customizable_options = [
  {
    key_value: "exportTxt",
    label: "Exportar TXT",
  },
];

const ButtonExport = styled.button`
  margin: 0;
  margin-top: 15px;
  font-size: 1.3rem;
  background-color: #544bff;
  border: none;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-weight: bold;
  color: white;
  min-height: 65px;
  margin-bottom: 17px;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#544BFF")};
  }
`;

const Container = styled.div`
  padding-bottom: 100px;
  height: 1vh;
  max-height: 1vh;
  margin-top: 20px;

  .react-datepicker {
    margin-top: 15px;
    font-family: "Inconsolata", monospace;
  }

  .react-datepicker__day--outside-month {
    color: transparent;
    visibility: hidden;
    pointer-events: none;
  }

  .react-datepicker__day--selected {
    background-color: #544bff;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: white;
    color: black;
  }

  .react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }

  @media (max-width: 1025px) {
    width: 95%;
    margin: auto;
  }
`;

const ContainerTab = styled.div`
  width: 100%;
  min-height: 50vh;
  margin-top: 30px;
  margin-left: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: 15px;
  }

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-direction: column;
    border: 1px solid black;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .txt-not-found {
    color: red;
    text-align: center;
    margin: 5px;
  }
`;

const ContainerMobile = styled.div`
  margin-top: 30px;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;
const ContainerOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const TitleMobile = styled.h3`
  margin: 0;
`;

const ArrowIconDown = styled(FaChevronDown)`
  padding: 0px 10px;
`;

const ArrowIconRight = styled(FaChevronRight)`
  padding: 0px 10px;
`;

const ContentContainer = styled.div`
  width: 100%;
  //min-height: 40vh;
  border: 0.5px solid black;
  border-top: none;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
  display: flex;

  @media (max-width: 1024px) {
    border: 0.5px solid black;
    margin-top: 20px;
    display: none;
  }
`;

/*const OverlayEmBreve = styled.div`
  height: 100vh;
  width: 90%;
  background-color: #fff;
  position: absolute;
  z-index: 100;

  @media (max-width: 800px) {
    width: 95%;
  }
`;*/

const Finances = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [activeValue, setActiveValue] = useState("exportTxt");

  const [selectedValue, setSelectedValue] = useState(
    null
  ); // tava new Date();

  const [foundFile, setFoundFile] = useState({ download_link: "", status: "" });
  const [validDates, setValidDates] = useState([]);

  // O que vai ser mandado pro back
  const [valueFormatted, setValueFormatted] = useState(
    moment(selectedValue).format("DD-MM-YYYY")
  );

  /**
   * Formata a data para ser mandada no back-end
   *
   */
  useEffect(() => {
    let fieldValue = moment(selectedValue).isValid()
      ? moment(selectedValue).format("DD-MM-YYYY")
      : selectedValue;
    setValueFormatted(fieldValue);
  }, [selectedValue]);

  // Atualiza o arquivo para ser baixadoq quando troca a data selecionada
  useEffect(() => {
    getData();
  }, [valueFormatted]);

  // Busca os dias que estarão disponíveis os downloads
  useEffect(() => {
        getValidDates();
  }, [])

  /**
   * Componente usado na tab de exportar txt
   */
  const ExportTXTTabComponent = ({ selected, setSelectedValue }) => {
    registerLocale("pt", pt);

    return (
      <ContainerTab>
        {loading ? <><LoadingComponent/></> : <><span className="title">Selecione a data:</span>
        <DatePicker
          type="text"
          selected={selected} // selected
          locale={"pt"}
          includeDates={validDates}
          onChange={(value) => {
            setSelectedValue(value);
          }}
          disabledKeyboardNavigation
          dateFormat={"dd-MM-yyyy"} // tava com /
          inline
          //minDate={moment().subtract(1, "d").toDate()} // não tinha
          maxDate={moment().subtract(1, "d").toDate()} // não tinha
        />

        {foundFile.status === 200 && (
          <>
            <ExternalLink href={foundFile.download_link}>
              <ButtonExport>Exportar</ButtonExport>
            </ExternalLink>
          </>
        ) /*: (
          <p className="txt-not-found">
            Não encontramos arquivo nessa data para ser exportado!
          </p>
        )*/}</>}
        
      </ContainerTab>
    );
  };

  /**
   * Pega informações sobre o arquivo de TXT que iremos baixar
   */
  const getData = async () => {
    setLoading(true);
    const result = await apiJson.getTxtFile(valueFormatted);
    setFoundFile(result.data);
    //console.log(foundFile)
    setLoading(false);
  };

  /**
   * Pega as datas válidas para marcar como disponíveis para download no calendário
   */
  const getValidDates = async () => {
    setLoading(true)

    const result = await apiJson.getValidDates();

    const { valid_dates } = result.data;

    let validDatesParsed = []

    valid_dates.forEach(date=> {
      validDatesParsed.push(moment(date, "DD-MM-YYYY").toDate())
    })

    //console.log(validDatesParsed)
   
    setValidDates(validDatesParsed);
  };

  /**
   * Cuida da troca de tab ao clicar
   * @param {string} key A tab que estamos clicando
   */
  const handleChangeOption = (key) => {
    if (activeValue === key) {
      return;
    }

    key === activeValue ? setActiveValue() : setActiveValue(key);
  };

  useEffect(() => {
    //console.log(location);
    location.valueSelected
      ? setActiveValue(location.valueSelected)
      : setActiveValue("exportTxt");
  }, [location]);

  return (
    <Container>
      <Greetings has_subtitle={false} />
      <ContainerMobile>
        {customizable_options.map((data) => (
          <div key={data.key_value}>
            <ContainerOption>
              <TitleMobile onClick={() => handleChangeOption(data.key_value)}>
                {data.label}
              </TitleMobile>
              {data.key_value === activeValue ? (
                <ArrowIconDown />
              ) : (
                <ArrowIconRight />
              )}
            </ContainerOption>
            {activeValue === "exportTxt" && data.key_value === activeValue && (
              <ExportTXTTabComponent
                selected={selectedValue}
                setSelectedValue={setSelectedValue}
                foundFile={foundFile}
              />
            )}
          </div>
        ))}
      </ContainerMobile>

      <ContainerOptions
        options={customizable_options}
        activeValue={activeValue}
        handleChangeOption={handleChangeOption}
      />

      <ContentContainer>
        {activeValue === "exportTxt" && (
          <ExportTXTTabComponent
            selected={selectedValue}
            setSelectedValue={setSelectedValue}
            foundFile={foundFile}
          />
        )}
      </ContentContainer>
    </Container>
  );
};

export default Finances;

/**
 * import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { FaTools } from 'react-icons/fa';

import Greetings from "../Components/Greetings";
import DirectAccount from "../Components/DirectAccount";
import LawAccount from "../Components/LawAccount";
import ContainerOptions from "../Components/ContainerOptions";

import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const customizable_options = [
  {
    key_value: "direct_account",
    label: "Conta Direto",
  },
  {
    key_value: "law_account",
    label: "Conta Lei Federal",
  },
];
const Container = styled.div`
  padding-bottom: 100px;
  height: 1vh;
  max-height: 1vh;
  overflow: hidden;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const ContainerMobile = styled.div`
  margin-top: 30px;
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;
const ContainerOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const TitleMobile = styled.h3`
  margin: 0;
`;

const ArrowIconDown = styled(FaChevronDown)`
  padding: 0px 10px;
`;

const ArrowIconRight = styled(FaChevronRight)`
  padding: 0px 10px;
`;

const ContentContainer = styled.div`
  width: 100%;
  min-height: 50vh;
  border: 1px solid black;
  border-top: none;
  box-sizing: border-box;

  @media (max-width: 800px) {
    border: 1px solid black;
    margin-top: 20px;
    display: none;
  }
`;

const OverlayEmBreve = styled.div`
  height: 100vh;
  width: 90%;
  background-color: #fff;
  position: absolute;
  z-index: 100;

  @media (max-width: 800px) {
    width: 95%;
  }
`;

const Finances = ({ location }) => {
  const [activeValue, setActiveValue] = useState();

  const handleChangeOption = (key) => {
    key === activeValue ? setActiveValue() : setActiveValue(key);
  };

  useEffect(() => {
    //console.log(location);
    location.valueSelected
      ? setActiveValue(location.valueSelected)
      : setActiveValue("direct_account");
  }, [location]);

  return (
    <Container>

      

      <Greetings has_subtitle={false} />
      <ContainerMobile>
        {customizable_options.map((data) => (
          <div key={data.id}>
            <ContainerOption onClick={() => handleChangeOption(data.key_value)}>
              <TitleMobile>{data.label}</TitleMobile>
              {data.key_value === activeValue ? (
                <ArrowIconDown />
              ) : (
                <ArrowIconRight />
              )}
            </ContainerOption>
            {activeValue === "direct_account" &&
              data.key_value === activeValue && <DirectAccount />}
            {activeValue === "law_account" &&
              data.key_value === activeValue && <LawAccount />}
          </div>
        ))}
      </ContainerMobile>
      <ContainerOptions
        options={customizable_options}
        activeValue={activeValue}
        handleChangeOption={handleChangeOption}
      />
      <ContentContainer>
        {activeValue === "direct_account" && <DirectAccount />}
        {activeValue === "law_account" && <LawAccount />}
      </ContentContainer>
    </Container>
  );
};

export default Finances;
 */

import cep from 'cep-promise';

const getAddressByCEP = async (_cep) => {
    if(_cep.length < 8) {
        return null;
    }

    let address;
    try {
        address = await cep(_cep);
    } catch (err) {
        address = null;
    }

    return address;
};

export default getAddressByCEP;
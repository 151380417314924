import React, { useState, useEffect } from "react";
import styled from "styled-components";

import TableMembershipMobile from "../Components/TableMembershipMobile";
import HeaderLink from "../Components/HeaderLink";
import apiJson from "../api/callApi";

import { useUserObjectData } from "../Context/UserObjectData";

const Container = styled.div`
  padding-bottom: 50px;
  width: 80%;

  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const TableTransactions = styled.div`
  width: 100%;

  border: 1px solid black;
  border-top: none;
  box-sizing: border-box;

  @media (max-width: 800px) {
    display: none;
  }
`;

const BoxTransactionsHeader = styled.div`
  border: 1px solid black;
  border-right: none;

  box-sizing: border-box;
  width: 25%;
  padding: 20px;
  font-size: 1.5rem;
  text-align: center;
  position: relative;

  font-weight: bold;
`;

const TableHeader = styled.div`
  display: flex;
  height: auto;
`;

const BoxTransactionsRow = styled.div`
  width: 25%;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border-left: 1px solid black;
`;

const columns_Transactions = [
  {
    id: 1,
    name: "Nome",
    width: "25%",
    key_value: "name",
  },
  {
    id: 2,
    name: "Email",
    width: "25%",
    key_value: "email",
  },
  {
    id: 3,
    name: "Total",
    width: "25%",
    key_value: "total",
  },
  {
    id: 4,
    name: "Data",
    width: "25%",
    key_value: "data",
  },
];

const rows_Transactions = [
  {
    total: "R$ 10,00",
    name: "Daniel Souza",
    email: "daniel@evoe.cc",
    data: "01/01/2021",
  },
  {
    total: "R$ 10,00",
    name: "Daniel Souza",
    email: "daniel@evoe.cc",
    data: "01/01/2021",
  },
];

const BlockTransactions = ({ location }) => {
  const [blockTransactions, setBlockTransactions] = useState(null);
  const { userObjectData } = useUserObjectData();

  const getData = async (type_account) => {
    let result = null;
    if (type_account === "direct") {
      result = await apiJson.getFinancialTransactions(
        userObjectData["project_id"]
      );
    } else {
      result = await apiJson.getFinancialTransactionsBlockAccount(
        userObjectData["project_id"]
      );
    }
    setBlockTransactions(result.data);
  };

  useEffect(() => {
    const queryString = location.search;
    getData(queryString.split("?account=")[1]);
  }, []);
  return (
    <Container>
      <HeaderLink
        url="/financeiro"
        label_link="Financeiro"
        label_info="Movimentações bloqueadas"
        valueSelected="direct_account"
      />

      <h2>Movimentações bloqueadas</h2>
      <p>
        Abaixo a lista de cobranças das assinatueras que estão bloqueadas. Estes
        valores serão liberados/desbloqueados em um prazo de 30 dias após a data
        da cobrança, ficando disponível para o saque.
      </p>

      {blockTransactions && (
        <>
          <TableMembershipMobile
            columns={columns_Transactions}
            rows={rows_Transactions}
            url_button={null}
          />
          <TableTransactions>
            <TableHeader>
              <BoxTransactionsHeader style={{ borderLeft: "none" }}>
                Nome
              </BoxTransactionsHeader>
              <BoxTransactionsHeader>Email</BoxTransactionsHeader>
              <BoxTransactionsHeader>Total</BoxTransactionsHeader>
              <BoxTransactionsHeader>Data</BoxTransactionsHeader>
            </TableHeader>
            {blockTransactions.blocked_operations.map((data) => (
              <TableHeader key={data.id}>
                <BoxTransactionsRow style={{ borderLeft: "none" }}>
                  {data.label}
                </BoxTransactionsRow>
                <BoxTransactionsRow>Email</BoxTransactionsRow>
                <BoxTransactionsRow>{data.valor}</BoxTransactionsRow>
                <BoxTransactionsRow>{data.data}</BoxTransactionsRow>
              </TableHeader>
            ))}
          </TableTransactions>
        </>
      )}
    </Container>
  );
};

export default BlockTransactions;

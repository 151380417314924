import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FaEdit, FaArrowLeft } from "react-icons/fa";
import { mask } from "remask";

import apiJson from "../api/callApi";
import { inhotimProjectData } from "../utils/constants";
import TableMembership from "../Components/TableMembership";
import TableMembershipMobile from "../Components/TableMembershipMobile";
import ContainerDetailsCard from "../Components/ContainerDetailsCard";
import DetailsInfo from "../Components/DetailsInfo";
import DetailsUserTransactions from "../Components/DetailsUserTransactions";
import DetailsUserEntries from "../Components/DetailsUserEntries";
import HeaderLink from "../Components/HeaderLink";

import { fixNumber } from "../utils/utils";

import animation_data from "../json_objects/animations/loading_json.json";
import Lottie from "react-lottie";

import { shade } from "polished";
import { isAfter } from "date-fns";

const TitleCard = styled.h2`
  margin: 0;
  font-size: 2rem;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
`;

const Container = styled.div`
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const BoxStatus = styled.div`
  background-color: #1bba1f;
  width: 20%;
  padding: 10px 30px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Poppins", sans-serif;
  & > p {
    margin: 0px;
    color: white;
    font-weight: bold;
  }
`;

const IconEdit = styled(FaEdit)`
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 10px;
  &:hover {
    color: #544bff;
    opacity: 1;
  }
`;

const IconReturn = styled.span`
  text-align: center;
  position: absolute;
  top: 0;
  right: 5%;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #544bff;
    opacity: 1;
  }
`;

const ButtonDownloadCard = styled.button`
  margin-top: 40px;
  border: none;
  background-color: #544bff;
  color: white;
  padding: 10px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  position: relative;
  width: 100%;
  font-family: "Poppins", sans-serif;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#544bff")};
  }
`;

const Button = styled.button`
  border: none;
  background-color: #544bff;
  color: white;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 10px;
  width: 40%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#544bff")};
  }

  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
`;

/*const TextReward = styled.p`
  margin: 0;
  white-space: pre-line;
  margin: 15px;
  line-height: 25px;
  font-family: "Inconsolata", monospace;
`;*/

const TextReward = styled.p`
  margin: 0;
  font-family: "Inconsolata", monospace;
  white-space: pre-line;
  line-height: 25px;
  margin: 15px;
  height: 200px;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    width: 95%;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgb(232, 232, 232);
  }

  ::-webkit-scrollbar-thumb {
    background: black;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(54, 56, 58);
  }
`;

const TypeReward = styled.h2`
  margin: 0;
  color: #544bff;
  font-family: "Poppins", sans-serif;
`;

const EmptyData = styled.p`
  margin-bottom: 20px;
  text-align: center;
  color: rgba(190, 189, 193, 0.8);
`;

const details__member = [
  {
    key_id: "email",
    label: "Email:",
    value: "example@example.com",
  },
  {
    key_id: "document",
    label: "CPF/CNPJ:",
    value: "000.000.000-00",
  },
  {
    key_id: "phone",
    label: "Telefone:",
    value: "(00) 0000-0000",
  },
  {
    key_id: "address",
    label: "Endereço",
    value: "Rua Pastor Rui Franco, 386, Belo Horizonte - MG, 00000-000",
  },
];

const columns_dependents = [
  {
    id: 1,
    name: "Nome",
    width: "30%",
    key_value: "nome",
  },
  {
    id: 1,
    name: "E-mail",
    width: "20%",
    key_value: "email",
  },
  {
    id: 2,
    name: "CPF",
    width: "15%",
    key_value: "document",
  },
  {
    id: 3,
    name: "Status",
    width: "10%",
    key_value: "status",
  },
  {
    id: 5,
    name: "Ultima Entrada",
    width: "10%",
    key_value: "date_last_access",
  },
];

const columns_transactions = [
  {
    id: 1,
    name: "Contribuição",
    width: "5%",
    key_value: "valor_contribuicao",
  },
  {
    id: 1,
    name: "Data",
    width: "2%",
    key_value: "data_contribuicao",
  },
  {
    id: 2,
    name: "Recompensa",
    width: "20%",
    key_value: "recompensa_contribuicao",
  },
  {
    id: 3,
    name: "",
    width: "10%",
    key_value: "",
  },
];

const columns_entries = [
  {
    id: 1,
    name: "Data",
    width: "35%",
    key_value: "date_access",
  },
  {
    id: 1,
    name: "Recompensa",
    width: "30%",
    key_value: "reward",
  },
  {
    id: 2,
    name: "",
    width: "15%",
    key_value: "id",
  },
];

const defaultOptionsLottie = {
  loop: true,
  autoplay: true,
  animationData: animation_data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const UsersInfo = ({ location, match }) => {
  const [viewDependent, setViewDependent] = useState({
    status: false,
    data: {},
  });
  const [rowsData, setRowsData] = useState([]);
  const [dataUser, setDataUser] = useState(null);
  const [dataContributions, setDataContributions] = useState([]);
  const [dataAccessLogs, setDataAccessLogs] = useState([]);
  const [detailsMember, setDetailsMember] = useState(details__member);
  const [userId, setUserId] = useState("");

  /**
   * Busca todas as informações de usuário
   * @param {number} user_id Id do usuário
   * @param {number} project_id Id do projeto
   */
  const getDataUser = async (user_id, project_id) => {
    setUserId(user_id);
    const objectData_detailsMember = [...detailsMember];

    const result = await apiJson.getUserInfo(user_id, project_id);

    let objNovo = {};
    let contributions = [];
    let accessess = [];
    result.data.forEach((signature) => {
      //objAntigo = signature;
      const transacoes = signature["contributions"];
      const acessos = signature["access_logs"];
      transacoes.forEach((contribution) => {
        contributions.push(contribution);
      });
      acessos.forEach((acesso) => {
        accessess.push(acesso);
      })

      /*signature['contributions'].forEach((c, index) => {
        if (isAfter(c['data_contribuicao'], objAntigo['contributions'][index]['data_contribuicao'])) {
          objNovo = objAntigo;
        }
      })*/
      objNovo = signature;
    });

    objNovo["contributions"] = contributions;
    objNovo["access_logs"] = accessess;

    objectData_detailsMember.map(
      (data) => (data["value"] = objNovo[data["key_id"]])
    );

    // Recriar o objeto de transações retirando informações desnecessárias
    let filteredAccessLogs = [];
    objNovo["access_logs"].forEach((access) => {
      const accessLogsDTO = {
        date_access: access.date_access,
        reward: "R$ " + fixNumber(access.reward.toFixed(2)),
        id: access.id,
      };
      filteredAccessLogs.push(accessLogsDTO);
    });

    // Recriar o objeto de contribuições
    let filteredContributions = [];
    objNovo["contributions"].forEach((contribution) => {
      const contributionDTO = {
        valor_contribuicao:
          "R$ " + fixNumber(contribution.valor_contribuicao.toFixed(2)),
        data_contribuicao: contribution.data_contribuicao,
        recompensa_contribuicao: contribution.recompensa_contribuicao,
        id_contribuicao: contribution.id_contribuicao,
      };
      filteredContributions.push(contributionDTO);
    });

    setDataContributions(filteredContributions); //result.data["contributions"]
    setDataAccessLogs(filteredAccessLogs); // result.data["access_logs"]

    setDataUser(objNovo);
    setDetailsMember(objectData_detailsMember);
  };

  /**
   * Busca todas as informações dos dependentes
   * @param {number} user_id Id to usuário
   * @param {number} project_id Id do projeto
   */
  const getDataDependents = async (user_id, project_id) => {
    const result = await apiJson.getTableDependents(user_id, project_id);

    let data = [];

    result.data.map((index) => {
      data.push({
        nome: index.name,
        email: index.email,
        cpf: index.document ? mask(index.document, ["999.999.999-99"]) : "",
        status: index.active ? "Ativo" : "Inativo",
        date_last_access: index.date_last_access
          ? mask(index.date_last_access, ["##/##/####"])
          : "",
        id: index.id,
      });
    });

    setRowsData(data);
  };

  useEffect(() => {
    getDataUser(match.params.user_id, inhotimProjectData.projectId);

    // Se a visualização for de dependente
    if (!viewDependent.status) {
      getDataDependents(match.params.user_id, inhotimProjectData.projectId);
    }
  }, [viewDependent]);

  return (
    <Container>
      {dataUser ? (
        <>
          <HeaderLink
            url="/usuarios"
            label_link="Usuários"
            label_info={dataUser.name}
          />
          <h2>
            Detalhes do {!viewDependent.status ? "usuário" : "dependente"}:
          </h2>
          <ContainerCards>
            <ContainerDetailsCard>
              {!viewDependent.status ? (
                <NavLink
                  to={`/editar-perfil/${match.params.user_id}`}
                  exact={true}
                  style={{ color: "black" }}
                >
                  <IconEdit size={40} />
                </NavLink>
              ) : (
                <IconReturn
                  onClick={() => setViewDependent({ status: false, data: {} })}
                >
                  <FaArrowLeft size={40} />
                  <strong>Voltar</strong>
                </IconReturn>
              )}

              <TitleCard>
                {!viewDependent.status ? "Usuário" : "Dependente"}
              </TitleCard>
              {/* Se for o usuário */}
              {!viewDependent.status &&
                detailsMember &&
                detailsMember.map((data) => (
                  <DetailsInfo
                    key={data.key_id}
                    label={data.label}
                    value={data.value}
                  />
                ))}

              {viewDependent.status && (
                <>
                  <DetailsInfo label={"Nome"} value={viewDependent.data.nome} />
                  <DetailsInfo label={"CPF"} value={viewDependent.data.cpf} />
                  <DetailsInfo
                    label={"E-mail"}
                    value={viewDependent.data.nome}
                  />
                  <DetailsInfo
                    label={"Nacionalidade"}
                    value={viewDependent.data.cpf ? "Brasileiro(a)" : "Outro"}
                  />
                </>
              )}
            </ContainerDetailsCard>

            <ContainerDetailsCard color="#1bba1f" min_heigth="80vh">
              <BoxStatus>
                <p>{dataUser["signature_status"]}</p>
              </BoxStatus>

              <TitleCard>Recompensa</TitleCard>
              <TypeReward>{`${dataUser.reward_name} - R$ ${Number(
                dataUser.reward_value
              ).toFixed(2)}`}</TypeReward>
              <TextReward>{dataUser["reward_description"]}</TextReward>
              {dataUser.card_url_file && (
                <Button onClick={() => window.open(dataUser.card_url_file)}>
                  Download Carteirinha
                </Button>
              )}
            </ContainerDetailsCard>

            {!viewDependent.status && (
              <ContainerDetailsCard>
                <TitleCard>Transações</TitleCard>
                {dataContributions <= 0 ? (
                  <EmptyData>Ainda não existem transações por aqui</EmptyData>
                ) : (
                  <>
                    <TableMembershipMobile
                      columns={columns_transactions}
                      rows={dataContributions}
                      label_button="Transação"
                      url_button="transacoes"
                    />
                    <TableMembership
                      columns={columns_transactions}
                      rows={dataContributions}
                      url_button="transacoes"
                      label_button="Transação"
                    />
                  </>
                )}
                <NavLink to={`/transacoes`} exact={true}>
                  <ButtonDownloadCard>Ver todas</ButtonDownloadCard>
                </NavLink>
              </ContainerDetailsCard>
            )}

            {!viewDependent.status && (
              <ContainerDetailsCard>
                <TitleCard>Entradas</TitleCard>
                {/*<DetailsUserEntries tableData={dataContributions} />*/}

                {dataAccessLogs <= 0 ? (
                  <EmptyData>Ainda não existem entradas por aqui</EmptyData>
                ) : (
                  <>
                    <TableMembershipMobile
                      columns={columns_entries}
                      rows={dataAccessLogs}
                      label_button="Entrada"
                      url_button="entradas"
                    />
                    <TableMembership
                      columns={columns_entries}
                      rows={dataAccessLogs}
                      url_button="entradas"
                      label_button="Entrada"
                    />
                  </>
                )}

                <NavLink to={`/entradas`} exact={true}>
                  <ButtonDownloadCard>Ver todas</ButtonDownloadCard>
                </NavLink>
              </ContainerDetailsCard>
            )}

            {!viewDependent.status && (
              <ContainerDetailsCard width="100%" height="auto">
                <TitleCard>Dependentes</TitleCard>
                {rowsData <= 0 ? (
                  <EmptyData>
                    Ainda não existem dependentes, adicione-os clicando abaixo
                  </EmptyData>
                ) : (
                  <>
                    <TableMembershipMobile
                      columns={columns_dependents}
                      rows={rowsData}
                      label_button="Usuário"
                      buttons={[
                        {
                          action: (dependent) =>
                            setViewDependent({ status: true, data: dependent }),
                          label: "Ver Usuário >",
                        },
                      ]}
                    />
                    <TableMembership
                      columns={columns_dependents}
                      rows={rowsData}
                      buttons={[
                        {
                          action: (dependent) =>
                            setViewDependent({ status: true, data: dependent }),
                          label: "Ver Usuário >",
                        },
                      ]}
                      label_button="Usuário"
                    />
                  </>
                )}
                <NavLink to={`/editar-perfil/${userId}`} exact={true}>
                  <Button>Adicionar Dependentes</Button>
                </NavLink>
              </ContainerDetailsCard>
            )}
          </ContainerCards>
        </>
      ) : (
        <Lottie options={defaultOptionsLottie} height={400} width={400} />
      )}
    </Container>
  );
};

export default UsersInfo;

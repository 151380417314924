import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormField from "../Components/FormField";
import { 
  RenderFormDependents,
  dependentsFieldsfields, 
  formatDataDependents,
  planAllowsDependent 
} from "../Components/DependentsForm";
import LoadingComponent from "../Components/LoadingComponent";

import apiJson, { saveDependents } from "../api/callApi";
import { isDocumentValid } from "../utils/validDocument";
import { unMask } from "remask";
import { FaPlus } from "react-icons/fa";

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Button = styled.button`
  width: 20%;
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  display: block;
  margin: 20px 0;
  font-family: "Poppins", sans-serif;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const Message = styled.h2`
  color: ${(props) => props.type === 'success' ? 'green' : 'red'};
`;

const schema = yup.object().shape({
  nome: yup.string().required("Campo Obrigatorio"),
  email: yup.string().email("Deve ser um Email").required("Campo Obrigatorio"),
  cpf: yup
    .string()
    .min(14, 'CPF Inválido!')
    .required('Documento Inválido')
    .test('isCPFValid', 'Documento Inválido',
        (value) => isDocumentValid(value))
    .required("Campo Obrigatorio"),
  telefone: yup
    .string()
    .required("Campo Obrigatório"),
  cep: yup.string(),
  logradouro: yup.string().required("Campo Obrigatorio"),
  numero: yup
    .number()
    .typeError("Deve ser um número")
    .required("Campo Obrigatorio"),
  complemento: yup.string(),
  bairro: yup.string().required("Campo Obrigatorio"),
  cidade: yup.string().required("Campo Obrigatorio"),
  estado: yup.string().required("Campo Obrigatorio"),

  ...dependentsFieldsfields().fields
});

const FormEditProfile = ({ defaultValues }) => {
  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const { errors } = formState;
  const isHandleDependent = planAllowsDependent(defaultValues?.rewardName, defaultValues?.user_type);
  const [numberOfDependents, setNumberOfDependents ] = useState(defaultValues?.numberOfdependents);
  const [showLoading, setShowLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState({
    status: false,
    type: '',
    message: ''
  });

  const submitForm = async (data) => {
    setShowLoading(true);

    const dependentsFormatted = formatDataDependents(data);
    const userData = {
      ...data,
      // Remoção de máscaras nos campos abaixo
      cpf: unMask(data?.cpf),
      cep: unMask(data?.cep),
      telefone: unMask(data?.telefone)
    };

    try {
      // Salva as informações do Usuário
      await apiJson.updateUserProfileData(userData.id, userData);

      // Salva apenas se o Plano permite ter dependentes
      if(isHandleDependent) {
        // Salva os Dependentes
        await saveDependents(userData.id, dependentsFormatted);
      };

      setResponseStatus({
        status: true,
        type: 'success',
        message: 'Informações Salvas com Sucesso!'
      });
    } catch(err) {
      setResponseStatus({
        status: true,
        type: 'error',
        message: 'Não foi possível realizar esta solicitação no momento!'
      });
    }

    setShowLoading(false);
  };

  const addDependents = () => {
    if(numberOfDependents < 4) {
      setNumberOfDependents(numberOfDependents + 1);
    }
  };

  return (
    <>
      <h2 style={{ fontFamily: "Poppins, sans-serif" }}>Sobre você:</h2>
      {showLoading && (<LoadingComponent heigth={300} width={300}/>)}
      <FormContainer>
        <FormField
          label="Nome:"
          width="60%"
          name="nome"
          margin_bottom="10px"
          registro={register}
          errorMsg={errors.nome?.message}
          defaultValue={defaultValues.nome}
          setValueFormState={setValue}
        />
        <FormField
          label="Email:"
          name="email"
          width="60%"
          margin_bottom="10px"
          registro={register}
          errorMsg={errors.email?.message}
          defaultValue={defaultValues.email}
          setValueFormState={setValue}
        />
        <FormField
          label="CPF/CNPJ:"
          name="cpf"
          width="60%"
          margin_bottom="10px"
          registro={register}
          errorMsg={errors.cpf?.message}
          maskParser={ ['999.999.999-99', '99.999.999/9999-99'] }
          defaultValue={defaultValues.cpf}
          setValueFormState={setValue}
        />
        <FormField
          label="Telefone:"
          name="telefone"
          width="60%"
          margin_bottom="10px"
          registro={register}
          maskParser={ ['(99) 9999-9999', '(99) 99999-9999'] }
          errorMsg={errors.telefone?.message}
          defaultValue={defaultValues.telefone}
          setValueFormState={setValue}
        />
      </FormContainer>
      <h2 style={{ fontFamily: "Poppins, sans-serif" }}>Endereço:</h2>
      <FormContainer>
        <FormField
          label="CEP:"
          name="cep"
          width="28%"
          margin_bottom="10px"
          registro={register}
          maskParser={ ['99.999-999'] }
          errorMsg={errors.cep?.message}
          defaultValue={defaultValues.cep}
          setValueFormState={setValue}
        />
        <FormField
          label="Endereço (Rua, Avenida, etc...):"
          name="logradouro"
          width="68%"
          margin_bottom="10px"
          registro={register}
          errorMsg={errors.logradouro?.message}
          defaultValue={defaultValues?.logradouro}
          setValueFormState={setValue}
        />
        <FormField
          label="Número:"
          name="numero"
          width="28%"
          margin_bottom="10px"
          registro={register}
          maskParser={ ['99999999'] }
          errorMsg={errors.numero?.message}
          defaultValue={defaultValues?.numero}
          setValueFormState={setValue}
        />
        <FormField
          label="Complemento:"
          name="complemento"
          width="32%"
          margin_bottom="10px"
          registro={register}
          errorMsg={errors.complemento?.message}
          defaultValue={defaultValues?.complemento}
          setValueFormState={setValue}
        />
        <FormField
          label="Bairro:"
          name="bairro"
          width="32%"
          margin_bottom="10px"
          registro={register}
          errorMsg={errors.bairro?.message}
          defaultValue={defaultValues?.bairro}
          setValueFormState={setValue}
        />
        <FormField
          label="Cidade:"
          name="cidade"
          width="64%"
          margin_bottom="10px"
          registro={register}
          errorMsg={errors.cidade?.message}
          defaultValue={defaultValues?.cidade}
          setValueFormState={setValue}
        />
        <FormField
          label="Estado:"
          name="estado"
          width="32%"
          margin_bottom="10px"
          registro={register}
          maskParser={ ['AA'] }
          errorMsg={errors.estado?.message}
          defaultValue={defaultValues?.estado}
          setValueFormState={setValue}
        />
      </FormContainer>
        {isHandleDependent &&
          (<div>
            <Button type="button" onClick={addDependents}>
              Adicionar Dependentes
            </Button>
            <RenderFormDependents
                numberDependents={numberOfDependents}
                setValue={setValue}
                register={register}
                errors={errors}
                defaultValues={defaultValues}
            />
            <FaPlus 
              size={40} 
              style={{ margin: "15px 5px", color: "#544bff", cursor: "pointer"}}
              onClick={addDependents} />
          </div>)
        }
      <Button onClick={handleSubmit(submitForm)}>Salvar Tudo</Button>
      {responseStatus.status && 
        <Message type={responseStatus.type}>
          {responseStatus.message}
        </Message>}
    </>
  );
};

export default FormEditProfile;
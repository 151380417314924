import React, { useEffect, useState } from "react";

import FormProfileProject from "../Components/FormProfileProject";

import apiJson from "../api/callApi";
import { inhotimProjectData } from "../utils/constants";

const ProjectData = () => {
  const [profileDefaultData, setProfileDefaultData] = useState(null);
  const getData = async () => {
    const results = await apiJson.getProjectProfileData(inhotimProjectData.projectId);
    setProfileDefaultData(results.data);
  };

  const handleData = async (data) => {
    await apiJson.updateProjectProfileData(inhotimProjectData.projectId, data);
    window.location.reload();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {profileDefaultData && (
        <FormProfileProject
          defaultValues={profileDefaultData}
          handleData={handleData}
        />
      )}
    </>
  );
};

export default ProjectData;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  text-align: right;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const SelectBox = styled.select`
  border: 0.5px solid black;
  font-size: 1.1rem;
  padding: 10px 10px;
  border-radius: 5px 0px 0px 5px;
  width: ${(props) => props.width};
  outline: none;

  @media (max-width: 1200px) {
    min-width: 35%;
  }

  @media (max-width: 920px) {
    width: 75%;
  }

  @media (max-width: 800px) {
    width: 70%;
  }
`;

const OptionBox = styled.option`
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

const Button = styled.button`
  border: 0.5px solid black;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;

  padding: 10px 10px;
  border-radius: 0px 5px 5px 0px;
  width: 12%;

  @media (max-width: 1200px) {
    min-width: 25%;
  }

  @media (max-width: 800px) {
    width: 30%;
  }
`;

const ExportSelect = ({ width = "20%" }) => {
  return (
    <Container>
      <SelectBox width={width} value="A">
        <OptionBox value="A">Export TXT</OptionBox>
      </SelectBox>
      <Button>Download</Button>
    </Container>
  );
};

export default ExportSelect;

import { parseISO, isAfter, isBetween, isWithinInterval } from "date-fns";

/**
 * Corrige o número
 * @param {number} str Numero.toFixed(2)
 * @returns 
 */
export function fixNumber(str){
  var arr,int,dec;
  str += '';

  arr = str.split('.');
  int = arr[0] + '';
  dec = arr.length>1?'.'+arr[1]:'';
  return int.replace(/(\d)(?=(\d{3})+$)/g,"$1.") + ',' + parseFloat(dec).toFixed(2).split('.')[1];
}

/**
   * Cuida do bloqueio anual de apoios com IR estando nos períodos 
   * @param {string} paymentMethod Tipo de pagamento que a pessoa está selecionando
   * @returns {boolean} True se a data de hoje está entre o período de bloqueio
   */
 export function isIRBlocked(paymentMethod) {
  /* LEMBRAR DE Trocar todo ano quando o pessoal solicitar o BLOCK de IR
   Exemplo: Se estamos em 2022, poe 2022 e é o block funcionará normalmente */
  const anoAtual = 2021;

  // Cria as datas do bloqueio
  let inicioBlockBoleto = new Date("0000-12-20T18:00:00"); // Trocar para 20
  let inicioBlockCartao = new Date("0000-12-24T12:00:00"); // Trocar para 24
  let fimBlock = new Date("0000-01-02T00:00:00");

  // Seta os anos para o ano atual
  inicioBlockBoleto.setFullYear(anoAtual);
  inicioBlockCartao.setFullYear(anoAtual);
  fimBlock.setFullYear(anoAtual+1);

  //console.log(fimBlock)

  const entre = isWithinInterval(new Date(), {
    start: paymentMethod === "boleto" ? inicioBlockBoleto : inicioBlockCartao,
    end: fimBlock,
  });

  return entre;
}

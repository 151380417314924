import React, { useEffect } from "react";
import styled from "styled-components";
import auth from "./api/authService";
import Navbar from "./Components/Navbar";
import { useUserObjectData } from "./Context/UserObjectData";
import { useHistory } from "react-router-dom";
import { RoutesMember } from "./routes.js";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Body = styled.div`
  margin: 0 auto;
  width: 85%;
  margin-left: 10%;
  padding-top: 30px;

  @media (max-width: 1200px) {
    width: 80%;
    margin-left: 15%;
  }

  @media (max-width: 800px) {
    width: 95%;
    margin: 0 auto;
    margin-top: 10%;
    padding-top: 20px;
  }
`;

const Footer = styled.div`
  display: none;

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
  }
`;

const Logo = styled.img`
  width: 30%;
  height: 30px;
  margin: 0 10px;
`;

const TextFooter = styled.h1`
  font-size: 1.2rem;
  font-family: Poppins;
  font-weight: 900;
  margin: 0;
  padding-right: 20px;
`;

const Main = () => {
  const { userObjectData, setUserObjectData } = useUserObjectData();
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      if (!userObjectData) {
        const userData = await auth.getCurrentUser();
        setUserObjectData(userData);
      }
    } catch (error) {
      history.push("/logout");
    }
  }, [history, setUserObjectData, userObjectData]);

  return (
    <>
      {userObjectData && (
        <>
          <Container>
            <Navbar type_user={userObjectData["type_user"]} />
            <Body>
              <RoutesMember userObjectData />
            </Body>
          </Container>
          <Footer>
            <TextFooter>membership.evoe.cc</TextFooter>
          </Footer>
        </>
      )}
    </>
  );
};

export default Main;

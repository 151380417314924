import React, { useState } from "react";
import styled from "styled-components";

import { isEmail } from "../utils/validDocument";

import apiJson from "../api/callApi";

import SearchMemberCard from "../Components/SearchMemberCard";

const Container = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 10px;
  position: relative;

  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
`;

const SearchField = styled.div`
  margin-top: 80px !important;
  width: 50%;
  margin: auto;

  @media (max-width: 1400px) {
    width: 60%;
  }

  @media (max-width: 1080px) {
    width: 80%;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const Input = styled.input`
  border: 1px solid #000;
  font-size: 1.2rem;
  padding: 10px 10px;
  border-radius: 5px 0px 0px 5px;
  width: 70%;
  font-family: "Inconsolata", monospace;
  @media (max-width: 800px) {
    width: 70%;
    box-sizing: border-box;
  }
`;

const Button = styled.button`
  border: 1px solid black;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 0px 5px 5px 0px;
  width: 25%;
  font-family: "Poppins", sans-serif;
  @media (max-width: 800px) {
    width: 30%;
    box-sizing: border-box;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  margin: auto;
  font-family: "Poppins", sans-serif;

  @media (max-width: 1400px) {
    width: 60%;
  }

  @media (max-width: 1080px) {
    width: 80%;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 5%;
  position: relative;
`;

const Checkbox = styled.input`
  margin: 0;
  zoom: 2;

  position: absolute;
  top: 3px;
`;

const Label = styled.p`
  margin: 0;
  font-size: 1.5rem;
  margin-left: 35px;
`;

const LabelError = styled.div`
  margin: 0;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  margin-top: 80px;
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
`;



const SearchMembers = () => {
  const [dataUser, setDataUser] = useState(null);
  const [field, setField] = useState("");
  const [error, setError] = useState("");

  const getData = async () => {
    try {
      const result = !isEmail(field) ? 
        await apiJson.getMemberByCpf(field.replace(/\D/g, ""))
      : await apiJson.getMemberByEmail(field);

      if(Object.keys(result.data).length > 0){
        setError(null);
        setDataUser(result.data);
      } else {
        setDataUser(null)
        setError(`Nenhum usuário encontrado para esse ${isEmail(field) ? 'E-mail' : 'CPF'}`);
      }
    } catch(err){
      setDataUser(null)
      setError('Nenhum usuário encontrado')
    }   
  };

  const handleSearch = () => {
    getData();
  };

  const pressKeys = (e) => {
    // Se apertar enter, pesquisa
    if (e.keyCode === 13) {
      handleSearch()
    }
  }

  return (
    <Container>
      <Title>Busca Membros</Title>
       
      <SearchField>
        <Input
          type={"text"}
          placeholder={"CPF ou Email"}
          name={"cpf_email"}
          onChange={event => setField(event.target.value)}
          value={field}
          onKeyDown={pressKeys}
        />
        <Button onClick={handleSearch}>Buscar</Button>
      </SearchField>
      {error && <LabelError>{error}</LabelError>}
      {dataUser && <SearchMemberCard dataUser={dataUser} />}
    </Container>
  );
};

export default SearchMembers;

import axios from "axios";

import { apiUrl } from "../config.json";
import { inhotimProjectData } from "../utils/constants";

const apiEndpoint = apiUrl + "/membership/";

const tokenKey = "token_access";

const getStartEndDate = async (objectDateFilter) => {
  let start_date = new Date(2015, 0, 1);
  const end_date = new Date();

  end_date.setDate(end_date.getDate());

  if (Object.keys(objectDateFilter).length === 0) {
    return { start_date, end_date };
  }

  start_date = new Date();
  start_date.setHours(0, 0, 0, 0);

  if (objectDateFilter["isDate"] === true) {
    start_date.setDate(objectDateFilter["value"][0].getDate());
    start_date.setFullYear(objectDateFilter["value"][0].getFullYear());
    start_date.setMonth(objectDateFilter["value"][0].getMonth());

    end_date.setDate(objectDateFilter["value"][1].getDate());
    end_date.setFullYear(objectDateFilter["value"][1].getFullYear());
    end_date.setMonth(objectDateFilter["value"][1].getMonth());
  } else if (objectDateFilter["isDate"] === false) {
    start_date.setDate(start_date.getDate() - objectDateFilter["value"]);
  }

  return { start_date, end_date };
};

const getMembersContributions = async (user_id, page) => {
  let dict_filter = {};

  dict_filter["page"] = page;
  dict_filter["user_id"] = user_id;
  dict_filter["access_token"] = localStorage.getItem(tokenKey);

  const result = await axios.get(apiEndpoint + "membro-contribuicoes", {
    params: dict_filter,
  });
  return result;
};

const getAllMembersContributions = async (
  project_id,
  objectDateFilter,
  searchContent,
  currentPage
) => {
  const { start_date, end_date } = await getStartEndDate(objectDateFilter);

  let dict_filter = {};

  dict_filter["start_date"] = start_date.toJSON();
  dict_filter["end_date"] = end_date.toJSON();
  dict_filter["project_id"] = project_id;
  dict_filter["search"] = searchContent;
  dict_filter["page"] = currentPage;
  dict_filter["access_token"] = localStorage.getItem(tokenKey);

  const result = await axios.get(apiEndpoint + "projeto-contribuicoes", {
    params: dict_filter,
  });
  return result;
};

const getUrlFormattedToDownloadPdf = async (
  project_id,
  objectDateFilter,
  searchContent,
  selectedFiltersState
) => {
  const { start_date, end_date } = await getStartEndDate(objectDateFilter);
  let url = `${apiEndpoint}report-payments?access_token=${localStorage.getItem(
    tokenKey
  )}&project_id=${project_id}`;
  url += `&start_date=${start_date.toJSON()}&end_date=${end_date.toJSON()}`;

  if (searchContent) {
    url += `&search=${searchContent}`;
  }

  selectedFiltersState?.forEach((filter) => {
    console.log(filter);
    url += `&${filter.keyPdf}=${filter.valuePdf}`;
  });

  return url;
};

const getAllAccessControl = async (project_id, currentPage) => {
  let dict_filter = {
    access_token: localStorage.getItem(tokenKey),
  };

  dict_filter["project_id"] = project_id;
  dict_filter["page"] = currentPage;
  dict_filter["access_token"] = localStorage.getItem(tokenKey);

  const result = await axios.get(apiEndpoint + "controle-acessos", {
    params: dict_filter,
  });
  return result;
};

const createAccessControl = async (data) => {
  const result = await axios.post(apiEndpoint + "controle-acessos", data, {
    params: {
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getAllStaffMembers = async (project_id) => {
  const result = await axios.get(apiEndpoint + "lista-empregados", {
    params: {
      project_id: project_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getMemberReward = async (user_id) => {
  const result = await axios.get(apiEndpoint + "recompensa-membro", {
    params: {
      user_id: user_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getMemberUser = async (user_id) => {
  const result = await axios.get(apiEndpoint + "dados-membro", {
    params: {
      user_id: user_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getAllUsersProject = async (
  project_id,
  objectDateFilter,
  searchContent,
  currentPage,
  selectedFiltersState
) => {
  const { start_date, end_date } = await getStartEndDate(objectDateFilter);

  let dict_filter = {};

  dict_filter["start_date"] = start_date.toJSON();
  dict_filter["end_date"] = end_date.toJSON();
  dict_filter["subscription__projeto__id"] = project_id;
  dict_filter["search"] = searchContent;

  // Se receber -1 da função, busca todos os dados sem paginação
  if (currentPage !== -1) {
    dict_filter["page"] = currentPage;
  } else {
    dict_filter["no_page"] = "";
  }

  // Adiciona os filtros para as querys
  selectedFiltersState.forEach((f) => {
    dict_filter[f.key] = f.value;
  });

  //console.log(selectedFiltersState);

  //dict_filter["access_token"] = localStorage.getItem(tokenKey);

  const result = await axios.get(apiEndpoint + "lista-membros", {
    params: dict_filter,
  });
  return result;
};

const getAllUsersProjectExport = async (
  project_id,
  objectDateFilter,
  searchContent,
  selectedFiltersState
) => {
  /*const { start_date, end_date } = await getStartEndDate(objectDateFilter);

  let dict_filter = {};

  dict_filter["start_date"] = start_date.toJSON();
  dict_filter["end_date"] = end_date.toJSON();
  dict_filter["subscription__projeto__id"] = project_id;

  if (searchContent) {
    dict_filter["search"] = searchContent;
  }

  // Adiciona os filtros para as querys
  selectedFiltersState.forEach((f) => {
    dict_filter[f.key] = f.value;
  });

  dict_filter["no_page"] = "";*/

  const { start_date, end_date } = await getStartEndDate(objectDateFilter);
  let url = `${apiEndpoint}export-excel?access_token=${localStorage.getItem(
    tokenKey
  )}&project_id=${project_id}`;
  url += `&start_date=${start_date.toJSON()}&end_date=${end_date.toJSON()}`;

  if (searchContent) {
    url += `&search=${searchContent}`;
  }

  selectedFiltersState?.forEach((filter) => {
    console.log(filter);
    url += `&${filter.keyPdf}=${filter.valuePdf}`;
  });

  //console.log(selectedFiltersState);

  //dict_filter["access_token"] = localStorage.getItem(tokenKey);

  const result = await axios.get(url, {
    //params: dict_filter,
  });
  return result;
};

const getMemberByCpf = async (cpf_member) => {
  const result = await axios.get(apiEndpoint + "busca-membro-cpf", {
    params: {
      cpf_member: cpf_member,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getMemberByEmail = async (email_member) => {
  const result = await axios.get(apiEndpoint + "busca-membro-email", {
    params: {
      email_member: email_member,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getMemberContributionInfo = async (contribution_id) => {
  const result = await axios.get(apiEndpoint + "contribuicao-detalhada", {
    params: {
      contribution_id: contribution_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

/**
 * Pega o log de acesso pelo id do acesso
 * @param {number} access_id Id do log de acesso
 * @returns Retorna resultado em formato JSON
 */
const getAccessLogByID = async (access_id) => {
  const result = await axios.get(apiEndpoint + "controle-acessos-detalhados", {
    params: {
      entrada_id: access_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getPaymentData = async (subscription_id) => {
  const result = await axios.get(apiEndpoint + "dados-pagamento-confirmado", {
    params: {
      id: subscription_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getStaffInfo = async (staff_id) => {
  const result = await axios.get(apiEndpoint + "empregado-detalhado", {
    params: {
      staff_id: staff_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getSalesStaff = async (staff_id) => {
  const result = await axios.get(apiEndpoint + "membros-vendedor", {
    params: {
      staff_id: staff_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getRewards = async (project_id) => {
  const result = await axios.get(apiEndpoint + "lista-recompensas", {
    params: {
      project_id: project_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getValidateProject = async (project_id) => {
  const result = await axios.get(apiEndpoint + "validar-projeto", {
    params: {
      project_id: project_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const createReward = async (data) => {
  const result = await axios.post(apiEndpoint + "lista-recompensas", data, {
    params: {
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getUpdates = async (project_id) => {
  const result = await axios.get(apiEndpoint + "lista-atualizacoes", {
    params: {
      project_id: project_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getUserProfileData = async (user_id) => {
  const result = await axios.get(apiEndpoint + "editar-perfil-membro", {
    params: {
      user_id: user_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getSpecificReward = async (reward_id) => {
  const result = await axios.get(apiEndpoint + "recompensa-especifica", {
    params: {
      reward_id: reward_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const updateSpecificReward = async (reward_id, data) => {
  const result = await axios.put(apiEndpoint + "recompensa-especifica", data, {
    params: {
      reward_id: reward_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getMemberDependents = async (user_id) => {
  const result = await axios.get(apiEndpoint + "dependentes", {
    params: {
      user_id: user_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const createMemberDependents = async (user_id, data) => {
  const result = await axios.post(apiEndpoint + "dependentes", data, {
    params: {
      user_id: user_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const createFinalizedData = async (data) => {
  //console.log(data)
  const result = await axios.post(apiEndpoint + "finalizar-apoio", data, {
    params: {
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const updateUserProfileData = async (user_id, data) => {
  const result = await axios.put(apiEndpoint + "editar-perfil-membro", data, {
    params: {
      user_id: user_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getProjectProfileData = async (project_id) => {
  const result = await axios.get(apiEndpoint + "editar-perfil-projeto", {
    params: {
      project_id: project_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getFinancialTransactions = async (project_id) => {
  const result = await axios.get(
    apiEndpoint + "movimentacoes-membros/" + project_id,
    {
      params: {
        access_token: localStorage.getItem(tokenKey),
      },
    }
  );
  return result;
};

const postFinancialTransactions = async (project_id, data) => {
  const result = await axios.post(
    apiEndpoint + "movimentacoes-membros/" + project_id,
    data,
    {
      params: {
        access_token: localStorage.getItem(tokenKey),
      },
    }
  );
  return result;
};

const getFinancialTransactionsBlockAccount = async (project_id) => {
  const result = await axios.get(
    apiEndpoint + "movimentacoes-membros-bloqueado/" + project_id,
    {
      params: {
        access_token: localStorage.getItem(tokenKey),
      },
    }
  );
  return result;
};

const postFinancialTransactionsBlockAccount = async (project_id, data) => {
  const result = await axios.post(
    apiEndpoint + "movimentacoes-membros-bloqueado/" + project_id,
    data,
    {
      params: {
        access_token: localStorage.getItem(tokenKey),
      },
    }
  );
  return result;
};

const updateProjectProfileData = async (project_id, data) => {
  const result = await axios.put(apiEndpoint + "editar-perfil-projeto", data, {
    params: {
      project_id: project_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getBankData = async (project_id, law_data = null) => {
  const result = await axios.get(apiEndpoint + "editar-dados-bancarios", {
    params: {
      project_id: project_id,
      law_data: law_data,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};
const updateBankData = async (project_id, data, law_data = null) => {
  const result = await axios.put(apiEndpoint + "editar-dados-bancarios", data, {
    params: {
      project_id: project_id,
      law_data: law_data,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const updateUserPassword = async (user_id, data) => {
  const result = await axios.put(apiEndpoint + "alterar-senha", data, {
    params: {
      user_id: user_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getUserInfo = async (user_id, project_id) => {
  const result = await axios.get(apiEndpoint + "info-usuarios", {
    params: {
      user_id: user_id,
      project_id: project_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const getTableDependents = async (user_id, project_id) => {
  const result = await axios.get(apiEndpoint + "dependentes", {
    params: {
      user_id: user_id,
      project_id: project_id,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const createSubscriptionMember = async () => {
  const send_data = {
    projeto: inhotimProjectData.projectId,
    status: "1",
    valor_contribuicao: "50",
    recompensa: inhotimProjectData.defaultRewardId,
  };
  const result = await axios.post(apiUrl + "/subscription/", send_data, {
    params: {
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

export const saveDependents = async (userId, dependents) => {
  return axios.post(apiEndpoint + "dependentes", dependents, {
    params: {
      user_id: userId,
      access_token: localStorage.getItem(tokenKey),
    },
  });
};

export const getTxtFile = async (data) => {
  const result = await axios.get(apiEndpoint + "buscar-txt", {
    params: {
      data: data,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

/*export const exportFile = async (type, data) => {
  //console.log(data)
  const result = await axios.post(apiEndpoint + "export-relatorios", data, {
    params: {
      tipo: type,
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};*/

export const getValidDates = async () => {
  const result = await axios.get(apiEndpoint + "buscar-txt", {
    params: {
      todos: "true",
      access_token: localStorage.getItem(tokenKey),
    },
  });
  return result;
};

const translatePlan = async (texto) => {
  let convertedText = "";
  return axios
    .post(
      "https://translation.googleapis.com/language/translate/v2",
      {},
      {
        params: {
          q: texto,
          target: "en",
          key: "AIzaSyCHUCmpR7cT_yDFHC98CZJy2LTms-IwDlM",
        },
      }
    )
    .then((response) => {
      convertedText = response.data.data.translations[0].translatedText;
      return convertedText;
    })
    .catch((err) => {
      console.log("rest api error", err);
    });
};

export default {
  getMembersContributions,
  getMemberContributionInfo,
  getMemberReward,
  getMemberByCpf,
  getAllUsersProject,
  getAllMembersContributions,
  getAllAccessControl,
  getAllStaffMembers,
  getStaffInfo,
  getSalesStaff,
  getRewards,
  getUpdates,
  getUserInfo,
  getTableDependents,
  createSubscriptionMember,
  getUserProfileData,
  updateUserProfileData,
  updateUserPassword,
  getMemberDependents,
  createMemberDependents,
  getSpecificReward,
  updateSpecificReward,
  createReward,
  getProjectProfileData,
  updateProjectProfileData,
  getBankData,
  updateBankData,
  getValidateProject,
  createFinalizedData,
  getFinancialTransactions,
  getMemberUser,
  getMemberByEmail,
  createAccessControl,
  getFinancialTransactionsBlockAccount,
  postFinancialTransactions,
  postFinancialTransactionsBlockAccount,
  getPaymentData,
  translatePlan,
  getAccessLogByID,
  getTxtFile,
  getValidDates,
  getAllUsersProjectExport,
  getUrlFormattedToDownloadPdf,
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { FaTools } from 'react-icons/fa';

import Greetings from "../Components/Greetings";
import ProjectData from "../Components/ProjectData";
import BankData from "../Components/BankData";

import ContainerOptions from "../Components/ContainerOptions";

import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const customizable_options = [
  {
    key_value: "project_data",
    label: "Dados Inhotim",
  },
  {
    key_value: "bank_data",
    label: "Conta Bancária",
  },
];
const Container = styled.div`
  padding-bottom: 100px;
  height: 1vh;
  max-height: 1vh;
  overflow: hidden;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  min-height: 50vh;
  border: 1px solid black;
  border-top: none;
  box-sizing: border-box;

  @media (max-width: 800px) {
    border: 1px solid black;
    margin-top: 20px;
    display: none;
  }
`;

const OverlayEmBreve = styled.div`
  height: 100vh;
  width: 90%;
  background-color: #fff;
  position: absolute;
  z-index: 100;

  @media (max-width: 800px) {
    width: 95%;
  }
`;

const ContainerMobile = styled.div`
  margin-top: 30px;
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;
const ContainerOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const TitleMobile = styled.h3`
  margin: 0;
`;

const ArrowIconDown = styled(FaChevronDown)`
  padding: 0px 10px;
`;

const ArrowIconRight = styled(FaChevronRight)`
  padding: 0px 10px;
`;

const Settings = ({ location }) => {
  const [activeValue, setActiveValue] = useState();

  const handleChangeOption = (key) => {
    key === activeValue ? setActiveValue() : setActiveValue(key);
  };

  useEffect(() => {
    //console.log(location);
    location.valueSelected
      ? setActiveValue(location.valueSelected)
      : setActiveValue("project_data");
  }, [location]);
  return (
    <Container>

      <OverlayEmBreve>
        <FaTools style={{ fontSize: "30px", marginTop: "20px" }}/>
        <h1 style={{ color: "#000" }}>Essa página estará disponível em breve!</h1>
        <h3>Enquanto isso qualquer dado, informação ou ação necessária pode ser solicitado para contato@evoe.cc</h3>
      </OverlayEmBreve>

      <Greetings has_subtitle={false} />
      <ContainerMobile>
        {customizable_options.map((data) => (
          <div key={data.id}>
            <ContainerOption onClick={() => handleChangeOption(data.key_value)}>
              <TitleMobile>{data.label}</TitleMobile>
              {data.key_value === activeValue ? (
                <ArrowIconDown />
              ) : (
                <ArrowIconRight />
              )}
            </ContainerOption>
            {activeValue === "project_data" &&
              data.key_value === activeValue && <ProjectData />}
            {activeValue === "bank_data" && data.key_value === activeValue && (
              <BankData />
            )}
          </div>
        ))}
      </ContainerMobile>
      <ContainerOptions
        options={customizable_options}
        activeValue={activeValue}
        handleChangeOption={handleChangeOption}
      />
      <ContentContainer>
        {activeValue === "project_data" && <ProjectData />}
        {activeValue === "bank_data" && <BankData />}
      </ContentContainer>
    </Container>
  );
};

export default Settings;

// import Integrations from "../Components/Integrations";

// {activeValue === "integrations" &&
// data.key_value === activeValue && <Integrations />}
// {activeValue === "integrations" && <Integrations />}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import apiJson from "../api/callApi";
import { useLocation } from "react-router-dom";
import { labelsLanguage } from "../utils/language";
import { useForm } from "react-hook-form";
import FormField from "../Components/FormField";
import { yupResolver } from "@hookform/resolvers/yup";
import Arrow from "../Icons/caret.svg";
import * as yup from "yup";
import { saveDependents } from "../api/callApi";
import { unMask } from "remask";
import LoadingComponent from "../Components/LoadingComponent";
import Footer from "../Components/Footer";
import projectLogo from "../assets/placeholder-projeto.png";
import { shade } from "polished";
import Helmet from "react-helmet";

import {
  RenderFormDependents,
  dependentsFieldsfields,
  formatDataDependents,
  planAllowsDependent,
} from "../Components/DependentsForm";

const Container = styled.div`
  padding: 2% 2% 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  //padding-bottom: 50px;

  width: 70%;
  margin: auto;
  margin-top: 45px;
  margin-bottom: 45px;
  background-color: white;

  // shadow na box
  -webkit-box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.59);

  @media (max-width: 1200px) {
    width: 95%;
    margin-top: 2%;
  }

  @media (max-width: 1024px) {
    width: 95%;
    margin-top: 2%;
  }
`;

const LogoArea = styled.div`
  //border: 1px solid orange;

  flex-direction: column;
  text-align: center;
  padding-top: 11px;
  height: fit-content;

  //width: 15%;
  //height: 70px;

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    //width: 100%;
    //height: 100px;

    img {
      //width: 20%;
    }

    h1 {
      font-size: 0.1rem;
    }
  }

  img {
    width: 80px;
  }

  h1 {
    color: #3aa479;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

const Content = styled.div`
  width: 100%;
  //min-height: 40vh;
  border-top: none;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
  min-height: 724px;

  //border: 1px solid black;
  padding: 30px;
`;

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  text-align: center;
  width: 90%;

  margin: 20px auto;
  font-size: 3rem;
  font-family: "Poppins", sans-serif;

  @media (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

const Text = styled.p`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  text-align: center;
  width: ${(props) => (props.width ? props.width : "65%")};
  font-size: ${(props) => props.size};
  margin: auto;
  margin-bottom: ${(props) => props.margin};
  font-family: "Inconsolata", monospace;

  .subtitle {
    margin-bottom: 30px;
  }

  @media (max-width: 900px) {
    width: 65%;
  }

  a {
    color: black;
  }
`;

const Button = styled.button`
  margin: 0;
  margin-top: 50px;
  font-size: 1.3rem;
  background-color: #8069ae;
  border: none;
  padding: 10px;
  width: 50%;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-weight: bold;
  color: white;
  min-height: 65px;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#8069AE")};
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
  margin-bottom: 44px;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  //padding-bottom: 100px;

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

/*const Logo = styled.img`
  width: 10%;
  height: auto;
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  margin-left: auto;
  right: 0;

  @media (max-width: 920px) {
    width: 20%;
  }
`;*/

const FormContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 10px;

  @media (max-width: 920px) {
    padding: 0px;
    margin-right: 0px;
  }
`;

const Message = styled.h2`
  text-align: center;
  color: ${(props) => (props.type === "success" ? "green" : "red")};
`;

const handleTexts = (methodInPath, langague) => {
  let title = "";
  let labelDownload = "";
  // eslint-disable-next-line default-case
  switch (methodInPath) {
    case "boleto":
      title = labelsLanguage[langague]["titleBillet"];
      labelDownload = labelsLanguage[langague]["labelDownload"];
      break;
    case "credit_card":
      title = labelsLanguage[langague]["titleCreditCard"];
      break;
    case "pix":
      title = "Transferência PIX feita com sucesso";
      break;
  }

  return { title, labelDownload };
};

const getData = async (id) => {
  const result = await apiJson.getPaymentData(id);
  const result_json = JSON.parse(result.data.retorno_api_raw);

  return result_json.payment;
};

const PaymentSuccess = () => {
  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(dependentsFieldsfields()),
  });
  const { errors } = formState;
  const location = useLocation();
  const language = location.pathname.includes("pt") ? "pt" : "en";
  const method = localStorage.getItem("paymentMethod");

  const [paymentData, setPaymentData] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState({
    status: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    if (method === "boleto") {
      const queryString = location.search;

      getData(queryString.split("?id=")[1])
        .then((response) => {
          setPaymentData(response);
        })
        .catch((err) => console.log(err));
    }
  }, [location.search, method]);

  const { title, labelDownload } = handleTexts(method, language);

  const userId = localStorage.getItem("userId");
  const [showFormDependents, setShowFormDependents] = useState(() => {
    const selectPlanUser = localStorage.getItem("planName");
    return planAllowsDependent(selectPlanUser) && userId;
  });

  const submitForm = async (data) => {
    try {
      setShowLoading(true);

      // Formata os dados no Padrão da API
      const dependentsFormatted = formatDataDependents(data);

      await saveDependents(userId, dependentsFormatted);

      // Limpa os dados do LocalStorage
      localStorage.removeItem("planName");
      localStorage.removeItem("userId");
      setShowFormDependents(false);

      setResponseStatus({
        status: true,
        type: "success",
        message: "Informações Salvas com Sucesso!",
      });
    } catch (err) {
      setResponseStatus({
        status: true,
        type: "error",
        message: "Não foi possível realizar esta solicitação no momento!",
      });
    }

    setShowLoading(false);
  };
  return (
    <>
      <Helmet>
        {/* Add o background nessas paginas */}
        <style>{`body {background-position:0;}`}</style>
      </Helmet>

      <Container>
        {showLoading && <LoadingComponent />}
        <Content>
          <LogoContainer>
            <LogoArea>
              <img alt="alt" src={projectLogo} />
              <h1>{labelsLanguage[language]["projectName"]}</h1>
            </LogoArea>
          </LogoContainer>
          <Title>{title}</Title>
          {labelDownload && (
            <>
              <Text margin="10px">{labelDownload}</Text>
              <ContainerButton>
                <Button
                  type="submit"
                  onClick={() => window.open(paymentData.boleto_url)}
                >
                  {labelsLanguage[language]["billetDownload"]}
                </Button>
              </ContainerButton>
            </>
          )}
          {method === "boleto" && (
            <Text className="subtitle" margin="30px" width="450px">
              {labelsLanguage[language]["subTitleBillet"]}
            </Text>
          )}
          {method === "credit_card" && (
            <Text className="subtitle" margin="30px" bold size="1.4rem">
              {labelsLanguage[language]["subTitleCard"]}
            </Text>
          )}

          {method === "credit_card" && (
            <>
              <Text margin="30px" width="650px">
                {labelsLanguage[language]["body"]}
              </Text>
              <Text margin="30px">
                {labelsLanguage[language]["question"]}:
                <a
                  href="mailto: contato@evoe.cc"
                  target="_blank"
                  rel="noreferrer"
                >
                  contato@evoe.cc
                </a>
              </Text>
            </>
          )}
          <Text bold>{labelsLanguage[language]["importantLabel"]}:</Text>
          {method === "boleto" ? (
            <Text width="300px">
              {labelsLanguage[language]["importantBillet"]}
            </Text>
          ) : (
            <Text>{labelsLanguage[language]["importantCreditCard"]}</Text>
          )}
          {responseStatus.status && (
            <Message type={responseStatus.type}>
              {responseStatus.message}
            </Message>
          )}
          {showFormDependents && (
            <FormContainer>
              <ContainerButton>
                <Button type="submit" onClick={handleSubmit(submitForm)}>
                  {labelsLanguage[language]["buttonDependents"]}
                </Button>
              </ContainerButton>
              <RenderFormDependents
                numberDependents={4}
                setValue={setValue}
                register={register}
                errors={errors}
              />
            </FormContainer>
          )}
        </Content>
        <Footer />
      </Container>
    </>
  );
};

export default PaymentSuccess;

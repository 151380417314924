import React, { useEffect, useState } from "react";
import styled from "styled-components";

import apiJson from "../api/callApi";

import { NavLink } from "react-router-dom";

import ContainerDetailsCard from "../Components/ContainerDetailsCard";
import DetailsInfo from "../Components/DetailsInfo";
import HeaderLink from "../Components/HeaderLink";
import { fixNumber } from "../utils/utils";

const Button = styled.button`
  margin-top: 30px;
  border: none;
  background-color: #544bff;
  color: white;
  padding: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  position: relative;
  bottom: 15px;
  width: 40%;

  @media (max-width: 1400px) {
    width: 50%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 50px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const BoxStatus = styled.div`
  background-color: #1bba1f;
  width: 20%;
  padding: 10px 30px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  & > p {
    margin: 0px;
    color: white;
    font-weight: bold;
  }
`;

const ValorTransaction = styled.h3`
  margin: 0;
  color: #544bff;
  font-size: 3rem;
  font-family: monospace;
  margin-bottom: 10px;
`;

const TitleCard = styled.h2`
  margin: 0;
  font-size: 2rem;
  margin-bottom: 10px;
`;

const TextReward = styled.p`
  margin: 0;
  white-space: pre-line;
  margin: 15px;
  line-height: 25px;
`;

const TypeReward = styled.h2`
  margin: 0;
  color: #544bff;
`;

const details__subscription = [
  {
    key_id: "name",
    label: "Entrada realizada por:",
    value: "",
  },
  {
    key_id: "access_date",
    label: "Data da entrada:",
    value: "",
  },
  {
    key_id: "reward_name",
    label: "Recompensa:",
    value: "",
  },
];

const details__member = [
  {
    key_id: "email",
    label: "Email:",
    value: "example@example.com",
  },
  {
    key_id: "document",
    label: "CPF/CNPJ:",
    value: "000.000.000-00",
  },
  {
    key_id: "phone",
    label: "Telefone:",
    value: "(00) 0000-0000",
  },
  {
    key_id: "address",
    label: "Endereço",
    value: "Rua Pastor Rui Franco, 386, Belo Horizonte - MG, 00000-000",
  },
];

const details__payment = [
  {
    key_id: "payment_type",
    label: "Tipo pagamento:",
    value: "Cartão de crédito Mastercard",
  },
  {
    key_id: "name",
    label: "Nome informado:",
    value: "Daniel Souza",
  },
  {
    key_id: "card_number",
    label: "Número:",
    value: "....0000",
  },
  {
    key_id: "parcelas",
    label: "Parcelas:",
    value: "1",
  },
  {
    key_id: "amount_contribution",
    label: "Valor Bruto:",
    value: "R$ 25,00",
  },
  {
    key_id: "payment_date",
    label: "Data prevista:",
    value: "00/0000",
  },
];

const UserEntries = ({ location }) => {
  const [dataContribution, setDataContribution] = useState(null);
  const [detailsSubscription, setDetailsSubscription] = useState(
    details__subscription
  );

  const [detailsMember, setDetailsMember] = useState(details__member);
  const [detailsPayment, setDetailsPayment] = useState(details__payment);

  const getData = async (contribution_id) => {
    const objectData_detailsSubscription = [...detailsSubscription];
    const objectData_detailsMember = [...detailsMember];
    const objectData_detailsPayment = [...detailsPayment];

    const result = await apiJson.getAccessLogByID(contribution_id);

    objectData_detailsSubscription.map(
      (data) => (data["value"] = result.data[data["key_id"]])
    );

    objectData_detailsMember.map(
      (data) => (data["value"] = result.data[data["key_id"]])
    );

    objectData_detailsPayment.map(
      (data) => (data["value"] = result.data[data["key_id"]])
    );

    setDetailsSubscription(objectData_detailsSubscription);
    setDetailsMember(objectData_detailsMember);
    setDetailsPayment(objectData_detailsPayment);

    setDataContribution(result.data);
  };

  useEffect(() => {
    const pathname = location.pathname;
    getData(pathname.split("/")[2]);
  }, []);
  return (
    <>
      <HeaderLink url="/entradas" label_link="Entradas" label_info="" />
      <h2>Detalhes da entrada:</h2>
      {dataContribution && (
        <Container>
          <ContainerDetailsCard color="#ff6e00">
            <ValorTransaction>
              R$ {fixNumber(dataContribution.reward_value.toFixed(2))}
            </ValorTransaction>
            {details__subscription.map((data) => (
              <DetailsInfo
                key={data.key_id}
                label={data.label}
                value={data.value}
              />
            ))}
            <NavLink
              to={`/usuarios/${dataContribution["user_id"]}`}
              exact={true}
            >
              <Button>Ver Usuário</Button>
            </NavLink>
          </ContainerDetailsCard>
          {/*<ContainerDetailsCard>
            <TitleCard>Nome do usuario</TitleCard>
            {details__member.map((data) => (
              <DetailsInfo
                key={data.key_id}
                label={data.label}
                value={data.value}
              />
            ))}
            <NavLink
              to={`/usuarios/${dataContribution["user_id"]}`}
              exact={true}
            >
              <Button>Ver Usuário</Button>
            </NavLink>
            </ContainerDetailsCard>*/}
          {/*<ContainerDetailsCard>
            <TitleCard>Recompensa</TitleCard>
            <TypeReward>{`${dataContribution.reward_name} - R$ ${Number(
              dataContribution.reward_value
            ).toFixed(2)}`}</TypeReward>
            <TextReward>{dataContribution["reward_description"]}</TextReward>
            {dataContribution.card_url_file && (
              <Button
                onClick={() => window.open(dataContribution.card_url_file)}
              >
                Download Carteirinha
              </Button>
            )}
            </ContainerDetailsCard>*/}
          {/*<ContainerDetailsCard>
            <TitleCard>Método de pagamento</TitleCard>
            {details__payment.map((data) => (
              <DetailsInfo
                key={data.key_id}
                label={data.label}
                value={data.value}
              />
            ))}
            </ContainerDetailsCard>*/}
        </Container>
      )}
    </>
  );
};

export default UserEntries;

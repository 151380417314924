import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-US";

import MaskedInput from 'react-maskedinput';
import moment from 'moment';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.margin_top};
  margin-bottom: ${(props) => props.margin_bottom};
  width: ${(props) => props.width};

  .react-datepicker__day--outside-month {
    color: transparent;
    visibility: hidden;
    pointer-events: none;
  }

  input {
    width: 100%;
    border: 1px solid black;
    font-size: 1.2rem;
    padding: 10px 10px;
    box-sizing: border-box;
    font-family: "Inconsolata", monospace;
  }

  @media (max-width: 920px) {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
    padding: 10px;
  }
`;

const LabelInput = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "Inconsolata", monospace;

  @media (max-width: 920px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const LabelError = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  color: red;
  @media (max-width: 920px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const ContainerInput = styled.div`
  width: 100%;
  position: relative;
`;

const FormFieldDatePicker = ({
  registro,
  name,
  label = null,
  errorMsg = "",
  width = "100%",
  margin_top = "0",
  margin_bottom = "0",
  defaultValue = new Date(),
  setValueFormState = () => {},
  language,
}) => {
  const [value, setValue] = useState(()=> {
    setValueFormState(name, defaultValue);
    return defaultValue;
  });
  const [valueInDate, setValueInDate] = useState(new Date());

  const dateFormat = language === "pt" ? pt : en;
  const a = language === "pt" ? "pt" : "en";

  const [formattedDate, setFormattedDate] = useState("dd/MM/yyyy");

  registerLocale(a, dateFormat);

  /**
   * Troca o formato da data
   */
  useEffect(() => {
    const dateFormatter = language === "pt" ? "dd/MM/yyyy" : "MM/dd/yyyy";
    setFormattedDate(dateFormatter);
  }, [language]);

  /**
   * Atualiza a data
   * @param {string} date 
   */
  const updateDate = (date) => {
    const format = language === "pt" ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
    let fieldValue = moment(date).isValid() ? moment(date).format(format) : date;
      setValue(fieldValue);
      setValueFormState(name, fieldValue);

      setValueInDate(date);
      //console.log(date)
  }

  return (
    <Container
      width={width}
      margin_top={margin_top}
      margin_bottom={margin_bottom}
    >
      {label && <LabelInput>{label}</LabelInput>}
      <ContainerInput>
        <DatePicker
          type="text"
          value={value}
          selected={valueInDate}
          {...registro(name)}
          locale={a}
          onChange={(value) => updateDate(value)}  
          dateFormat={formattedDate}
          customInput={
            <MaskedInput mask="11/11/1111" placeholder= { language === "pt" ? "dd/mm/yyyy" : "mm/dd/yyyy"}  onChange={(value) => updateDate(value)} size="11"/>
          }
        />
        {errorMsg && <LabelError>{errorMsg}</LabelError>}
      </ContainerInput>
    </Container>
  );
};

export default FormFieldDatePicker;

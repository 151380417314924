import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaPlus } from "react-icons/fa";

import FormField from "./FormField";
import apiJson from "../api/callApi";

const Container = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    margin-top: 50px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Button = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 30%;
  margin-bottom: 20px;
  display: block;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const schema = yup.object().shape({
  dependents: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Campo Obrigatório"),
      document: yup
        .string()
        .matches(
          /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
          "Confira o Formato"
        )
        .required("Campo Obrigatório"),
      relation: yup.string().required("Campo Obrigatório"),
    })
  ),
});

const FormDependents = () => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const submitForm = async (data) => {
    await apiJson.createMemberDependents(44234, data);
    window.location.reload();
  };

  return (
    <Container onSubmit={handleSubmit(submitForm)}>
      <FormContainer>
        <FormField
          label="Nome:"
          width="30%"
          margin_bottom="10px"
          name={"name"}
          registro={register}
          errorMsg={errors.name?.message}
        />
        <FormField
          label="CPF:"
          width="30%"
          margin_bottom="10px"
          name={"document"}
          registro={register}
          errorMsg={errors.document?.message}
        />
        <FormField
          label="Relação:"
          width="30%"
          margin_bottom="10px"
          name={"relation"}
          registro={register}
          errorMsg={errors.relation?.message}
        />
      </FormContainer>
      <Button>Adicionar Dependente</Button>
    </Container>
  );
};

export default FormDependents;

{
  /* <FaPlus
  size={40}
  style={{
    margin: "15px 5px",
    color: "#544bff",
    cursor: "pointer",
  }}
  onClick={() => {
    addDepends();
  }}
/>; */
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Arrow from "../Icons/caret.svg";
import FormField from "../Components/FormField";
import FormFieldDatePicker from "../Components/FormFieldDatePicker";
import json from "../json_objects/countries";
import { labelsLanguage } from "../utils/language";

import { useLanguageState } from "../Context/Language";
import { useNationalityState } from "../Context/Nationality";

import {
  isDocumentValid,
  isValidCEP,
  isValidBirthday,
} from "../utils/validDocument";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ExternalLink } from "react-external-link";
import getAddresByCep from "../utils/getAddressByCEP";

import projectLogo from "../assets/placeholder-projeto.png";
import { shade } from "polished";

const Container = styled.form`
  min-height: 60vh;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: "Inconsolata", monospace;

  //padding: 50px 0;
  padding: 30px;

  @media (max-width: 800px) {
    //border: 1px solid black;
    margin-bottom: 50px;
  }
`;

const FormContainer = styled.div`
  width: 48%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
  }
`;

const ContainerSelect = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
  }
`;

/*const Button = styled.button`
  margin: 0;
  margin-top: 10px;
  font-size: 1.2rem;
  border: none;
  width: 100%;
  height: 50px;
  cursor: pointer;
  background-color: #1b724f;
  color: #fff;
  font-family: "Inconsolata", monospace;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
  }
`;*/

const Button = styled.button`
  margin: 0;
  margin-top: 50px;
  font-size: 1.5rem;
  background-color: #148a80;
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-weight: bold;
  color: white;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#148a80")};
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const SelectBox = styled.select`
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 15px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;

  font-family: "Inconsolata", monospace;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }
  background-color: #ffffff;
`;

const LabelInput = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "Inconsolata", monospace;

  @media (max-width: 800px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

/*const LogoContainer = styled.div`
  width: 100%;
  display: block;
`;*/
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  //padding-bottom: 100px;

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const LogoArea = styled.div`
  //border: 1px solid orange;

  flex-direction: column;
  text-align: center;
  padding-top: 11px;
  height: fit-content;

  //width: 15%;
  //height: 70px;

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    //width: 100%;
    //height: 100px;

    img {
      //width: 20%;
    }

    h1 {
      font-size: 0.1rem;
    }
  }

  img {
    width: 80px;
  }

  h1 {
    color: #3aa479;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

/*const Logo = styled.img`
  width: 10%;
  height: auto;
  float: right;
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  right: 0;

  @media (max-width: 920px) {
    width: 20%;
  }
`;*/

const userDatailSchema = yup.object().shape({
  email: yup.string().email("Email Inválido").required("Email Inválido"),
  confirmEmail: yup
    .string()
    .email("Email de Confirmação Inválido")
    .when("email", (email, field) =>
      email
        ? field
            .required("Email de confirmação inválido")
            .oneOf([yup.ref("email")], "Email de Confirmação diferente")
        : field
    )
    .required("Email de Confirmação Inválido"),
  document: yup
    .string()
    .required("Documento Inválido")
    .when("country", {
      is: "BR",
      then: yup
        .string()
        .min(14, "CPF Inválido!")
        .test("isCPFValid", "Documento Inválido", (value) =>
          isDocumentValid(value)
        ),
    }),
  phone_number: yup.string().required("Número Inválido"),
  address: yup.string().required("Endereço Inválido"),
  complementary: yup.string().notRequired(),
  zipcode: yup
    .string()
    .required("CEP Inválido")
    .nullable("")
    .when("country", {
      is: "BR",
      then: yup
        .string()
        .test("validCEP", "CEP Inválido", (value) => isValidCEP(value)),
    }),
  birthday: yup
    .string()
    .required("Data de Nascimento inválida")
    .nullable("Data de Nascimento vazia"),
    /*.test("validBirthday", "Data de nascimento inválida", (value) =>
      isValidBirthday(value)
    ),*/
  street_number: yup.string().required("Valor Inválido"),
  state: yup.string().required("Estado Inválido"),
  neighborhood: yup
    .string()
    .required("Bairro Inválido")
    .nullable("Bairro vazia"),
  city: yup.string().required("Valor Inválido"),
  name: yup.string().required("Nome Inválido"),
});

const FormPaymentUser = ({ handleSubmitPayment, defaultValues }) => {
  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(userDatailSchema),
    defaultValues: defaultValues,
  });

  const { errors } = formState;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    defaultValues?.country?.toUpperCase() || "BR"
  );

  const { language } = useLanguageState();

  const { nationality, setNationality } = useNationalityState();

  const handleCep = async (cep) => {
    if (selectedCountry !== "BR") {
      return;
    }

    const address = await getAddresByCep(cep);

    if (address) {
      setValue("city", address?.city);
      setValue("neighborhood", address?.neighborhood);
      setValue("state", address?.state);
      setValue("address", address?.street);
    }
  };

  const submitForm = (data) => {
    data.country = selectedCountry.toLowerCase();
    data.document = data.document.replace(/[^\d]+/g, "");
    data.phone_number = data.phone_number.replace(/[^\d]+/g, "");
    data.nationality = nationality;

    if (nationality !== "pt") {
      data.document = "0";
    }

    // Coloquei isso aqui
    //console.log(data);

    handleSubmitPayment(data);
  };

  useEffect(() => {
    setCountries(json["countries_json"]);
  }, []);

  return (
    <Container onSubmit={handleSubmit(submitForm)}>
      <LogoContainer>
        {/*<Logo src="https://i.ibb.co/Px5FZp7/amigos-logo-cor.png" />*/}
        <LogoArea>
          <img alt="alt" src={projectLogo} />
          <h1>{labelsLanguage[language]["projectName"]}</h1>
        </LogoArea>
      </LogoContainer>
      <FormContainer>
        {
          /* NACIONALIDADE
          <ContainerSelect>
            <LabelInput>{labelsLanguage[language]["nationality"]}</LabelInput>
            <SelectBox
              onChange={(event) => setNationality(event.target.value)}
              value={nationality}
            >
              <option value="pt">
                {labelsLanguage[language]["brazilian"]}
              </option>
              <option value="en">{labelsLanguage[language]["other"]}</option>
            </SelectBox>
          </ContainerSelect>*/
        }
        <FormField
          label={labelsLanguage[language]["name"]}
          width="100%"
          name="name"
          registro={register}
          errorMsg={errors.name?.message}
          margin_bottom="10px"
          margin_top="10px"
          defaultValue={defaultValues.name}
          setValueFormState={setValue}
        />
        <FormField
          label="Email:"
          width="100%"
          name="email"
          registro={register}
          errorMsg={errors.email?.message}
          margin_bottom="10px"
          defaultValue={defaultValues.email}
          setValueFormState={setValue}
        />
        <FormField
          label={labelsLanguage[language]["confirmEmail"]}
          width="100%"
          name="confirmEmail"
          registro={register}
          errorMsg={errors.confirmEmail?.message}
          margin_bottom="10px"
          defaultValue={defaultValues.confirmEmail}
          setValueFormState={setValue}
        />
        {nationality === "pt" && (
          <FormField
            label={labelsLanguage[language]["document"]}
            width="100%"
            name="document"
            registro={register}
            maskParser={
              nationality === "pt"
                ? ["999.999.999-99", "99.999.999/9999-99"]
                : []
            }
            errorMsg={errors.document?.message}
            margin_bottom="10px"
            defaultValue={defaultValues.document}
            setValueFormState={setValue}
          />
        )}
        <FormField
          label={labelsLanguage[language]["phone_number"]}
          width="48%"
          name="phone_number"
          registro={register}
          maskParser={
            nationality === "pt" ? ["(99) 9999-9999", "(99) 99999-9999"] : []
          }
          errorMsg={errors.phone_number?.message}
          margin_bottom="10px"
          defaultValue={defaultValues.phone_number}
          setValueFormState={setValue}
        />
        <FormFieldDatePicker
          label={labelsLanguage[language]["birthday"]}
          width="48%"
          name="birthday"
          registro={register}
          errorMsg={errors.birthday?.message}
          margin_bottom="10px"
          setValueFormState={setValue}
          defaultValue={defaultValues.birthday}
          language={language}
        />
      </FormContainer>
      {countries && (
        <FormContainer>
          <ContainerSelect>
            <LabelInput>{labelsLanguage[language]["country"]}</LabelInput>
            <SelectBox
              onChange={(event) => setSelectedCountry(event.target.value)}
              value={selectedCountry}
            >
              {countries.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.country}
                </option>
              ))}
            </SelectBox>
          </ContainerSelect>
          <FormField
            label={labelsLanguage[language]["cep"]}
            width="48%"
            name="zipcode"
            registro={register}
            maskParser={selectedCountry === "BR" ? ["99.999-999"] : []}
            errorMsg={errors.zipcode?.message}
            margin_bottom="10px"
            defaultValue={defaultValues.zipcode}
            setValueFormState={setValue}
            handleCep={handleCep}
          />
          <FormField
            label={labelsLanguage[language]["address"]}
            width="100%"
            name="address"
            registro={register}
            errorMsg={errors.address?.message}
            margin_bottom="10px"
            defaultValue={defaultValues.address}
            setValueFormState={setValue}
          />

          <FormField
            label={labelsLanguage[language]["number"]}
            width="48%"
            name="street_number"
            registro={register}
            maskParser={["99999999"]}
            errorMsg={errors.street_number?.message}
            margin_bottom="10px"
            defaultValue={defaultValues.street_number}
            setValueFormState={setValue}
          />
          <FormField
            label={labelsLanguage[language]["complement"]}
            width="48%"
            name="complementary"
            registro={register}
            errorMsg={errors.complementary?.message}
            margin_bottom="10px"
            defaultValue={defaultValues.complementary}
            setValueFormState={setValue}
          />
          <FormField
            label={labelsLanguage[language]["neighborhood"]}
            width="48%"
            name="neighborhood"
            registro={register}
            errorMsg={errors.neighborhood?.message}
            margin_bottom="10px"
            defaultValue={defaultValues.neighborhood}
            setValueFormState={setValue}
          />
          {/**<FormField
            label={ labelsLanguage[language]["city"] }
            width="48%"
            name="city"
            registro={register}
            errorMsg={errors.city?.message}
            margin_bottom="10px"
            defaultValue={defaultValues.city}
            setValueFormState={setValue}
          />**/}
          <FormField
            label={labelsLanguage[language]["state"]}
            width="48%"
            name="state"
            registro={register}
            maskParser={language === "pt" ? ["AA"] : []}
            errorMsg={errors.state?.message}
            margin_bottom="10px"
            defaultValue={defaultValues.state}
            setValueFormState={setValue}
          />
          <Button type="submit">{labelsLanguage[language]["next"]}</Button>
          <ExternalLink
            style={{
              right: "0",
              marginTop: "10px",
              display: "block",
              color: "#fff",
              textDecoration: "none",
            }}
            href="https://suporteevoe.zendesk.com/hc/pt-br/sections/4404375232787-Membership-evoe-cc"
          >
            <span
              style={{
                color: "#fff",
                textDecoration: "none",
                width: "100%",
                padding: "5px",
                backgroundColor: "#9a9a9a",
              }}
            >
              {labelsLanguage[language]["faq"]}
            </span>
          </ExternalLink>
        </FormContainer>
      )}
    </Container>
  );
};

export default FormPaymentUser;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Arrow from "../Icons/caret.svg";
import { shade } from "polished";
import { fixNumber } from "../utils/utils";
import { ExternalLink } from "react-external-link";

const Container = styled.div`
  width: 100%;
  border: 0.5px solid black;
  margin-top: 30px;
  display: none;

  & > div:last-child {
    border-bottom: none;
  }

  @media (max-width: 1024px) {
    display: block;
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  width: 90%;
  margin: auto;
  padding: 50px 0;
  border-top: 0.5px solid black;

  &&:first-child {
    border: none;
  }
`;

const ContentBlack = styled.div`
  width: 100%;
  margin: auto;
  padding: 50px 0;
  background: black;
`;

const Title = styled.h2`
  margin: 0;
  font-family: "Poppins", sans-serif;
`;

const TitleWhite = styled.h2`
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-left: 18px;
`;

const Label = styled.p`
  margin: 0;
  margin-bottom: 10px;
  font-family: "Inconsolata", monospace;
`;

const LabelWhite = styled.p`
  margin: 0;
  margin-bottom: 10px;
  font-family: "Inconsolata", monospace;
  color: white;
  margin-left: 18px;
`;

const Button = styled.button`
  border: none;
  margin-top: 20px;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;

  padding: 15px 10px;

  width: 100%;
`;

const TableMembershipMobile = ({
  columns,
  rows,
  url_button,
  buttons = [],
  label_button = "Detalhes",
  showTotalRow,
}) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(0);
    if (showTotalRow !== undefined && showTotalRow.is) {
      rows.map((row, index) => {
        setTotal(total + index);
      });
    }
  }, []);

  return (
    <>
      <Container>
        {rows.map((data) => (
          <Content key={data.id}>
            {columns.map(
              (column, key) =>
                column.name && (
                  <div key={column}>
                    <Title>{column.name}</Title>
                    <Label>{data[column.key_value]}</Label>
                  </div>
                )
            )}
            {buttons.length > 0 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {buttons.map((button) => (
                  <Button
                    onClick={() => button.action(data)}
                    key={button.label}
                  >
                    {button.label}
                  </Button>
                ))}
              </div>
            ) : (
              <NavLink
                key={data}
                to={`/${url_button}/${data.id}`}
                exact={true}
                style={{ textDecoration: "none" }}
              >
                <Button>Ver {label_button}</Button>
              </NavLink>
            )}
          </Content>
        ))}
        {showTotalRow !== undefined && showTotalRow.is && total > 0 && (
          <>
            <ContentBlack>
              <TitleWhite>Total</TitleWhite>
              <LabelWhite>R$ {fixNumber(total.toFixed(2))}</LabelWhite>
            </ContentBlack>
          </>
        )}
      </Container>
    </>
  );
};

export default TableMembershipMobile;

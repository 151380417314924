import React, { useState } from "react";
import styled from "styled-components";
import FormField from "./FormField";
import * as yup from "yup";
import { isDocumentValid } from "../utils/validDocument";
import { labelsLanguage } from "../utils/language";
import Arrow from "../Icons/caret.svg";
import { unMask } from "remask";

const NotContent = styled.div`
width: 22%;
margin-top:10px;
`;

const ContainerSelect = styled.div`
  width: 22%;
  display: flex;
  flex-direction: column;
  margin-top:10px;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
  }
`;

const SelectBox = styled.select`
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 15px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;

  font-family: "Inconsolata", monospace;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }
  background-color: #FFFFFF;
`;

const LabelInput = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "Inconsolata", monospace;

  @media (max-width: 800px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const ContainerFields = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
`;

export const dependentsFieldsfields = () => {
    let fieldSchema = {};
    const options = [];
    for (let index = 1; index <= 4; index++) {
      fieldSchema = {
        ...fieldSchema,
  
        [`name${index}`]: yup.lazy(() => yup.string()
        .when([`document${index}`,`email${index}`], {
          is: (param1, param2) => param1 || param2,
          then: yup.string().required('Valor Inválido!')
        })),
  
        [`email${index}`]: yup.lazy(() => yup.string()
        .when([`document${index}`,`name${index}`], {
          is: (param1, param2) => param1 || param2,
          then: yup.string().email('Email Inválido!').required('Valor Inválido!')
        })),
  
        [`nationality${index}`]: yup.string().oneOf(['pt','en']).notRequired(),
  
        [`document${index}`]: yup.lazy(() => yup.string()
        .when([`name${index}`,`email${index}`, `nationality${index}`], {
          is: (param1, param2, param3) => (param1 || param2) && param3 === 'pt',
          then: yup.string().min(14, 'CPF Inválido!').required('CPF Inválido!').test('isCPFValid',
          'CPF Inválido!',
          (value) => isDocumentValid(value))
        }))
      };

    // É necessário passar as combinações dos fields
    options.push(
        [`name${index}`, `email${index}`], 
        [`name${index}`,`document${index}`], 
        [`email${index}`, `document${index}`]
      )
    };
    return yup.object().shape(fieldSchema, options);
};

export const formatDataDependents = (data) => {
    const NUMBER_MAX_DEPENDENTS = 4;
    const dependents = [];
  
    let i = 1;
    while(i <= NUMBER_MAX_DEPENDENTS) {
      if(data[`name${i}`] && data[`email${i}`] && data[`id${i}`]) {
        dependents.push({
          id: data[`id${i}`],
          name: data[`name${i}`],
          email: data[`email${i}`],
          document: data[`document${i}`] ? unMask(data[`document${i}`]) : ''
        });
      } else if(data[`name${i}`] && data[`email${i}`]) {
        dependents.push({
          name: data[`name${i}`],
          email: data[`email${i}`],
          document: data[`document${i}`] ? unMask(data[`document${i}`]) : ''
        });
      }

      delete data[`id${i}`];
      delete data[`name${i}`];
      delete data[`email${i}`];
      delete data[`document${i}`];
      delete data[`nationality${i}`];
      
      i++;
    };
    
    // Padrão da API
    return { dependents };
};

export const formatDependentsToForm = (data) => {
    let dependents = {};

    let index = 0;
    while(index < data.length) {
      const indexPropert = index + 1;
      
      dependents = {
          ...dependents,
          [`id${indexPropert}`]: data[index]?.id,
          [`name${indexPropert}`]: data[index]?.name || '',
          [`email${indexPropert}`]: data[index]?.email || '',
          [`document${indexPropert}`]: data[index]?.document || '',
          [`nationality${indexPropert}`]: data[index]?.document ? 'pt' : 'en'
      };
      
      index++;
    };

    return dependents;
};

const generateArrayState = (dependents) => {
  const NUMBER_MAX_DEPENDENTS = 4;
  const newArray = [];

  for(let i = 1; i <= NUMBER_MAX_DEPENDENTS; i++) {
    if(dependents[`nationality${i}`]) {
      newArray.push(dependents[`nationality${i}`])
    } else {
      newArray.push('pt');
    }
  };
  return newArray;
};

export const planAllowsDependent = (_selectPlanUser, typeUser) =>  {
  let isPermitted = false;
  if(typeUser?.length) {
    typeUser.forEach(type => {
        isPermitted =  type?.toUpperCase() === 'MEMBER';
    });
  }

  const selectPlanUser = _selectPlanUser?.toUpperCase();
  const isPlanIncludes = ['MECENAS', 'BENFEITOR MASTER', 'BENFEITOR', 'FAMÍLIA'].includes(selectPlanUser);

  return isPermitted && isPlanIncludes;
};

export const RenderFormDependents = ({
    numberDependents, 
    setValue, 
    register, 
    errors,
    defaultValues = {},
    language = 'pt'
}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityState, setNationalityState] = useState(generateArrayState(defaultValues));

    const handleSelect = (value, index) => {
      // Caso o usuário tenha digitado aldo no input de CPF
      if(value === "en") {
        setValue(`document${index}`, '');
      };

      let arrayTemp = [...nationalityState];
      arrayTemp[index-1] = value;

      setNationalityState(arrayTemp); 
      setValue(`nationality${index}`,value);
    };

    const renderFormDependents = () => {
      const element = [];
      const dependents = numberDependents === 0 ? 1 : numberDependents;
      for (let index = 1; index <= dependents; index++) {
          element.push(
              <ContainerFields key={index}>
                  <FormField
                      label={ labelsLanguage[language]['name'] }
                      width="22%"
                      name={`name${index}`}
                      registro={register}
                      errorMsg={errors[`name${index}`]?.message}
                      margin_bottom="10px"
                      margin_top="10px"
                      setValueFormState={setValue}
                      defaultValue={defaultValues[`name${index}`]}
                  />
                  <FormField
                      label="Email:"
                      width="22%"
                      name={`email${index}`}
                      registro={register}
                      errorMsg={errors[`email${index}`]?.message}
                      margin_bottom="10px"
                      margin_top="10px"
                      setValueFormState={setValue}
                      defaultValue={defaultValues[`email${index}`]}
                  />
                  <ContainerSelect>
                      <LabelInput>{ labelsLanguage[language]["nationality"] }</LabelInput>
                      <SelectBox
                          onChange={ (event) => handleSelect(event.target.value, index) }
                          value={nationalityState[index-1]}
                      >
                          <option value="pt">{labelsLanguage[language]["brazilian"]}</option>
                          <option value="en">{labelsLanguage[language]["other"]}</option>
                      </SelectBox>
                  </ContainerSelect>
                  {nationalityState[index-1] === 'pt' ? (
                      <FormField
                          label="CPF:"
                          width="22%"
                          name={`document${index}`}
                          registro={register}
                          maskParser={ ['999.999.999-99'] }
                          errorMsg={errors[`document${index}`]?.message}
                          margin_bottom="10px"
                          margin_top="10px"
                          setValueFormState={setValue}
                          defaultValue={defaultValues[`document${index}`]}
                      />
                      ) : (<NotContent />)
                  }
              </ContainerFields>
          );
      };

      return element.map((element) => element);
    };

  return (
    <>
      { renderFormDependents() }
    </>
  );
}




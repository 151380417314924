import React, { useEffect, useState } from "react";

import FormPaymentUser from "./FormPaymentUser";

const PaymentUserData = ({ handleChangeOption }) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleSubmitPayment = (data) => {
    localStorage.setItem(
      "userData",
      JSON.stringify({ ...subscriptionData, ...data })
    );

    //console.log(handleChangeOption)

    handleChangeOption("payment");
  };

  const checkToken = async () => {
    const dataToken = localStorage.getItem("userData");
    dataToken
      ? setSubscriptionData(JSON.parse(dataToken))
      : handleChangeOption("rewards"); //* : handleChangeOption("rewards");
  };

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    subscriptionData && setIsLoaded(true);
  }, [subscriptionData]);

  return (
    <>
      {isLoaded && (
        <FormPaymentUser
          handleSubmitPayment={handleSubmitPayment}
          defaultValues={subscriptionData}
        />
      )}
    </>
  );
};

export default PaymentUserData;

import React, { createContext, useState, useContext } from "react";

const NationalityContext = createContext({
    nationality: "pt",
    setNationality: () => {}
});

export default function NationalityProvider ({ children }) {
    const [nationality, setNationality] = useState("pt");

    return (
        <NationalityContext.Provider
            value={{ 
                nationality, 
                setNationality
            }}
        >
        {children}
        </NationalityContext.Provider>
    );
};

export function useNationalityState() {
    const context = useContext(NationalityContext);
    const { nationality, setNationality } = context;

    return { nationality, setNationality };
};

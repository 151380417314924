import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ExternalLink } from "react-external-link";

import apiJson from "../api/callApi";

import { labelsLanguage } from "../utils/language";
import { useLanguageState } from "../Context/Language";

import Arrow from "../Icons/caret.svg";

import { shade } from "polished";

import projectLogo from "../assets/placeholder-projeto.png";

import { fixNumber } from '../utils/utils'

const Container = styled.div`
  //width: 90%;
  min-height: 60vh;
  //margin: auto;
  display: flex;
  justify-content: space-between;
  //justify-items: center;
  flex-wrap: wrap;

  padding: 30px; // padding ao redor do container principal

  //padding: 50px 0;
  //border: 1px solid red;

  @media (max-width: 1024px) {
    //border: 1px solid black;
    margin-bottom: 50px;
  }
`;
const ContainerReward = styled.div`
  width: 48%;
  margin-top: -100px;
  border-right: 1px solid black;

  h3 {
    font-family: "Inconsolata" sans-serif;
    font-weight: normal;
  }

  @media (max-width: 1024px) {
    width: 90%;
    border-right: none;
    margin: auto;
  }
`;
const ContainerValue = styled.div`
  width: 48%;
  margin-top: -100px;

  @media (max-width: 1024px) {
    width: 90%;
    margin: auto;
  }

  p {
    a {
      margin-left: 5px;
      color: #f97778;

      &:visited,
      &:focus,
      &:hover {
        color: #f97778;
      }

      &:hover {
        color: black;
        font-weight: bold;
      }
    }
  }
`;
const TextReward = styled.p`
  margin: 0;
  width: 90%;
  font-family: "Inconsolata", monospace;
  white-space: pre-line;
  line-height: 2;
  height: 200px;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgb(232, 232, 232);
  }

  ::-webkit-scrollbar-thumb {
    background: black;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(54, 56, 58);
  }
`;

const TypeReward = styled.h2`
  margin: 0;
  margin: 15px 0;
  color: #000000;
  font-family: "Inconsolata", monospace;
  font-weight: 900;
`;

const ValueReward = styled.h2`
  margin: 0;
  margin-top: 30px;
  font-size: 3rem;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
  letter-spacing: normal !important;

  & > span {
    font-weight: normal;
    font-size: 1rem;
  }
`;

const ValueSupport = styled.input`
  width: 100%;
  margin: 0;
  margin-top: 20px;
  border: 1px solid black;
  font-size: 2rem;
  padding: 15px 15px;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
`;

const Button = styled.button`
  margin: 0;
  margin-top: 50px;
  font-size: 1.5rem;
  background-color: #148a80;
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-weight: bold;
  color: white;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#148a80")};
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const SelectBox = styled.select`
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  width: 90%;
  margin: 10px 0;
  font-family: "Inconsolata", monospace;

  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 15px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const LabelError = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  color: red;
  font-family: "Inconsolata", monospace;
  @media (max-width: 1024px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  //padding-bottom: 100px;

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const LogoArea = styled.div`
  //border: 1px solid orange;

  flex-direction: column;
  text-align: center;
  padding-top: 11px;
  height: fit-content;

  //width: 15%;
  //height: 70px;

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    //width: 100%;
    //height: 100px;

    img {
      //width: 20%;
    }

    h1 {
      font-size: 0.1rem;
    }
  }

  img {
    width: 80px;
  }

  h1 {
    color: #3aa479;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

/*const Logo = styled.img`
  width: 10%;
  height: auto;
  float: right;
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  right:0;

  @media (max-width: 920px) {
    width: 20%;
  }
`;*/

const PaymentPlan = ({ projectId, handleChangeOption }) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [rewards, setRewards] = useState(null);
  const [selectedReward, setSelectedReward] = useState([]);
  const [valueSupport, setValueSupport] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const { language } = useLanguageState();

  const [translatedProjectDescription, setTranslatedProjectDescription] = useState("");

  const validateValueReward = () => {
    return Number(valueSupport) < Number(selectedReward.valor_recompensa);
  };

  const handleCreateSubscription = async () => {
    if (validateValueReward()) {
      setErrorMsg("Valor do apoio não pode ser menor que a recompensa");
    } else {
      setErrorMsg("");
      const objectCreateSubscription = {};

      objectCreateSubscription.projeto = projectId;
      objectCreateSubscription.status = "1";

      const numero = valueSupport;
      let fixedNumber = parseFloat(numero).toFixed(2);
      objectCreateSubscription.valor_contribuicao = fixedNumber;

      objectCreateSubscription.recompensa = selectedReward.id;

      localStorage.setItem("planName", selectedReward.nome_recompensa);

      const result = await apiJson.createSubscriptionMember(
        objectCreateSubscription
      );

      if (result.data.id) {
        setSubscriptionData({
          ...{ apoio_id: result.data.id },
          ...{ amount_total: fixedNumber },
          ...objectCreateSubscription,
        });
        handleChangeOption("user-data");
        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...{ apoio_id: result.data.id },
            ...{ amount_total: fixedNumber },
            ...objectCreateSubscription,
          })
        );
      }
    }
  };

  const handleProcedeSubscription = () => {
    if (validateValueReward()) {
      setErrorMsg("Valor do apoio não pode ser menor que a recompensa");
    } else {
      setErrorMsg("");

      subscriptionData.recompensa = selectedReward.id;

      const numero = valueSupport;
      let fixedNumber = parseFloat(numero).toFixed(2);

      subscriptionData.valor_contribuicao = fixedNumber;
      subscriptionData.amount_total = valueSupport;

      localStorage.setItem("userData", JSON.stringify(subscriptionData));
      localStorage.setItem("planName", selectedReward.nome_recompensa);

      handleChangeOption("user-data");
    }
  };

  const getData = async () => {
    const result = await apiJson.getRewards(projectId);

    setRewards(result.data.results);

    if (Object.keys(subscriptionData).length != 0) {
      setSelectedReward(
        result.data.results.find(
          (data) => data.id == subscriptionData.recompensa
        )
      );
      setValueSupport(subscriptionData.amount_total);
    } else {
      setSelectedReward(result.data.results[0]);
      setValueSupport(result.data.results[0].valor_recompensa);
    }
  };

  const changeSelectedReward = (e) => {
    const objectReward = rewards.find((data) => data.id == e.target.value);

    setSelectedReward(objectReward);
    setValueSupport(objectReward.valor_recompensa);
  };

  const handleInputChange = (e) => {
    setValueSupport(e.target.value);
  };

  const checkToken = async () => {
    const dataToken = localStorage.getItem("userData");
    dataToken
      ? setSubscriptionData(JSON.parse(dataToken))
      : setSubscriptionData({});
  };

  /*const translatePlan = async(texto) => {
    const result = await apiJson.translatePlan(texto);
    return result;
  }

  useEffect(() => {
    translatePlan();
  }, [nationality])*/

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    subscriptionData && getData();
  }, [subscriptionData]);



  return (
    <Container>
      {rewards && (
        <>
          <LogoContainer>
            {/**<Logo src="https://i.ibb.co/Px5FZp7/amigos-logo-cor.png" />**/}
            {/**<ProjectLogo
              img={projectLogo}
              name={"Amigos do INHOTIM"}
              divHeight={"100px"}
              imgSize={{ width: "70px", height: "75%" }}
              fontSize={"13px"}
            />**/}
            <LogoArea>
              <img alt="alt" src={projectLogo} />
              <h1>{labelsLanguage[language]["projectName"]}</h1>
            </LogoArea>
          </LogoContainer>

          <ContainerReward>
            <h3>{labelsLanguage[language]["selectYourPlan"]}</h3>
            <SelectBox
              onChange={(e) => changeSelectedReward(e)}
              value={selectedReward.id}
            >
              {rewards.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.nome_recompensa}
                </option>
              ))}
            </SelectBox>

            <TypeReward>{selectedReward.nome_recompensa}</TypeReward>
            <TextReward>{selectedReward.descricao}</TextReward>
          </ContainerReward>

          <ContainerValue>
            <ValueReward>
              R${fixNumber(selectedReward.valor_recompensa)}
              <span>/ {labelsLanguage[language]["year"]}</span>
            </ValueReward>
            <ValueSupport
              value={valueSupport}
              onChange={(e) => handleInputChange(e)}
              type="number"
            />
            {errorMsg && <LabelError>{errorMsg}</LabelError>}
            {Object.keys(subscriptionData).length != 0 ? (
              <Button onClick={() => handleProcedeSubscription()}>
                {labelsLanguage[language]["next"]}
              </Button>
            ) : (
              <>
                <Button onClick={() => handleCreateSubscription()}>
                  {labelsLanguage[language]["continue"]}
                </Button>
              </>
            )}
            <p>
              {labelsLanguage[language]["agreement"]}
              <ExternalLink
                style={{
                  marginLeft: "5px",
                }}
                href="https://suporteevoe.zendesk.com/hc/pt-br/articles/4404382390675--Amigos-do-Inhotim-Termo-de-Uso"
              >
                <span>{labelsLanguage[language]["termsAndConditions"]}</span>
              </ExternalLink>
            </p>
            <ExternalLink
              style={{
                right: "0",
                marginTop: "10px",
                display: "block",
                color: "#fff",
              }}
              href="https://suporteevoe.zendesk.com/hc/pt-br/sections/4404375232787-Membership-evoe-cc"
            >
              <span
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  width: "100%",
                  padding: "5px",
                  backgroundColor: "#9a9a9a",
                }}
              >
                <b>{labelsLanguage[language]["faq"]}</b>
              </span>
            </ExternalLink>
          </ContainerValue>
        </>
      )}
    </Container>
  );
};

export default PaymentPlan;

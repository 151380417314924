import React, { useState } from "react";
import styled from "styled-components";

import LinksNavbar from "./LinksNavbar";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100vh;
  position: fixed;
  width: 8%;
  box-shadow: 5px 5px 10px #00000029;
  background-color: white;
  z-index: 200;

  @media (max-width: 1200px) {
    width: 10%;
  }

  @media (max-width: 920px) {
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    transition: transform 0.3s ease-in-out;
    width: 25%;
  }

  @media (max-width: 500px) {
    width: 30%;
  }

  @media (max-width: 900px) {
    justify-content: flex-start;
    padding-top: 100px;
  }

  @media (max-height: 500px) {
    position: absolute;
  }
`;

const Title = styled.div`
  color: black;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 920px) {
    display: none;
  }
`;

const TextEvoe = styled.h2`
  margin: 0;
  margin: auto;
  font-weight: 900;
  font-size: 1.5rem;
  font-family: Poppins;
`;

const TextSubtitle = styled.h2`
  margin: 0;
  margin: auto;
  margin-bottom: 10px;
  color: black;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 900;
`;

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: ${({ open }) => (open ? "fixed" : "absolute")};
  top: 15px;
  left: 20px;
  z-index: 210;
  display: none;
  cursor: pointer;

  @media (max-width: 920px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  @media (max-height: 500px) {
    position: absolute;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: black;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    left: 5%;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Navbar = ({ type_user }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleClickMenu = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <StyledBurger open={openMenu} onClick={() => setOpenMenu(!openMenu)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <Container open={openMenu}>
        <LinksNavbar type_user={type_user} handleClickMenu={handleClickMenu} />
        <Title>
          <TextSubtitle>membership.evoe.cc</TextSubtitle>
        </Title>
      </Container>
    </>
  );
};

export default Navbar;

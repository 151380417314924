import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { shade } from "polished";
import Arrow from "../Icons/caret.svg";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const ExportWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  a {
    font-size: 1.8rem;
    margin-left: 5px;
    color: white;
    text-decoration: none;
    padding: 10px;
    margin-top: 20px;
    background-color: #544bff;
    border: 2px solid black;

    // Remover decorations do link
    &:hover,
    &:visited,
    &:focus,
    &:active {
      color: white;
      text-decoration: inherit;
    }

    &:hover {
      background-color: white;
      color: #544bff;
      border-color: #544bff;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  select {
    color: #544bff;
    font-weight: bold;
  }

  @media (max-width: 920px) {
    display: flex;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    select {
      border-right: 1px solid black;
      width: 100%;
      margin: 0;
    }
  }
`;

const SelectBox = styled.select`
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  width: fit-content;
  box-sizing: border-box;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 15px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;
  padding-right: 40px;
  font-family: "Inconsolata", monospace;
  margin-left: 18px;
  border-right: 0;

  @media (max-width: 920px) {
    padding: 10px;
    padding-right: 40px;
    border-bottom: 0;

    border-left: 1px solid black;
  }

  background-color: #ffffff;
`;

const ButtonExport = styled.button`
  margin: 0;
  font-size: 1.3rem;
  background-color: #544bff;
  border: none;
  width: fit-content;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-weight: bold;
  color: white;

  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;

  padding-right: 30px;
  padding-left: 30px;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#544BFF")};
  }

  &:disabled {
    background-color: ${shade(0.2, "lightgray")};
    cursor: not-allowed;
  }

  @media (max-width: 920px) {
    width: 100%;
    height: 38px;
    border-left: 1px solid black;
    border-top: 0;
  }
`;

const ExportDataComponent = ({
  /*exportableFile,
  localDateFilter,
  searchContent,
  selectedFiltersState,*/
  loading,
  downloadPDF,
  downloadExcel,
}) => {
  //const { userObjectData } = useUserObjectData();

  //const { format, isExportable } = exportableFile;

  const [file, setFile] = useState({
    format: "",
    //content: exportableFile.dataToBeExported,
  });

  /*const processExport = async (type) => {
    if (type === "pdf") {
      downloadPDF();
    } else {
      downloadExcel();
    }
  };*/

  return (
    <>
      {/**exportableFile !== undefined && (**/
        <>
          <ExportWrap>
            <div>
              <div>
                <SelectBox
                  onChange={(event) => {
                    setFile({
                      format: event.target.value,
                      //content: exportableFile.dataToBeExported,
                    });

                    //console.log(exportableFile);
                  }}
                  value={file.format}
                >
                  <option value="" disabled>
                    Selecione Formato
                  </option>
                  <option value="xlsx">Excel (.xlsx)</option>
                  <option value="pdf">PDF. (.pdf)</option>
                </SelectBox>

                {file.format === "xlsx" ? (
                  <>
                    <ButtonExport
                      disabled={loading}
                      onClick={downloadExcel}
                    >
                      {loading ? (
                        <>
                          <Loader
                            type="ThreeDots"
                            color="gray"
                            height={20}
                            width={20}
                          />
                        </>
                      ) : (
                        <>Exportar</>
                      )}
                    </ButtonExport>
                  </>
                ) : file.format === "pdf" ? (
                  <>
                    <ButtonExport onClick={downloadPDF}>
                      Exportar
                    </ButtonExport>
                  </>
                ) : (<>
                    <ButtonExport disabled={true}>
                      Exportar
                    </ButtonExport>
                </>)}
              </div>
            </div>
          </ExportWrap>
        </>
      /*)*/}
    </>
  );
};

export default ExportDataComponent;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

import animation_data from "../json_objects/animations/loading_json.json";
import { useUserObjectData } from "../Context/UserObjectData";
import apiJson from "../api/callApi";

import TableMembership from "../Components/TableMembership";
import TableMembershipMobile from "../Components/TableMembershipMobile";
import InfoCardsTransactions from "../Components/InfoCardsTransactions";
//import Contact from "../Components/Contact";
import Search from "../Components/Search";
import Greetings from "../Components/Greetings";
//import ExportSelect from "../Components/ExportSelect";
import PaginationTables from "../Components/PaginationTables";
//import { FaPlus } from "react-icons/fa";

const ContainerActions = styled.div`
  display: flex;

  @media (max-width: 920px) {
    flex-wrap: wrap;
  }
`;

const Container = styled.div`
  padding-bottom: 100px;

  @media (max-width: 920px) {
    width: 95%;
    margin: auto;
  }
`;

/*const ButtonAddUser = styled.button`
  border: none;
  background-color: #544bff;
  color: white;
  padding: 10px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  min-width: 25%;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    min-width: 40%;
  }

  @media (max-width: 920px) {
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
  }
`;*/

const InfoText = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`;

/*const IconFaPlus = styled(FaPlus)`
  margin-left: 15px;
`;*/

const data_transactions = [
  {
    key_id: "count",
    title: "Transações",
    value: "200",
    color: "#544afb",
    is_money: true,
  },
  {
    key_id: "seiladeus",
    title: "Tipo",
    color: "#544afb",
    paymentType: [
      {
        key_id: "rtyrtyr",
        label: "Direto:",
        value: "88",
      },
      {
        key_id: "rtyrytr",
        label: "Lei:",
        value: "88",
      },
    ],
  },
  {
    key_id: "ytrytr",
    title: "Ticket de media",
    value: "1000",
    color: "#544afb",
    is_money: false
  },
];

const columns_names_Users = [
  {
    id: 1,
    name: "Nome",
    width: "16%",
    key_value: "name",
  },
  {
    id: 2,
    name: "Quantidade",
    width: "14%",
    key_value: "document",
  },
  {
    id: 3,
    name: "Recompensa",
    width: "16%",
    key_value: "email",
  },
  {
    id: 4,
    name: "Data do pagamento",
    width: "14%",
    key_value: "reward_name",
  },
  {
    id: 5,
    name: "Tipo do pagmento",
    width: "10%",
    key_value: "date_last_access",
  },
  {
    id: 7,
    name: "Pagamento com I.R?",
    width: "10%",
    key_value: "status",
  },
  {
    id: 8,
    name: "Status",
    width: "10%",
    key_value: "id",
  },
  {
    id: 9,
    name: "Próximo pagamento",
    width: "10%",
    key_value: "id",
  },
];

const AllTransactions = () => {
  const defaultOptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animation_data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [detailsCountUsers, setDetailsCountUsers] = useState(data_transactions); // Cards usuário
  const { userObjectData } = useUserObjectData();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesPagination, setTotalPagesPagination] = useState(1);
  const [localDateFilter, setLocalDateFilter] = useState({});
  const [searchContent, setSearchContent] = useState("");

  /**
   * Pega os dados pela requisição
   */
  const getData = async () => {
    const objectData_detailsCountUsers = [...detailsCountUsers]; // Cards usuário

    setDataIsLoaded(false);
    const result = await apiJson.getAllUsersProject(
      userObjectData["project_id"],
      localDateFilter,
      searchContent,
      currentPage
    );

    objectData_detailsCountUsers.map(
      (data) => (data["value"] = result.data[data["key_id"]])
    );

    // Setar os paymenttypes
    if (objectData_detailsCountUsers["paymentType"] !== undefined) {
      objectData_detailsCountUsers["paymentType"].map(
        (data) => (data["value"] = result.data[data["key_id"]])
      );
    }

    const total_pages = parseInt(result.data.count / 20 + 1);
    if (totalPagesPagination !== total_pages)
      setTotalPagesPagination(total_pages);

    setDetailsCountUsers(objectData_detailsCountUsers);
    setRowsData([]);
    setRowsData(result.data.results);
    setDataIsLoaded(true);
  };

  /**
   * Cuida da parte de filtro
   * @param {Date} value Data selecionada
   * @param {boolean} isDate É uma data?
   */
  const handleDateFilter = (value = null, isDate = false) => {
    value !== null
      ? setLocalDateFilter({ value: value, isDate: isDate })
      : setLocalDateFilter({});
  };

  /**
   * Cuida da parte de pesquisa
   * @param {any} data
   */
  const handleSubmitSearch = (data) => {
    setSearchContent(data["search-input"]);
  };

  /**
   * Disparado quando clicamos para trocar cada página
   * @param {any} data
   */
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  /**
   * Atualiza os dados da página conforme os estados abaixo mudam
   */
  useEffect(() => {
    getData();
  }, [currentPage, searchContent, localDateFilter]);

  return (
    <Container>
      <Greetings />
      <Search
        handleDateFilter={handleDateFilter}
        handleSubmitSearch={handleSubmitSearch}
      />
      {dataIsLoaded ? (
        <>
          <InfoCardsTransactions info_cards={data_transactions} />
          <ContainerActions>
            {/* <ButtonAddUser>
              Adicionar novo usuario <IconFaPlus size={20} />
            </ButtonAddUser> */}
            {/* <ExportSelect width="40%" /> */}
          </ContainerActions>
          {rowsData.length > 0 ? (
            <>
              <TableMembershipMobile
                columns={columns_names_Users}
                rows={rowsData}
                url_button="usuarios"
                label_button="Usuário"
                exportable={{ is: true, table: "usuarios", dataToBeExported: rowsData }}
                showTotalRow={{is: true, rowKeyDataToSum: "name"}}
              />
              <TableMembership
                columns={columns_names_Users}
                rows={rowsData}
                url_button="usuarios"
                label_button="Usuário"
                exportable={{ is: true, table: "usuarios", dataToBeExported: rowsData }}
                showTotalRow={{is: true, rowKeyDataToSum: "name"}}
              />
            </>
          ) : (
            <InfoText>Nenhum resultado encontrado</InfoText>
          )}
        </>
      ) : (
        <Lottie options={defaultOptionsLottie} height={400} width={400} />
      )}
      {totalPagesPagination > 1 && (
        <PaginationTables
          numberPages={totalPagesPagination}
          handlePageClick={handlePageClick}
        />
      )}
      {/* <Contact /> */}
    </Container>
  );
};

export default AllTransactions;

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import HeaderLink from "../Components/HeaderLink";
import { formatDependentsToForm } from "../Components/DependentsForm";
import apiJson from "../api/callApi";
import auth from "../api/authService";
import FormEditProfile from "../Components/FormEditProfile";
import LoadingComponent from "../Components/LoadingComponent";

const Container = styled.form`
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const EditProfileStaff = () => {
  const [profileDefaultData, setProfileDefaultData] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const getData = async () => {
    try {
      setShowLoading(true);
      let userData = {};

      const { user_id } = await auth.getCurrentUser();

      const response = await apiJson.getUserProfileData(user_id);

      const dependents = await apiJson.getMemberDependents(response.data.id);

      // Recebe os dados do Plano 
      const reward = await apiJson.getMemberReward(response.data.id);
      const dependentsFormattedForm = formatDependentsToForm(dependents.data);

      userData = {
        ...response.data,
        ...dependentsFormattedForm,
        numberOfdependents: dependents.data.length,
        rewardName: reward?.data?.nome_recompensa
      };

      setProfileDefaultData(userData);
    } catch(err) {}

    setShowLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <HeaderLink
        url="/usuarios"
        label_link="Usuários"
        label_info="Editar dados usuário"
      />
      {showLoading && (<LoadingComponent heigth={300} width={300}/>)}
      {profileDefaultData && (
        <>
          <FormEditProfile defaultValues={profileDefaultData} />
        </>
      )}
    </Container>
  );
};

export default EditProfileStaff;
import React from "react";
import styled from "styled-components";

import FormField from "../Components/FormField";
import FormFieldEditor from "../Components/FormFieldEditor";
import HeaderLink from "./HeaderLink";

import Arrow from "../Icons/caret.svg";

const LabelInput = styled.label`
  font-weight: bold;
  font-size: 0.9rem;
  margin-left: 5px;

  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SelectBox = styled.select`
  border-radius: 5px;
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  width: 30%;
  margin: 10px 0;

  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 15px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const Button = styled.button`
  font-size: 1rem;
  width: 15%;
  padding: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  margin-top: 10px;
  margin-bottom: 30px;
  display: block;

  @media (max-width: 800px) {
    width: 100%;
    padding: 10px 0;
  }
`;

const CardUpdate = ({ label_update, title, label_button }) => {
  return (
    <Container>
      <HeaderLink
        url="/customizacao"
        label_link="Atualizações"
        label_info={label_update}
        valueSelected="updates"
      />
      <h2>{title}</h2>
      <FormContainer>
        <ContainerSelect>
          <LabelInput>Enviar para:</LabelInput>
          <SelectBox value="A">
            <option value="A">Recompensa Independente</option>
            <option value="A">Recompensa Idoso</option>
          </SelectBox>
        </ContainerSelect>
        <FormField
          label="Assunto atualização:"
          value=""
          width="90%"
          margin_bottom="30px"
          handleChange={null}
          has_icon={false}
        />
        <FormFieldEditor
          label="Editar atualização:"
          value=""
          width="90%"
          margin_bottom="30px"
        />
      </FormContainer>
      <Button>Incluir Arquivo</Button>

      <Button>{label_button}</Button>
    </Container>
  );
};

export default CardUpdate;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatDependentsToForm } from "../Components/DependentsForm";
import apiJson from "../api/callApi";
import LoadingComponent from "../Components/LoadingComponent";
import FormEditProfile from "../Components/FormEditProfile";

const Container = styled.div`
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const EditProfileMemberByStaff = ({ location }) => {
  const [profileDefaultData, setProfileDefaultData] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const getData = async (user_id) => {
    try {
      setShowLoading(true);
      let userData = {};

      const response = await apiJson.getUserProfileData(user_id);
      const dependents = await apiJson.getMemberDependents(response.data.id);
      // Recebe os dados do Plano 
      const reward = await apiJson.getMemberReward(response.data.id);

      const dependentsFormattedForm = formatDependentsToForm(dependents.data);

      userData = {
        ...response.data,
        ...dependentsFormattedForm,
        numberOfdependents: dependents.data.length,
        rewardName: reward?.data?.nome_recompensa
      };

      setProfileDefaultData(userData);
    } catch (err) {}

    setShowLoading(false);
  };

  useEffect(() => {
    const pathname = location.pathname;
    getData(pathname.split("/")[2]);
  }, []);
  return (
    <Container>
      {showLoading && (<LoadingComponent heigth={300} width={300}/>)}
      {profileDefaultData && (
        <>
          <FormEditProfile defaultValues={profileDefaultData} />
        </>
      )}
    </Container>
  );
};

export default EditProfileMemberByStaff;

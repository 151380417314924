import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useUserObjectData } from "../Context/UserObjectData";

const Logout = () => {
  const { setUserObjectData } = useUserObjectData();
  let history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("token_access");
    setUserObjectData(null);
    history.push("/login");
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <></>;
};

export default Logout;

import React, { useState } from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";

const FilterWrapper = styled.div`
  padding: 16px;
  min-width: 280px;

  h1 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    margin-bottom: 5px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    padding: 0;
    
    li:hover {
      font-weight: bold;
      cursor: pointer;
    }

    .marked {
      color: #99ff70;
      width: fit-content;
      font-weight: bold;
    }
  }
`;

const Checked = styled(FaCheck)`
  margin-right: 5px;
  width: 14px;
`;


const FilterComponent = ({
  filters,
  selectedFiltersState,
  setSelectedFiltersState,
}) => {
  //const [activeFilters, setActiveFilters] = useState([]);

  /**
   * Processa o clique em cada filtro
   * @param {array} filters Array de filtros
   * @param {any} f Objeto do filtro
   */
  const selectFilter = (f) => {
    const { id, label, value, filter_key } = f;

    let newSelectedFilters = [...selectedFiltersState];

    const filter = newSelectedFilters.find((f) => f.id === id);
    const indexFilter = newSelectedFilters.indexOf(filter);

    // Cuida da marcação e remoção do filtro
    if (newSelectedFilters.some((filtro) => filtro.label === label)) {
      newSelectedFilters.splice(indexFilter, 1);
      console.log(f.label, "removido")
    } else if (newSelectedFilters.some((filtro) => filtro.label !== label && (filtro.category !== undefined && filtro.category === f.category))) {
      console.log('test')
      newSelectedFilters.splice(indexFilter, 1, f);
    } else {
      newSelectedFilters.push(f);
    }

    setSelectedFiltersState(newSelectedFilters);

    console.log(newSelectedFilters)
  };

  return (
    <>
      {filters.map((filter) => (
        <>
          <FilterWrapper>
            <h1>
              {/*&gt;*/} {filter.label}
            </h1>
            <ul>
              {filter.filters.map((f) => (
                <>
                <li className={selectedFiltersState.some((filtro) => filtro.label === f.label) ? "marked" : "unmarked"} onClick={() => selectFilter(f)} key={f.id}>
                {selectedFiltersState.some((filtro) => filtro.label === f.label) && <Checked/>}{f.label}
                </li>
                </>
              ))}
            </ul>
          </FilterWrapper>
        </>
      ))}
    </>
  );
};

export default FilterComponent;

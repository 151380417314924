import React from "react";
import styled from "styled-components";

import FormField from "../Components/FormField";

const Container = styled.div`
  width: 100%;
  padding: 2% 0;

  @media (max-width: 800px) {
    border: 1px solid black;
    margin-bottom: 50px;
  }
`;

const ContainerAddUpdate = styled.div`
  width: 100%;
  padding: 1% 5%;
  box-sizing: border-box;
`;

const TitleReward = styled.h4`
  margin: 0;
  margin-bottom: 10px;
  font-size: 2rem;
`;

const Button = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 30%;
  margin-left: 30px;
  margin-top: 20px;
  box-sizing: border-box;

  @media (max-width: 1400px) {
    width: 30%;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
  }
`;

const ButtonSave = styled(Button)`
  @media (max-width: 800px) {
    width: 90%;
    margin: 30px 0;
    margin-left: 5%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
`;

const Branding = () => {
  return (
    <Container>
      <ContainerAddUpdate>
        <TitleReward>Branding</TitleReward>
        <p>Aqui você pode adicionar os logos e fonts do seu projeto.</p>
      </ContainerAddUpdate>
      <FormContainer>
        <FormField
          label="Logo:"
          value=""
          width="50%"
          margin_bottom="10px"
          handleChange={null}
          has_icon={false}
        />
        <Button>Update logo</Button>
        <FormField
          label="Fonte:"
          value=""
          width="50%"
          margin_bottom="10px"
          handleChange={null}
          has_icon={false}
        />
        <Button>Salvar fonte</Button>
      </FormContainer>

      <ButtonSave>Salvar</ButtonSave>
    </Container>
  );
};

export default Branding;

import React from "react";
import styled from "styled-components";

const Title = styled.h2`
  margin: 0;
  font-size: 2rem;

  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
`;

const TextNumber = styled.h1`
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: ${(props) => (props.is_money ? "5rem" : "5rem")};
  color: ${(props) => props.color || "#6fe275"};

  @media (max-width: 1200px) {
    font-size: 4rem;
  }

  @media (max-width: 1100px) {
    font-size: 3rem;
  }
`;

const SimpleInfoCard = ({ label, value, is_money, color }) => {
  return (
    <>
      <Title>{label}</Title>
      {is_money ? (
        <TextNumber color={color} is_money={is_money}>
          R${parseFloat(value).toLocaleString()}
        </TextNumber>
      ) : (
        <TextNumber color={color}>{value}</TextNumber>
      )}
    </>
  );
};

export default SimpleInfoCard;

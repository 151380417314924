import React from "react";
import styled from "styled-components";

const Label = styled.p`
  width: ${(props) => props.width};
  height: auto;
  margin: 0;
  padding: 10px 0px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const Value = styled.p`
  margin: 0;
  width: ${(props) => props.width};
  font-family: "Inconsolata", monospace;
`;

const Separator = styled.div`
  border-right: 1px solid #c4c4c4;
  height: 100%;
`;

const DetailsInfo = ({
  label,
  value,
  value_width = "48%",
  label_width = "48%",
}) => {
  return (
    <Container>
      <Label width={label_width}>{label}</Label>
      <Separator />
      <Value width={value_width}>{value}</Value>
    </Container>
  );
};

export default DetailsInfo;

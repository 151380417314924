import React from "react";
import styled from "styled-components";

const Title = styled.h2`
  margin: 0;
  font-size: 2rem;

  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
`;

const PaymentType = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  font-size: 1.8rem;
  justify-content: space-between;

  @media (max-width: 1200px) {
    font-size: 1.3rem;
  }

  div.mini-div {
    display: flex;
    flex-direction: column;

    /*&:not(:first-child) {
      margin-left: 50px;
    }*/

    p {
      color: black;
      font-size: 1.3rem;
      margin: 0;
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
      margin-left: 5px;
    }
  }
`;

const TextNumber = styled.h1`
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: ${(props) => (props.is_money ? "5rem" : "5rem")};
  color: ${(props) => props.color || "#6fe275"};
  margin: 0;

  @media (max-width: 1200px) {
    font-size: 4rem;
  }

  @media (max-width: 1100px) {
    font-size: 3rem;
  }
`;

const SimpleInfoCardTransaction = ({ label, paymentType, color }) => {
  return (
    <>
      <Title>{label}</Title>
      <PaymentType>
        {paymentType.map((paymentType) => (
          <div className="mini-div">
            <p>{paymentType.label}</p>
            <TextNumber color={color}>{paymentType.value}</TextNumber>
          </div>
        ))}
      </PaymentType>
    </>
  );
};

export default SimpleInfoCardTransaction;

/**
 {is_money ? (
        <TextNumber color={color} is_money={is_money}>
          R${parseFloat(value).toLocaleString()}
        </TextNumber>
      ) : (
        <TextNumber color={color}>{value}</TextNumber>
      )}
 */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormField from "../Components/FormField";

import ArrowIcon from "../Icons/caret.svg";

const Container = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;

  @media (max-width: 800px) {
    margin-top: 50px;
  }
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 10px;
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
`;

const Select = styled.select`
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  width: 28%;

  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position: right 15px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;
  font-family: "Poppins", sans-serif;

  @media (max-width: 1200px) {
    width: 36%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const FormSelectField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
`;

const Button = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 20%;
  margin-bottom: 20px;
  display: block;
  font-family: "Poppins", sans-serif;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 50px;
  }

  @media (max-width: 1200px) {
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  display: block;

`;

const Logo = styled.img`
  width: 10%;
  height: auto;
  float: right;
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  right:0;

  @media (max-width: 920px) {
    width: 20%;
  }
`;

const PaymentForm = () => {
  const { register, handleSubmit, formState } = useForm({});
  const [paymentMethod, setPaymentMethod] = useState("boleto");
  const { errors } = formState;

  const submitForm = (data) => {
    let dataPaymentMethod = {};
    dataPaymentMethod.paymentMethod = paymentMethod;
    if (paymentMethod === "cartao") {
      dataPaymentMethod = { ...dataPaymentMethod, ...data };
    }
    console.log(dataPaymentMethod);
  };
  const changePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  return (
    <Container onSubmit={handleSubmit(submitForm)}>
      <h2 style={{ margin: 0, marginBottom: "10px" }}>
        Editar metodo de pagamento:
      </h2>
      <FormSelectField>
        <Label>Metodo de Pagamento:</Label>
        <Select onChange={(e) => changePaymentMethod(e)}>
          <option value="boleto">Boleto</option>
          <option value="cartao">Cartão</option>
        </Select>
      </FormSelectField>
      {paymentMethod === "cartao" && (
        <>
          <FormField
            label="Número:"
            width="36%"
            margin_bottom="10px"
            name="cardNumber"
            type="number"
            registro={register}
            errorMsg={errors.cardNumber?.message}
          />
          <FormField
            label="Validade:"
            width="28%"
            margin_bottom="10px"
            name="validity"
            registro={register}
            errorMsg={errors.validity?.message}
          />
          <FormField
            label="CVV:"
            width="28%"
            margin_bottom="10px"
            name="cvv"
            type="number"
            registro={register}
            errorMsg={errors.cvv?.message}
          />
        </>
      )}
      <Button type="submit">Alterar Pagamento</Button>
    </Container>
  );
};

export default PaymentForm;

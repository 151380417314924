import React from "react";
import styled from "styled-components";

import { NavLink } from "react-router-dom";

const LinkRouter = styled(NavLink)`
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 9px 0;
  text-decoration: none;

  &:hover {
    color: #544bff;
    opacity: 1;
  }

  &.active {
    color: #544bff;
    opacity: 1;
  }
`;

const LinkIcon = styled.div`
  font-size: ${(props) => props.font_size};
`;

const LinkTitle = styled.div`
  font-size: 0.8rem;
`;

const LinkNavbar = ({
  texto,
  Icon,
  url,
  is_exact,
  font_size,
  handleClickMenu,
}) => {
  return (
    <LinkRouter to={url} exact={is_exact} onClick={handleClickMenu}>
      <LinkIcon font_size={font_size}>
        <Icon />
      </LinkIcon>
      <LinkTitle>{texto}</LinkTitle>
    </LinkRouter>
  );
};

export default LinkNavbar;

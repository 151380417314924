import React from "react";

import UserObjectDataProvider from "./Context/UserObjectData";
import LanguageProvider from "./Context/Language";
import NationalityProvider from "./Context/Nationality";
import Routes from "./routes";

function App() {
  return (
    <>
      <LanguageProvider>
        <NationalityProvider>
          <UserObjectDataProvider>
            <Routes />
          </UserObjectDataProvider>
        </NationalityProvider>
      </LanguageProvider>
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

import apiJson from "../api/callApi";
import { useUserObjectData } from "../Context/UserObjectData";
import animation_data from "../json_objects/animations/loading_json.json";

import Search from "../Components/Search";
import Greetings from "../Components/Greetings";
import TableMembership from "../Components/TableMembership";
import TableMembershipMobile from "../Components/TableMembershipMobile";
import Contact from "../Components/Contact";
import ExportSelect from "../Components/ExportSelect";
import InfoCards from "../Components/InfoCards";
import PaginationTables from "../Components/PaginationTables";
import ExportDataComponent from "../Components/ExportDataComponent";

const data_simpleCards = [
  {
    key_id: "count",
    title: "Número de entradas",
    value: "",
    color: "#544afb",
  },
];

const columns_names_accessControl = [
  {
    id: 1,
    name: "Nome",
    width: "18%",
    key_value: "name",
  },
  {
    id: 2,
    name: "CPF",
    width: "16%",
    key_value: "document",
  },
  {
    id: 3,
    name: "Email",
    width: "18%",
    key_value: "email",
  },
  {
    id: 4,
    name: "Recompensa",
    width: "16%",
    key_value: "reward",
  },
  {
    id: 5,
    name: "Data de Entrada",
    width: "12%",
    key_value: "access_date",
  },
  {
    id: 6,
    name: "Status",
    width: "10%",
    key_value: "status",
  },
  {
    id: 7,
    name: "",
    width: "10%",
    key_value: "id",
  },
];

const Container = styled.div`
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const InfoText = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`;

const moreFilters = [
  {
    id: 1,
    label: "Outros",
    filters: [
      {
        id: 1,
        label: "Plano",
        key: "subscription__forma_pagamento1",
        value: "boleto",
        keyPdf: "payment_methods[]",
        valuePdf: "billet",
      },
    ],
  },
  {
    id: 1,
    label: "Status",
    filters: [
      {
        id: 6,
        label: "Ativo",
        key: "subscription__signature_status1",
        value: "AT",
        keyPdf: "status[]",
        valuePdf: "AT",
      },
      {
        id: 7,
        label: "Pendente",
        key: "subscription__signature_status2",
        value: "PE",
        keyPdf: "status[]",
        valuePdf: "PE",
      },
      {
        id: 8,
        label: "Cancelada",
        key: "subscription__signature_status3",
        value: "CA",
        keyPdf: "status[]",
        valuePdf: "CA",
      },
      {
        id: 9,
        label: "Encerrada",
        key: "subscription__signature_status4",
        value: "EN",
        keyPdf: "status[]",
        valuePdf: "EN",
      },
    ],
  },
];

const AcessControl = () => {
  const defaultOptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animation_data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [detailsAccessLogs, setDetailsAccessLogs] = useState(data_simpleCards);
  const { userObjectData } = useUserObjectData();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesPagination, setTotalPagesPagination] = useState(1);

  const [localDateFilter, setLocalDateFilter] = useState({});
  const [searchContent, setSearchContent] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedFiltersState, setSelectedFiltersState] = useState([]);

  const getData = async () => {
    const objectData_detailsAccessLogs = [...detailsAccessLogs];

    setDataIsLoaded(false);
    const result = await apiJson.getAllAccessControl(
      userObjectData["project_id"],
      currentPage
    );

    //console.log(result.data.results)

    objectData_detailsAccessLogs.map(
      (data) => (data["value"] = result.data[data["key_id"]])
    );

    const total_pages = parseInt(result.data.count / 20 + 1);
    if (totalPagesPagination !== total_pages)
      setTotalPagesPagination(total_pages);

    setDetailsAccessLogs(objectData_detailsAccessLogs);
    setRowsData([]);
    setRowsData(result.data.results);

    setDataIsLoaded(true);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

   /**
   * Cuida da parte de filtro
   * @param {Date} value Data selecionada
   * @param {boolean} isDate É uma data?
   */
    const handleDateFilter = (value = null, isDate = false) => {
      value !== null
        ? setLocalDateFilter({ value: value, isDate: isDate })
        : setLocalDateFilter({});
    };
  
    /**
     * Cuida da parte de pesquisa
     * @param {any} data
     */
    const handleSubmitSearch = (data) => {
      setSearchContent(data["search-input"]);
    };

   /**
   * Busca o arquivo PDF pra baixar e dispara o download
   */
    const downloadPDF = async () => {
      // TODO: Download PDF
  
      // Formata a url para ser enviada a API para o download do PDF
      const url = await apiJson.getUrlFormattedToDownloadPdf(
        userObjectData["project_id"],
        localDateFilter,
        searchContent,
        selectedFiltersState
      );
  
      console.log(url);
  
      window.open(url, "_blank");
    };
  
    /**
     * Busca o arquivo excel pra baixar e dispara o download
     */
    const downloadExcel = async () => {
      setLoading(true);
      apiJson
        .getAllUsersProjectExport(
          userObjectData["project_id"],
          localDateFilter,
          searchContent,
          selectedFiltersState
        )
        .then((response) => {
          if (response.status === 200) {
            const data = response.data;
            window.open(data, "_blank");
            setLoading(false);
          } else {
            console.log("Não encontrado");
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    };

  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <Container>
      <Greetings />
      <Search
        handleDateFilter={handleDateFilter}
        handleSubmitSearch={handleSubmitSearch}
        //moreFiltersData={moreFilters}
        selectedFiltersState={selectedFiltersState}
        setSelectedFiltersState={setSelectedFiltersState}
      />
      {dataIsLoaded ? (
        <>
          {rowsData.length > 0 ? (
            <>
              <InfoCards info_cards={detailsAccessLogs} />
              {/* <ExportSelect /> */}
              {/*<ExportDataComponent
                loading={loading}
                downloadPDF={downloadPDF}
                downloadExcel={downloadExcel}
              />*/}
              <TableMembershipMobile
                columns={columns_names_accessControl}
                rows={rowsData}
                url_button="usuarios"
                label_button="Usuário"
              />
              <TableMembership
                columns={columns_names_accessControl}
                rows={rowsData}
                url_button="usuarios"
                label_button="Usuário"
              />
            </>
          ) : (
            <InfoText>Nenhum resultado encontrado</InfoText>
          )}
          {totalPagesPagination > 1 && (
            <PaginationTables
              numberPages={totalPagesPagination}
              handlePageClick={handlePageClick}
            />
          )}
          {/* <Contact /> */}
        </>
      ) : (
        <Lottie options={defaultOptionsLottie} height={400} width={400} />
      )}
    </Container>
  );
};

export default AcessControl;

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import apiJson from "../api/callApi";

import { FaTools } from 'react-icons/fa';

import TableMembership from "../Components/TableMembership";
import TableMembershipMobile from "../Components/TableMembershipMobile";
import ContainerDetailsCard from "../Components/ContainerDetailsCard";
import DetailsInfo from "../Components/DetailsInfo";
import HeaderLink from "../Components/HeaderLink";
import ExportSelect from "../Components/ExportSelect";

const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TitleCard = styled.h2`
  margin: 0;
  font-size: 2rem;
  margin-bottom: 10px;
`;

const Notification = styled.p`
  margin: 0;
`;

const Container = styled.div`
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const columns_names_Sales = [
  {
    id: 1,
    name: "Nome",
    width: "20%",
    key_value: "name",
  },
  {
    id: 2,
    name: "CPF",
    width: "16%",
    key_value: "document",
  },
  {
    id: 3,
    name: "Email",
    width: "16%",
    key_value: "email",
  },
  {
    id: 4,
    name: "Recompensa",
    width: "16%",
    key_value: "reward",
  },
  {
    id: 5,
    name: "Ultima Entrada",
    width: "12%",
    key_value: "date_last_access",
  },
  {
    id: 5,
    name: "Status",
    width: "10%",
    key_value: "status",
  },
  {
    id: 6,
    name: "",
    width: "10%",
    key_value: "id",
  },
];
const details__staff = [
  {
    key_id: "email",
    label: "Email:",
    value: "",
  },
  {
    key_id: "document",
    label: "CPF/CNPJ:",
    value: "",
  },
  {
    key_id: "phone",
    label: "Telefone:",
    value: "",
  },
  {
    key_id: "job",
    label: "Emprego",
    value: "",
  },
];

const OverlayEmBreve = styled.div`
  height: 100vh;
  width: 90%;
  background-color: #fff;
  position: absolute;
  z-index: 100;

  @media (max-width: 800px) {
    width: 95%;
  }
`;

const StaffInfo = ({ location }) => {
  const [rowsData, setRowsData] = useState([]);
  const [dataStaff, setDataStaff] = useState(null);
  const [detailsStaff, setDetailsStaff] = useState(details__staff);

  const getDataStaff = async (staff_id) => {
    const objectData_detailsStaff = [...detailsStaff];

    const result = await apiJson.getStaffInfo(staff_id);

    objectData_detailsStaff.map(
      (data) => (data["value"] = result.data[data["key_id"]])
    );

    setDetailsStaff(objectData_detailsStaff);
    setDataStaff(result.data);
  };

  const getDataSales = async (staff_id) => {
    const result = await apiJson.getSalesStaff(staff_id);
    setRowsData(result.data.results);
  };

  useEffect(() => {
    const pathname = location.pathname;
    getDataStaff(pathname.split("/")[2]);
    getDataSales(pathname.split("/")[2]);
  }, []);

  return (
    <Container>
        <OverlayEmBreve>
          <FaTools style={{ fontSize: "30px", marginTop: "20px" }}/>
          <h1 style={{ color: "#000" }}>Essa página estará disponível em breve!</h1>
          <h3>Enquanto isso qualquer dado, informação ou ação necessária pode ser solicitado para contato@evoe.cc</h3>
        </OverlayEmBreve>
      <HeaderLink
        url="/colaboradores"
        label_link="Colaboradores"
        label_info="Daniel Souza"
      />
      <h2>Detalhes do usuário:</h2>
      {dataStaff && (
        <>
          <ContainerCards>
            <ContainerDetailsCard>
              <TitleCard>{dataStaff["name"]}</TitleCard>
              {detailsStaff.map((data) => (
                <DetailsInfo
                  key={data.key_id}
                  label={data.label}
                  value={data.value}
                />
              ))}
            </ContainerDetailsCard>
          </ContainerCards>
        </>
      )}
    </Container>
  );
};

export default StaffInfo;

{
  /* <ContainerDetailsCard>
<TitleCard>Numero de vendas</TitleCard>
<TitleCard style={{ color: "#544bff" }}>
  {dataStaff["number_sales"]}
</TitleCard>
<TitleCard>Comissão</TitleCard>
<TitleCard style={{ color: "#544bff" }}>R$ 1000,00</TitleCard>
<Notification>Calculo das vendas do último mês</Notification>
</ContainerDetailsCard> */
}

// {rowsData && (
//   <ContainerDetailsCard width="100%" height="auto">
//     <h2>Assinantes do vendedor:</h2>
//     <ExportSelect />
//     <TableMembershipMobile
//       columns={columns_names_Sales}
//       rows={rowsData}
//       url_button="usuarios"
//       label_button="Usuário"
//     />
//     <TableMembership
//       columns={columns_names_Sales}
//       rows={rowsData}
//       url_button="usuarios"
//       label_button="Usuário"
//     />
//   </ContainerDetailsCard>
// )}

import React from "react";
import styled from "styled-components";

import Card from "../Components/Card";
import SimpleInfoCard from "../Components/SimpleInfoCard";

const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    margin-bottom: 30px;
  }
`;

const InfoCards = ({ info_cards = [] }) => {
  return (
    <Container>
      {info_cards.map((data) => (
        <Card show_seeMore={true} key={data.key_id}>
          <SimpleInfoCard
            label={data.title}
            value={data.value}
            is_money={data.is_money ? data.is_money : false}
            color={data.color && data.color}
          />
        </Card>
      ))}
    </Container>
  );
};

export default InfoCards;

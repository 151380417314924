import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import { ExternalLink } from "react-external-link";

import Arrow from "../Icons/caret.svg";

// payment forms
import boleto from "../assets/payments/barcode.png";
import credit from "../assets/payments/credit-card.png";
import pix from "../assets/payments/qr-code.png";

import { isDocumentValid } from "../utils/validDocument";

import ReactTooltip from "react-tooltip";

import apiJson from "../api/callApi";
import FormField from "../Components/FormField";
import LaodingComponent from "../Components/LoadingComponent";
import ReCAPTCHA from "react-google-recaptcha";
import { FaRegCreditCard, FaBarcode } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { labelsLanguage } from "../utils/language";

import { useLanguageState } from "../Context/Language";
import { useNationalityState } from "../Context/Nationality";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { shade } from "polished";

import projectLogo from "../assets/placeholder-projeto.png";

import { isIRBlocked } from "../utils/utils";

const Container = styled.form`
  width: 90%;
  min-height: 60vh;
  margin: auto;

  padding: 30px;
  width: 100%;

  @media (max-width: 800px) {
    //border: 1px solid black;
    margin-bottom: 50px;
    width: auto;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 920px) {
    width: 100%;
  }
`;

/*const Button = styled.button`
  margin: 0;
  margin-top: 10px;
  font-size: 1.2rem;
  width: 25%;
  height: 50px;
  cursor: pointer;
  background-color: #1b724f;
  color: #fff;
  font-family: "Poppins", sans-serif;
  border: none;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
  }
`;*/

const Button = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin: 0;
    margin-top: 50px;
    font-size: 1.5rem;
    background-color: #148a80;
    border: none;
    padding: 10px;
    width: 40%;
    cursor: pointer;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
    color: white;

    // Transição
    transition: background-color 0.3s;

    // Hover no botão
    &:hover {
      background-color: ${shade(0.2, "#148a80")};
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;

const ContainerMethodPayment = styled.div`
  display: flex;
`;

const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
  }

  a {
    margin-left: 5px;
    color: #f97778;

    // Remover decorations do link
    &:hover,
    &:visited,
    &:focus,
    &:active {
      color: #f97778;
      text-decoration: inherit;
    }

    &:hover {
      color: black;
      font-weight: bold;
    }
  }
`;

const LinksMethodPayments = styled.div`
  text-align: center;
  margin-right: 70px;
  margin-bottom: 20px;
  padding: 10px;

  opacity: 50%;

  cursor: pointer;

  // Cor no elemento ao dar hover
  &:hover {
    color: #148a80;
  }

  // Cor no elemento ativo
  &.active {
    color: #000000;
    opacity: 100%;

    font-weight: bold;

    // Cor quando dá hover no elemento ativo
    &:hover {
      color: #148a80;
    }
  }

  & > p {
    margin: 0;
  }
`;

const ContainerCaptcha = styled.div`
  padding: 10px;
`;

const SelectBox = styled.select`
  border: 1px solid ${(props) => (props.color ? props.color : "black")};
  font-size: 1.2rem;
  padding: 10px 10px;
  width: 96%;
  margin: 10px 0;
  font-family: "Inconsolata", monospace;

  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 15px bottom 50%;
  background-size: 15px 20px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0px;
  background-color: #ffffff;

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
  }
`;

const ImpostoInfoMobile = styled.div`
  display: none;

  @media (max-width: 920px) {
    display: block;
    font-family: "Inconsolata", monospace;
    color: #544bff;
  }
`;

const ErrorSpan = styled.div`
  color: red;
  display: flex;
  font-family: "Inconsolata", monospace;
  flex-direction: column;

  @media (max-width: 920px) {
    span {
      margin-top: 5px;
    }
  }
`;

const LabelInput = styled.label`
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "Inconsolata", monospace;

  @media (max-width: 800px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const LabelTotal = styled.label`
  margin-top: -10px;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Inconsolata", monospace;

  @media (max-width: 800px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const LabelErrorCaptcha = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  color: red;
  font-family: "Inconsolata", monospace;
  @media (max-width: 920px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

/*const LogoContainer = styled.div`
  width: 100%;
  display: block;

`;

const Logo = styled.img`
  width: 10%;
  height: auto;
  float: right;
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  right:0;

  @media (max-width: 920px) {
    width: 20%;
  }
`;*/
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  //padding-bottom: 100px;

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const LogoArea = styled.div`
  //border: 1px solid orange;

  flex-direction: column;
  text-align: center;
  padding-top: 11px;
  height: fit-content;

  //width: 15%;
  //height: 70px;

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    //width: 100%;
    //height: 100px;

    img {
      //width: 20%;
    }

    h1 {
      font-size: 0.1rem;
    }
  }

  img {
    width: 80px;
  }

  h1 {
    color: #3aa479;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

/**
 * Calcula os valores das parcelas
 * @param {number} _valorTotal Valor total das parcelas
 * @param {string} labelsLanguage Array de strings utilizadas na
 * @param {string} language Linguagem escolhida pelo usuário
 * @returns Todas as parcelas
 */
const calculatesValuesInstallments = (
  _valorTotal,
  labelsLanguage,
  language
) => {
  const valorTotal = parseFloat(_valorTotal);
  const JUROS = 0.025; // Juros total de 2.5% por mês

  const instalments = [];

  const string0 = labelsLanguage[language]["parcela"];
  const string1 = labelsLanguage[language]["aVista"];

  instalments.push({
    value: 1,
    message: `1 ${string0} ${valorTotal.toFixed(2)} (${string1})`,
    valueTotal: valorTotal.toFixed(2),
  });

  // Atualmente é parcelado em 6 vezes
  for (let index = 2; index <= 6; index++) {
    let jurosTotal = (index - 1) * JUROS; // Quantidade de mês vezes o juros
    let valorTotalComJuros = valorTotal + valorTotal * jurosTotal; // Valor da parcela com Juros

    const valorParcela = parseFloat(valorTotalComJuros / index).toFixed(2);

    const string0 = labelsLanguage[language]["parcelas"];
    const string1 = labelsLanguage[language]["juros"];

    instalments.push({
      value: index,
      message: `${index} ${string0} ${valorParcela} (${string1})`,
      valueTotal: valorTotalComJuros.toFixed(2),
    });
  }

  return instalments;
};

const FinalizedPayment = ({ handleChangeOption, setIrModalOpen }) => {
  const [paymentMethod, setPaymentMethod] = useState("credit_card");

  const { language } = useLanguageState();
  const { nationality } = useNationalityState();

  const paymentSchema = yup.object().shape({
    card_name: yup.lazy(() =>
      yup.string().when([`card_number`, `card_due_date`, `card_cvv`], {
        is: (param1, param2, param3) =>
          param1 || param2 || param3 || paymentMethod === "credit_card",
        then: yup.string().required("Nome do Cartão Inválido!"),
      })
    ),
    card_number: yup.lazy(() =>
      yup.string().when([`card_name`, `card_due_date`, `card_cvv`], {
        is: (param1, param2, param3) => param1 || param2 || param3,
        then: yup.string().required("Número do Cartão Inválido!"),
      })
    ),
    card_due_date: yup.lazy(() =>
      yup.string().when([`card_name`, `card_number`, `card_cvv`], {
        is: (param1, param2, param3) => param1 || param2 || param3,
        then: yup.string().required("Valor Inválido!"),
      })
    ),
    card_cvv: yup.lazy(() =>
      yup.string().when([`card_name`, `card_number`, `card_due_date`], {
        is: (param1, param2, param3) => param1 || param2 || param3,
        then: yup.string().required("Código de Verificação Inválido!"),
      })
    ),
    document: yup.lazy(() =>
      yup.string().when(["payment_method"], {
        is: "credit_card",
        then: yup
          .string()
          .min(14, "CPF Inválido!")
          .nullable()
          .required("Documento Inválido")
          .test("isCPFValid", "Documento Inválido", (value) =>
            isDocumentValid(value)
          ),
      })
    ),

    mecenato: yup.number().oneOf([0, 1]),
    is_renewal: yup.number().oneOf([0, 1]),
    payment_method: yup.string().oneOf(["boleto", "credit_card"]),
  });

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(paymentSchema),
    defaultValues: {
      mecenato: language === "pt" ? 2 : 1,
      is_renewal: 2,
      payment_method: paymentMethod,
    },
  });
  const { errors } = formState;

  const [isLawSupport, setIsLawSupport] = useState(2);
  const [renewPlan, setRenewPlan] = useState(2);
  const [errorCaptcha, setErrorCaptcha] = useState(null);
  const [errorPayment, setErrorPayment] = useState(null);

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState();
  const [showLoading, setShowLoading] = useState(false);

  let history = useHistory();

  /**
   * Verifica recaptcha
   * @param {valor} value Valor do recaptcha
   */
  const onChange = (value) => {
    setRecaptchaValue(value);
  };

  /**
   * Verifica método de pagamento
   * @param {string} method Método de pagamento
   */
  const handleChangeMethod = (method) => {
    setPaymentMethod(method);
    setValue("payment_method", method);

    if (isIRBlocked(method)) {
      // Corrige a troca do tipo
      setIsLawSupport(2);
      setValue("mecenato", 2);
      setIrModalOpen(true);
    }
  };

  /**
   * Verifica se é mecenato ou não
   * @param {string} e Evento
   */
  const handleLawSupport = (e) => {
    //console.log(e.target.value)

    setIsLawSupport(e.target.value);
    setValue("mecenato", e.target.value);

    // Checa se não está bloqueado o IR
    if (isIRBlocked(paymentMethod) && isLawSupport && e.target.value === "1") {
      setIsLawSupport(2);
      setIrModalOpen(true);
      setValue("mecenato", null);
    }
  };

  /**
   * Cuida da renovação do plano
   * @param {valor} e Evento
   */
  const handleRenewPlan = (e) => {
    setRenewPlan(e.target.value);
    setValue("is_renewal", e.target.value);
  };

  const [installments, setInstallments] = useState([]);
  const [valorTotal, setValorTotal] = useState(0);
  const [installmentsSelected, setInstallmentSelected] = useState(1);

  // Usado para atualizar os dropdowns de parcelas
  useEffect(() => {
    const dataToken = localStorage.getItem("userData");
    dataToken && setPaymentData(JSON.parse(dataToken));
  }, [language]);

  /**
   * Seta os dados de pagamento
   * @param {any} data Dados de pagamento
   */
  const setPaymentData = (data) => {
    setSubscriptionData(data);
    setInstallments(
      calculatesValuesInstallments(data.amount_total, labelsLanguage, language)
    );
    setValorTotal(data.amount_total);
  };

  /**
   * Seta os valores das parcelas
   * @param {number} value Valor das parcelas
   */
  const handleInstallments = (value) => {
    setInstallmentSelected(value);

    setValorTotal(installments[value - 1].valueTotal);
  };

  /**
   * Cuida do SUBMIT do form de pagamento
   * @param {any} data Dados de pagamento
   */
  const submitForm = async (data) => {
    setErrorCaptcha(null);
    setErrorPayment(null);
    setShowLoading(true);

    data.document = data.document.replace(/[^\d]+/g, "");

    if (nationality !== "pt") {
      data.document = "0";
    }

    data.nationality = nationality;

    if (recaptchaValue) {
      data.payment_type_code = paymentMethod;
      data.installments = installmentsSelected;
      data.recaptcha = 1;
      data.anonimo = 1;
      data.currency_code = "BRL";
      data.merchant_payment_code = `${subscriptionData.apoio_id} - ${subscriptionData.projeto}`;
      data.custos_transacao = 0;
      data.postback_url = `https://homologacao-api.evoe.cc/status/change/subscription/${subscriptionData.apoio_id}/`;

      if (paymentMethod === "boleto") {
        data.installments = 1;
        data.card_cvv = 0;
        data.card_due_date = 0;
        data.card_name = 0;
        data.card_number = 0;

        // Pega o documento da pessoa do estado e seta no boleta
        const { document } = subscriptionData;
        data.document = document;
      }
      subscriptionData.amount_total = subscriptionData.amount_total
        .split(".")
        .join("");

      const copySubscriptionPaymentData = { ...subscriptionData, ...data };

      try {
        const result = await apiJson.createFinalizedData(
          copySubscriptionPaymentData
        );

        if (result.data.status === "CO" && paymentMethod === "credit_card") {
          localStorage.removeItem("userData");

          // Salva o método de Pagamento
          localStorage.setItem("paymentMethod", paymentMethod);
          // Salva o id do Usuário criado no DB
          localStorage.setItem("userId", result.data.new_user_id);
          history.push(`/paymentSuccess/${nationality}/`);
        } else if (result.data.status === "PE" && paymentMethod === "boleto") {
          localStorage.removeItem("userData");
          // Salva o método de Pagamento
          localStorage.setItem("paymentMethod", paymentMethod);

          history.push(
            `/paymentSuccess/${nationality}?id=${subscriptionData.apoio_id}`
          );

          // PIX
          /*} else if (result.data.status === "PI" && paymentMethod === "pix") {
          localStorage.removeItem("userData");
          // Salva o método de Pagamento
          localStorage.setItem("paymentMethod", paymentMethod);

          history.push(
            `/paymentSuccess/${nationality}?id=${subscriptionData.apoio_id}`
          );*/
        } else {
          setErrorPayment(
            "Ocorreu um erro ao processar o pagamento. Favor tentar novamente."
          );
        }
      } catch (error) {
        setErrorPayment(
          "Ocorreu um erro ao processar o pagamento. Favor tentar novamente."
        );
        setShowLoading(false);
      }
    } else {
      setErrorCaptcha("Preencha o captcha por favor");
    }

    setShowLoading(false);
  };

  /**
   * Checa o token de usuário
   */
  const checkToken = async () => {
    const dataToken = localStorage.getItem("userData");
    dataToken
      ? setPaymentData(JSON.parse(dataToken))
      : handleChangeOption("rewards");
  };

  /**
   * Effect de checagem de token de usuário
   */
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <Container onSubmit={handleSubmit(submitForm)}>
      <LogoContainer>
        {/*<Logo src="https://i.ibb.co/Px5FZp7/amigos-logo-cor.png" />*/}
        <LogoArea>
          <img alt="alt" src={projectLogo} />
          <h1>{labelsLanguage[language]["projectName"]}</h1>
        </LogoArea>
      </LogoContainer>
      {showLoading && <LaodingComponent heigth={300} width={300} />}
      <ContainerMethodPayment>
        <LinksMethodPayments
          onClick={() => handleChangeMethod("credit_card")}
          className={"credit_card" === paymentMethod && "active"}
        >
          {/**<FaRegCreditCard size={50} /> **/}
          <img alt={labelsLanguage[language]["labelCreditCard"]} src={credit} />
          <p>{labelsLanguage[language]["labelCreditCard"]}</p>
        </LinksMethodPayments>
        {nationality === "pt" && (
          <>
            <LinksMethodPayments
              onClick={() => handleChangeMethod("boleto")}
              className={"boleto" === paymentMethod && "active"}
            >
              {/**<FaBarcode size={40} /> **/}
              <img alt="Boleto Bancário" src={boleto} />
              <p>Boleto</p>
            </LinksMethodPayments>
            {/*<LinksMethodPayments
              onClick={() => handleChangeMethod("pix")}
              className={"pix" === paymentMethod && "active"}
            >
              <img alt="PIX" src={pix} />
              <p>PIX</p>
            </LinksMethodPayments>*/}
          </>
        )}
      </ContainerMethodPayment>
      {paymentMethod === "credit_card" && (
        <FormContainer>
          <FormField
            label={labelsLanguage[language]["cardName"]}
            width="48%"
            margin_bottom="20px"
            name="card_name"
            registro={register}
            errorMsg={errors.card_name?.message}
          />
          <FormField
            label={labelsLanguage[language]["cardNumber"]}
            width="50%"
            margin_bottom="20px"
            name="card_number"
            registro={register}
            errorMsg={errors.card_number?.message}
            maskParser={["9999 9999 9999 9999"]}
          />

          {nationality === "pt" && (
            <FormField
              label={labelsLanguage[language]["document"]}
              width="48%"
              name="document"
              registro={register}
              maskParser={
                nationality === "pt"
                  ? ["999.999.999-99", "99.999.999/9999-99"]
                  : []
              }
              errorMsg={errors.document?.message}
              margin_bottom="10px"
            />
          )}

          <FormField
            label={labelsLanguage[language]["cardDueDate"]}
            width="28%"
            margin_bottom="20px"
            name="card_due_date"
            registro={register}
            errorMsg={errors.card_due_date?.message}
            maskParser={["99/99"]}
          />
          <FormField
            label={labelsLanguage[language]["cardCvv"]}
            width="20%"
            margin_bottom="20px"
            name="card_cvv"
            registro={register}
            errorMsg={errors.card_cvv?.message}
            maskParser={["999"]}
          />

          <ContainerSelect>
            <LabelInput>{labelsLanguage[language]["installments"]}</LabelInput>

            <SelectBox
              onChange={(event) => handleInstallments(event.target.value)}
              value={installmentsSelected}
              style={{ marginBottom: "15px" }}
            >
              <option value="" disabled>
                {labelsLanguage[language]["chooseAnOption"]}
              </option>
              {installments
                ? installments.map((installment, index) => (
                    <option key={index} value={installment.value}>
                      {installment.message}
                    </option>
                  ))
                : null}
            </SelectBox>
            <LabelTotal style={{ paddingBottom: "15px" }}>
              Total: R$ {valorTotal}
            </LabelTotal>
          </ContainerSelect>
        </FormContainer>
      )}
      <FormContainer>
        <ContainerSelect>
          <LabelInput>
            {labelsLanguage[language]["suportIR"]}

            <div
              data-tip="Pessoas físicas que fazem declaração completa do<br> Imposto de Renda, podem abater até 6% do Imposto devido. <br>Pessoas jurídicas que declaram lucro real podem abater 4%."
              style={{
                display: "inline-block",
                border: "1px solid #544bff",
                borderRadius: "50%",
                height: "1em",
                width: "1em",
                textAlign: "center",
                color: "#544bff",
              }}
            >
              ?
            </div>
            <ReactTooltip multiline={true} />
          </LabelInput>

          <SelectBox
            value={isLawSupport}
            onChange={(e) => handleLawSupport(e)}
            color={errors.mecenato ? "red" : "black"}
            name="mecenato"
            {...register}
          >
            {nationality === "pt" ? (
              <>
                <option value={2} disabled>
                  {labelsLanguage[language]["chooseAnOption"]}
                </option>
                <option value={1}>{language === "pt" ? "Sim" : "Yes"}</option>
                <option value={0}>{language === "pt" ? "Não" : "No"}</option>
              </>
            ) : (
              <option value={0}>{language === "pt" ? "Não" : "No"}</option>
            )}
          </SelectBox>

          <ExternalLink href="https://suporteevoe.zendesk.com/hc/pt-br/sections/4404375232787-Membership-evoe-cc">
            <span>{labelsLanguage[language]["knowMoreAboutIR"]}</span>
          </ExternalLink>

          <ImpostoInfoMobile>
            <small>
              Pessoas físicas que fazem declaração completa do Imposto de Renda,
              podem abater até 6% do Imposto devido. Pessoas jurídicas que
              declaram lucro real podem abater 4%.
            </small>
          </ImpostoInfoMobile>
        </ContainerSelect>
        <ContainerSelect>
          <LabelInput>{labelsLanguage[language]["renewPlan"]}</LabelInput>
          <SelectBox
            value={renewPlan}
            onChange={(e) => handleRenewPlan(e)}
            color={errors.is_renewal ? "red" : "black"}
            name="is_renewal"
            {...register}
          >
            <option value={2} disabled>
              {labelsLanguage[language]["chooseAnOption"]}
            </option>
            <option value={1}>{language === "pt" ? "Sim" : "Yes"}</option>
            <option value={0}>{language === "pt" ? "Não" : "No"}</option>
          </SelectBox>
        </ContainerSelect>
        <ContainerCaptcha style={{ marginLeft: "-10px" }}>
          <LabelInput>{labelsLanguage[language]["notRobot"]}</LabelInput>
          <ReCAPTCHA
            hl={language}
            sitekey="6Lcx1SccAAAAAKB9DjO5-1qBbWaXUGZEBafTg3j0"
            onChange={onChange}
            style={{ paddingTop: "9px" }}
          />
          {errorCaptcha && (
            <LabelErrorCaptcha>{errorCaptcha}</LabelErrorCaptcha>
          )}
        </ContainerCaptcha>
      </FormContainer>
      <ExternalLink
        style={{
          right: "0",
          marginTop: "10px",
          display: "block",
          color: "#fff",
          textDecoration: "none",
        }}
        href="https://suporteevoe.zendesk.com/hc/pt-br/sections/4404375232787-Membership-evoe-cc"
      >
        <span
          style={{
            color: "#fff",
            textDecoration: "none",
            width: "100%",
            padding: "5px",
            backgroundColor: "#9a9a9a",
          }}
        >
          {labelsLanguage[language]["faq"]}
        </span>
      </ExternalLink>
      <Button>
        <button type="submit" style={{ marginTop: "30px" }}>
          {labelsLanguage[language]["finalisePayment"]}
        </button>
      </Button>
      <ErrorSpan>
        <span>{errorPayment}</span>
      </ErrorSpan>
    </Container>
  );
};
export default FinalizedPayment;

import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormField from "../Components/FormField";
import FormFieldTextArea from "../Components/FormFieldTextArea";
import apiJson from "../api/callApi";
import HeaderLink from "./HeaderLink";

const FormContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  padding-bottom: 50px;
  font-family: "Inconsolata", monospace;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const Button = styled.button`
  display: block;
  font-size: 1rem;
  width: 15%;
  padding: 15px;
  border: none;
  background-color: #544bff;
  color: white;
  margin-top: 10px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;

  @media (max-width: 800px) {
    min-width: 100%;
    padding: 10px 0;
  }
`;

const CardReward = ({
  label_reward,
  title,
  label_button,
  handleSaveData,
  defaultValues = {},
}) => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: defaultValues,
  });

  const submitForm = (data) => {
    console.log(data);
    handleSaveData(data);
    // await apiJson.updateUserProfileData(44234, data);
    // window.location.reload();
  };
  return (
    <Container>
      <HeaderLink
        url="/customizacao"
        label_link="Recompensa"
        label_info={label_reward}
        valueSelected="rewards"
      />

      <h2 style={{ fontFamily: "Poppins, sans-serif" }}>{title}:</h2>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <FormField
          label="Titulo Recompensa:"
          width="90%"
          margin_bottom="30px"
          has_icon={false}
          name="nome_recompensa"
          registro={register}
        />
        <FormField
          label="Preço:"
          value=""
          width="90%"
          margin_bottom="30px"
          handleChange={null}
          has_icon={false}
          name="valor_recompensa"
          registro={register}
        />
        <FormFieldTextArea
          label="Texto Recompensa:"
          value=""
          width="90%"
          margin_bottom="30px"
          name="descricao"
          registro={register}
        />
        <Button type="submit">{label_button}</Button>
      </FormContainer>
    </Container>
  );
};

export default CardReward;

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import FormDirectBankData from "../Components/FormDirectBankData";
import FormField from "./FormField";

import apiJson from "../api/callApi";
import { inhotimProjectData } from "../utils/constants";

const Container = styled.div`
  width: 70%;

  padding: 2% 0;
  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 800px) {
    border: 1px solid black;
    margin-bottom: 50px;
  }
`;

const Title = styled.h4`
  margin: 20px 3%;
  font-size: 2rem;
`;

const SubTitle = styled.h2`
  margin: 20px 5%;
`;

const Button = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 15%;
  margin-left: 30px;
  margin-top: 15px;

  @media (max-width: 800px) {
    width: 90%;
    margin-bottom: 20px;
    margin-left: 5%;
  }
`;

const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 5%;
  position: relative;
`;

const Checkbox = styled.input`
  margin: 0;
  zoom: 2;

  position: absolute;
  top: 3px;
`;

const Label = styled.p`
  margin: 0;
  font-size: 1.5rem;
  margin-left: 35px;
`;

const BankData = () => {
  const [activeBankLaw, setActiveBankLaw] = useState(false);
  const [directBankData, setDirectBankData] = useState(null);
  const [lawBankData, setLawBankData] = useState(null);

  const getBankData = async () => {
    const results = await apiJson.getBankData(inhotimProjectData.projectId);
    const results_law = await apiJson.getBankData(inhotimProjectData.projectId, 1);

    setDirectBankData(results.data);
    setLawBankData(results_law.data);
  };

  useEffect(() => {
    getBankData();
  }, []);

  const handleDirectUpdateBank = async (data) => {
    await apiJson.updateBankData(inhotimProjectData.projectId, data);
    window.location.reload();
  };

  const handleLawUpdateBank = async (data) => {
    await apiJson.updateBankData(inhotimProjectData.projectId, data, 1);
    window.location.reload();
  };

  return (
    <Container>
      <Title>Dados Inhotim</Title>
      {directBankData && (
        <FormDirectBankData
          handleData={handleDirectUpdateBank}
          title="Conta Direta:"
          defaultValues={directBankData}
        />
      )}
      <ContainerCheckbox>
        <Checkbox
          type="checkbox"
          checked={activeBankLaw}
          onClick={() => setActiveBankLaw(!activeBankLaw)}
        />
        <Label>Incluir lei de incentivo</Label>
      </ContainerCheckbox>
      {activeBankLaw && (
        <>
          {lawBankData && (
            <FormDirectBankData
              handleData={handleLawUpdateBank}
              title="Conta Lei:"
              defaultValues={lawBankData}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default BankData;
{
  /* <ContainerCheckbox>
        <Checkbox
          type="checkbox"
          checked={activeBankLaw}
          onClick={() => setActiveBankLaw(!activeBankLaw)}
        />
        <Label>Incluir lei de incentivo</Label>
      </ContainerCheckbox>

      {activeBankLaw && (
        <>
          <SubTitle>Conta Lei Incentivo:</SubTitle>
          <FormContainer>
            <FormField
              label="Titular"
              value=""
              width="48%"
              margin_bottom="10px"
              handleChange={null}
            />

            <FormField
              label="CPF/CNPJ"
              value=""
              width="48%"
              margin_bottom="10px"
              handleChange={null}
            />
            <FormField
              label="Pronac"
              value=""
              width="48%"
              margin_bottom="10px"
              handleChange={null}
            />
            <FormField
              label="Banco"
              value=""
              width="48%"
              margin_bottom="10px"
              handleChange={null}
            />
            <FormField
              label="Agência"
              value=""
              width="48%"
              margin_bottom="10px"
              handleChange={null}
            />
            <FormField
              label="Conta"
              value=""
              width="48%"
              margin_bottom="10px"
              handleChange={null}
            />
          </FormContainer>
        </>
      )}

      <Button>Salvar</Button> */
}

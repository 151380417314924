import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.margin_top};
  margin-bottom: ${(props) => props.margin_bottom};
  width: ${(props) => props.width};

  @media (max-width: 800px) {
    min-width: 100%;
  }
`;

const LabelInput = styled.label`
  padding-left: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;

  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

const FormFieldEditor = ({
  label = null,
  width = "100%",
  margin_top = "0",
  margin_bottom = "0",
}) => {
  const editorRef = useRef(null);

  return (
    <Container
      width={width}
      margin_top={margin_top}
      margin_bottom={margin_bottom}
    >
      {label && <LabelInput>{label}</LabelInput>}

      <Editor
        apiKey="lhlkwht624izuic5znz57a9evil17vmwd1gw1wvjilvwptmn"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 500,
          width: "100%",
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </Container>
  );
};

export default FormFieldEditor;

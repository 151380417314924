import React from "react";

import CardUpdate from "../Components/CardUpdate";

const EditUpdate = () => {
  return (
    <CardUpdate
      label_update="Atualização 1"
      title="Atualizações:"
      label_button="Editar"
    />
  );
};

export default EditUpdate;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

import animation_data from "../json_objects/animations/loading_json.json";
import { useUserObjectData } from "../Context/UserObjectData";
import apiJson from "../api/callApi";

import TableMembership from "../Components/TableMembership";
import TableMembershipMobile from "../Components/TableMembershipMobile";
import InfoCards from "../Components/InfoCards";
//import Contact from "../Components/Contact";
import Search from "../Components/Search";
import Greetings from "../Components/Greetings";
//import ExportSelect from "../Components/ExportSelect";
import PaginationTables from "../Components/PaginationTables";
import ExportDataComponent from "../Components/ExportDataComponent";
//import { FaPlus } from "react-icons/fa";

const ContainerActions = styled.div`
  display: flex;

  @media (max-width: 920px) {
    flex-wrap: wrap;
  }
`;

const Container = styled.div`
  padding-bottom: 100px;

  @media (max-width: 920px) {
    width: 95%;
    margin: auto;
  }
`;

/*const ButtonAddUser = styled.button`
  border: none;
  background-color: #544bff;
  color: white;
  padding: 10px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  min-width: 25%;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    min-width: 40%;
  }

  @media (max-width: 920px) {
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
  }
`;*/

const InfoText = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`;

/*const IconFaPlus = styled(FaPlus)`
  margin-left: 15px;
`;*/

const data_simpleCards = [
  {
    key_id: "count",
    title: "Total Usuários",
    value: "",
    color: "#544afb",
  },
  {
    key_id: "total_active_members",
    title: "Usuários ativos",
    value: "",
    color: "#544afb",
  },
  {
    key_id: "total_inactive_members",
    title: "Usuários inativos",
    value: "",
    color: "#544afb",
  },
];

const columns_names_Users = [
  {
    id: 1,
    name: "Nome",
    width: "16%",
    key_value: "name",
  },
  {
    id: 2,
    name: "CPF",
    width: "14%",
    key_value: "document",
  },
  {
    id: 3,
    name: "Email",
    width: "16%",
    key_value: "email",
  },
  {
    id: 4,
    name: "Recompensa",
    width: "14%",
    key_value: "reward_name",
  },
  {
    id: 5,
    name: "Ultima Entrada",
    width: "10%",
    key_value: "date_last_access",
  },
  {
    id: 5,
    name: "Data Adesão",
    width: "10%",
    key_value: "data_add",
  },
  {
    id: 7,
    name: "Status",
    width: "10%",
    key_value: "status",
  },
  {
    id: 8,
    name: "",
    width: "10%",
    key_value: "id",
  },
];

const moreFilters = [
  {
    id: 1,
    label: "Formas de Pagamento",
    filters: [
      {
        id: 1,
        label: "Boleto",
        key: "subscription__forma_pagamento1",
        value: "boleto",
        keyPdf: "payment_methods[]",
        valuePdf: "billet",
      },
      {
        id: 2,
        label: "Cartão de Crédito",
        key: "subscription__forma_pagamento2",
        value: "credit_card",
        keyPdf: "payment_methods[]",
        valuePdf: "credit_card",
      },
      {
        id: 3,
        label: "Inhotim",
        key: "subscription__forma_pagamento3",
        value: "inhotim",
        keyPdf: "payment_methods[]",
        valuePdf: "inhotim",
      },
    ],
  },
  {
    id: 2,
    label: "Tipos de Pagamento",
    filters: [
      {
        id: 4,
        label: "Lei Incentivo",
        key: "mecenato_quero1",
        value: "True",
        category: "mecenato",
        keyPdf: "payment_types[]",
        valuePdf: "lei_incentivo",
      },
      {
        id: 4,
        label: "Direto",
        key: "mecenato_quero2",
        value: "False",
        category: "mecenato",
        keyPdf: "payment_types[]",
        valuePdf: "direto",
      },
    ],
  },
  {
    id: 3,
    label: "Status",
    filters: [
      {
        id: 6,
        label: "Ativo",
        key: "subscription__signature_status1",
        value: "AT",
        keyPdf: "status[]",
        valuePdf: "AT",
      },
      {
        id: 7,
        label: "Pendente",
        key: "subscription__signature_status2",
        value: "PE",
        keyPdf: "status[]",
        valuePdf: "PE",
      },
      {
        id: 8,
        label: "Cancelada",
        key: "subscription__signature_status3",
        value: "CA",
        keyPdf: "status[]",
        valuePdf: "CA",
      },
      {
        id: 9,
        label: "Encerrada",
        key: "subscription__signature_status4",
        value: "EN",
        keyPdf: "status[]",
        valuePdf: "EN",
      },
    ],
  },
];

const Users = () => {
  const defaultOptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animation_data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  //const [detailsCountUsers, setDetailsCountUsers] = useState(data_simpleCards); // Cards usuário
  //
  const { userObjectData } = useUserObjectData();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesPagination, setTotalPagesPagination] = useState(1);
  const [localDateFilter, setLocalDateFilter] = useState({});
  const [searchContent, setSearchContent] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedFiltersState, setSelectedFiltersState] = useState([]);

  /**
   * Pega os dados pela requisição
   */
  const getData = async () => {
    //const objectData_detailsCountUsers = [...detailsCountUsers]; // Cards usuário

    setDataIsLoaded(false);
    const result = await apiJson.getAllUsersProject(
      userObjectData["project_id"],
      localDateFilter,
      searchContent,
      currentPage,
      selectedFiltersState
    );

    /* Cards usuario
    objectData_detailsCountUsers.map(
      (data) => (data["value"] = result.data[data["key_id"]])
    );*/

    const total_pages = parseInt(result.data.count / 20 + 1);
    if (totalPagesPagination !== total_pages)
      setTotalPagesPagination(total_pages);

    //setDetailsCountUsers(objectData_detailsCountUsers); Cards usuário

    setRowsData([]);
    setRowsData(result.data.results);

    setDataIsLoaded(true);
  };

  /**
   * Cuida da parte de filtro
   * @param {Date} value Data selecionada
   * @param {boolean} isDate É uma data?
   */
  const handleDateFilter = (value = null, isDate = false) => {
    value !== null
      ? setLocalDateFilter({ value: value, isDate: isDate })
      : setLocalDateFilter({});
  };

  /**
   * Cuida da parte de pesquisa
   * @param {any} data
   */
  const handleSubmitSearch = (data) => {
    setSearchContent(data["search-input"]);
  };

  /**
   * Disparado quando clicamos para trocar cada página
   * @param {any} data
   */
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  /**
   * Busca o arquivo PDF pra baixar e dispara o download
   */
  const downloadPDF = async () => {
    // TODO: Download PDF

    // Formata a url para ser enviada a API para o download do PDF
    const url = await apiJson.getUrlFormattedToDownloadPdf(
      userObjectData["project_id"],
      localDateFilter,
      searchContent,
      selectedFiltersState
    );

    console.log(url);

    window.open(url, "_blank");
  };

  /**
   * Busca o arquivo excel pra baixar e dispara o download
   */
  const downloadExcel = async () => {
    setLoading(true);
    apiJson
      .getAllUsersProjectExport(
        userObjectData["project_id"],
        localDateFilter,
        searchContent,
        selectedFiltersState
      )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          window.open(data, "_blank");
          setLoading(false);
        } else {
          console.log("Não encontrado");
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  /**
   * Atualiza os dados da página conforme os estados abaixo mudam
   */
  useEffect(() => {
    getData();
  }, [currentPage, searchContent, localDateFilter, selectedFiltersState]);

  return (
    <Container>
      <Greetings />
      <Search
        handleDateFilter={handleDateFilter}
        handleSubmitSearch={handleSubmitSearch}
        moreFiltersData={moreFilters}
        selectedFiltersState={selectedFiltersState}
        setSelectedFiltersState={setSelectedFiltersState}
      />
      {dataIsLoaded ? (
        <>
          {/*<InfoCards info_cards={data_simpleCards} />*/}
          <ContainerActions>
            {/* <ButtonAddUser>
              Adicionar novo usuario <IconFaPlus size={20} />
            </ButtonAddUser> */}
            {/* <ExportSelect width="40%" /> */}
          </ContainerActions>
          {rowsData.length > 0 ? (
            <>
              <ExportDataComponent
                loading={loading}
                downloadPDF={downloadPDF}
                downloadExcel={downloadExcel}
              />
              <TableMembershipMobile
                columns={columns_names_Users}
                rows={rowsData}
                url_button="usuarios"
                label_button="Usuário"
              />
              <TableMembership
                columns={columns_names_Users}
                rows={rowsData}
                url_button="usuarios"
                label_button="Usuário"
              />
            </>
          ) : (
            <InfoText>Nenhum resultado encontrado</InfoText>
          )}
        </>
      ) : (
        <Lottie options={defaultOptionsLottie} height={400} width={400} />
      )}
      {totalPagesPagination > 1 && (
        <PaginationTables
          numberPages={totalPagesPagination}
          handlePageClick={handlePageClick}
        />
      )}
      {/* <Contact /> */}
    </Container>
  );
};

export default Users;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { fixNumber } from "../utils/utils";

const Table = styled.table`
  margin-top: 2%;
  width: 100%;
  border-bottom: 0px;
  margin-bottom: 30px;
  border: 0.5px solid #000000;
  border-collapse: collapse;
  box-shadow: 5px 5px 10px #00000029;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Columns = styled.td`
  font-size: 1rem;
  font-weight: bold;
  padding: 20px 5px;
  text-align: center;
  border: 0.5px solid #000000;
  border-collapse: collapse;
  font-family: "Poppins", sans-serif;
  text-align: left;
`;

const Rows = styled.td`
  font-size: 1rem;
  padding: 10px 10px;
  word-wrap: break-word;
  text-align: left;
  border: 0.5px solid #000000;
  border-collapse: collapse;
  font-family: "Inconsolata", monospace;
`;

const TotalRow = styled.td`
  font-size: 1rem;
  padding: 10px 10px;
  word-wrap: break-word;
  text-align: left;
  border: 0.5px solid white;
  border-collapse: collapse;
  font-family: "Inconsolata", monospace;
  background: black;
  color: white;
  height: 45px;
`;

const TableBody = styled.tbody`
  background-color: #ffffff;
`;

const RowsBtn = styled(Rows)`
  background-color: #544bff;
  color: white;
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: transparent;
    color: #544bff;
    border: 1px solid #544bff;
  }
`;

const TextBtn = styled.p`
  margin: 0;
  font-weight: bold;
  width: 100%;
`;

const TableWrap = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const TableMembership = ({
  columns,
  rows,
  url_button,
  buttons = [],
  label_button = "Detalhes",
  showTotalRow,
}) => {
  const [total, setTotal] = useState(0);

  // Calcula o total para ser mostrado na última linha da tabela
  useEffect(() => {
    //if (showTotalRow !== undefined && showTotalRow.is) {
    /*rows.map((row) =>
      Object.keys(row).map((key_data, index) =>
        showTotalRow !== undefined &&
        key_data === showTotalRow.rowKeyDataToSum ? (
          setTotal(total + index)
        ) : (
          <></>
        )
      )
    );*/
    if (showTotalRow !== undefined && showTotalRow.is) {
      rows.map((row, index) => {
        setTotal(0);
        setTotal(total + index);
      });
    }
  }, [rows]);

  return (
    <TableWrap>
      <Table>
        <thead>
          <tr>
            {columns.map((column) => (
              <Columns key={column.id} style={{ width: column.width }}>
                {column.name}
              </Columns>
            ))}
          </tr>
        </thead>
        <TableBody>
          {rows.map((row) => (
            <tr key={row.id}>
              {Object.keys(row).map((key_data, index) =>
                key_data === "id" || key_data === "id_contribuicao" ? (
                  // Quando for um evento ao invés de um link
                  buttons.length > 0 ? (
                    buttons.map((button) => (
                      <RowsBtn onClick={() => button.action(row)}>
                        <TextBtn>{button.label}</TextBtn>
                      </RowsBtn>
                    ))
                  ) : (
                    // Quando for um link ao invés de um evento
                    <NavLink
                      key={index}
                      to={`/${url_button}/${row[key_data]}`}
                      exact={true}
                      style={{ textDecoration: "none" }}
                    >
                      <RowsBtn>
                        <TextBtn>Ver</TextBtn>
                        <br />
                        <TextBtn>{`${label_button} >`}</TextBtn>
                      </RowsBtn>
                    </NavLink>
                  )
                ) : (
                  <Rows key={index}>{row[key_data]}</Rows>
                )
              )}
            </tr>
          ))}
          {showTotalRow !== undefined && showTotalRow.is && total > 0 && (
            <tr>
              {columns.map((column, index) =>
                index === 0 ? (
                  <TotalRow>Total: </TotalRow>
                ) : index === 1 ? (
                  <TotalRow>R$ {fixNumber(total.toFixed(2))}</TotalRow>
                ) : (
                  <TotalRow />
                )
              )}
            </tr>
          )}
        </TableBody>
      </Table>
    </TableWrap>
  );
};

export default TableMembership;

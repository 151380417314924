import React, { useState, useEffect } from "react";
import styled from "styled-components";

import apiJson from "../api/callApi";
import FormDependents from "./FormDependents";

const Button = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 20%;
  margin-bottom: 20px;
  display: block;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 50px;
  }

  @media (max-width: 1200px) {
    width: 40%;
  }
`;

const Table = styled.table`
  margin-top: 2%;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 0px 0px 2px #c4c4c4;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Columns = styled.td`
  width: 33%;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 20px 5px;
  text-align: center;
  box-shadow: 0px 0px 0px 2px #c4c4c4;
`;

const TableBody = styled.tbody`
  background-color: #ffffff;
`;

const Rows = styled.td`
  font-size: 1rem;
  padding: 10px 10px;
  word-wrap: break-word;
  font-weight: bold;
  box-shadow: 0px 0px 0px 2px #c4c4c4;
  text-align: center;
`;

const DependentsShow = ({ total_dependents }) => {
  const [activateDependents, setActivateDependents] = useState(false);
  const [dependents, setDependents] = useState(false);

  const handleActivateDependents = () => {
    setActivateDependents(!activateDependents);
  };

  const getData = async () => {
    const result = await apiJson.getMemberDependents(44234);
    setDependents(result.data);
  };

  useEffect(() => {
    total_dependents > 0 && getData();
  }, []);

  useEffect(() => {
    console.log(dependents);
    console.log(total_dependents);
  }, [dependents]);

  return (
    <>
      <Button type="button" onClick={() => handleActivateDependents()}>
        Dependentes
      </Button>
      {activateDependents && (
        <>
          <Table>
            <thead>
              <tr>
                <Columns>Nome</Columns>
                <Columns>CPF</Columns>
                <Columns>Relação</Columns>
              </tr>
            </thead>

            <TableBody>
              {dependents.map((data) => (
                <tr key={data.id}>
                  <Rows>{data.name}</Rows>
                  <Rows>{data.document}</Rows>
                  <Rows>{data.relation}</Rows>
                </tr>
              ))}
            </TableBody>
          </Table>

          {total_dependents > dependents.length && <FormDependents />}
        </>
      )}
    </>
  );
};

export default DependentsShow;

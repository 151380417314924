import React from "react";
import styled from "styled-components";
import { labelsLanguage } from "../utils/language";
import { useLanguageState } from "../Context/Language";

const ContainerBorder = styled.div`
  flex-grow: 2;
  border-bottom: 1px solid black;
`;

const Container = styled.div`
  display: flex;
  //margin-top: 50px; - comentei para remover a margem do topo
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const BoxOption = styled.h2`
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
  width: 15%;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid black;
  border-bottom: 1px solid black;
  font-family: "Poppins", sans-serif;

  &.active {
    text-decoration: underline;
    border-bottom: none;
  }

  @media (max-width: 1200px) {
    width: 20%;
  }

  @media (max-width: 1024px) {
    width: 25%;
  }
`;

const ContainerOptions = ({
  options,
  activeValue,
  handleChangeOption = null,
}) => {
  const { language } = useLanguageState();

  const handleChange = (value) => {
    handleChangeOption && handleChangeOption(value);
  };

  return (
    <Container>
      {options.map((data) => (
        <BoxOption
          key={data.key_value}
          onClick={() => handleChange(data.key_value)}
          className={data.key_value === activeValue && "active"}
        >
          { labelsLanguage[language][data.key_value] }
        </BoxOption>
      ))}
      <ContainerBorder />
    </Container>
  );
};

export default ContainerOptions;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { BsCalendar } from "react-icons/bs";
import { ImSearch } from "react-icons/im";
import FilterButton from "./FilterButton";
import MoreFiltersButton from "./MoreFiltersButton";
import FilterComponent from "./FilterComponent";

import Expand from "react-expand-animated";

const ContainerSearch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

const DivMoreFilters = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
  flex-wrap: wrap;
  height: fit-content;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

const Input = styled.input`
  border: none;
  font-size: 1rem;
  padding: 3px 10px;
  font-weight: 600;
  width: 85%;
  font-family: "Poppins", sans-serif;

  &:focus {
    outline: none;
    box-shadow: inset 2px 2px 5px #00000029;
  }

  @media (max-width: 800px) {
    padding: 5px 10px;
  }
`;

const CalendarFilter = styled(Calendar)`
  font-size: 1rem;
  position: absolute;
  text-decoration: none;
  z-index: 1;
`;

const LinkIcon = styled.div`
  font-size: 2rem;
  padding-top: 3px;
  cursor: pointer;
`;
const SearchIcon = styled(ImSearch)`
  font-size: 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 800px) {
    font-size: 1.3rem;
  }
`;

const SearchButton = styled.button`
  background-color: white;
  border: none;
  width: 10%;
  cursor: pointer;
  position: relative;

  &:focus {
    outline: none;
  }
  &:hover {
    color: #544bff;
  }
`;

const Form = styled.form`
  border: 0.5px solid black;
  width: 25%;
  display: flex;
  justify-content: space-between;

  &:focus {
    box-shadow: inset 5px 5px 10px #00000029;
  }

  @media (max-width: 800px) {
    width: 85%;
  }
`;

const FiltersArea = styled(Expand)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: black;
  color: white;
  margin-top: 12px;
  overflow: wrap;

  @media (max-width: 800px) {
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
  }
`;

const searchBtns_labels = [
  {
    id: 1,
    label: "Hoje",
    value: 0,
  },
  {
    id: 2,
    label: "7 dias",
    value: 7,
  },
  {
    id: 3,
    label: "15 dias",
    value: 15,
  },
  {
    id: 4,
    label: "30 dias",
    value: 30,
  },
];

const Search = ({
  handleDateFilter,
  handleSubmitSearch,
  moreFiltersData,
  selectedFiltersState,
  setSelectedFiltersState,
}) => {
  const [activeValue, setActiveValue] = useState("");
  const [valueCalendar, setValueCalendar] = useState(new Date());
  const { register, handleSubmit } = useForm();

  const [moreFiltersOpen, setMoreFiltersOpen] = useState(false);

  /**
   * Cuida do click no botão de filtros de data
   * @param {string} label Label da aba
   * @param {string} value Valor da aba
   */
  const handleClickBtn = (label, value) => {
    handleActive(label);
    setValueCalendar(new Date());
    activeValue === label ? handleDateFilter() : handleDateFilter(value);
  };

  /**
   * Cuida do click no botão de mais filtros
   * @param {string} label Nome do filtro
   */
  const handleClickMoreFiltersBtn = (label) => {
    handleActive(label);
    setMoreFiltersOpen(!moreFiltersOpen);
  };

  /**
   * @param {string} label Filtro que estamos ativando
   */
  const handleActive = (label) => {
    activeValue === label ? setActiveValue("") : setActiveValue(label);
  };

  useEffect(() => {
    activeValue === "calendar" && handleDateFilter(valueCalendar, true);
  }, [valueCalendar]);
  return (
    <>
      <ContainerSearch>
        {searchBtns_labels.map((data) => (
          <FilterButton
            key={data.id}
            label={data.label}
            value={data.value}
            primary={true}
            handleClickBtn={handleClickBtn}
            activeValue={activeValue}
          />
        ))}

        <LinkIcon>
          <BsCalendar onClick={() => handleActive("calendar")} />
          {activeValue === "calendar" && (
            <CalendarFilter
              onChange={setValueCalendar}
              value={valueCalendar}
              selectRange={true}
            />
          )}
        </LinkIcon>
        <Form onSubmit={handleSubmit(handleSubmitSearch)}>
          <Input disabled placeholder="Busca" {...register("search-input")} />
          <SearchButton disabled type="submit">
            <SearchIcon>
              <ImSearch />
            </SearchIcon>
          </SearchButton>
        </Form>
      </ContainerSearch>

      {moreFiltersData !== undefined && (
        <DivMoreFilters>
          <MoreFiltersButton
            key={5}
            label={"Mais filtros"}
            value={"butão"}
            primary={false}
            handleClickBtn={handleClickMoreFiltersBtn}
            activeValue={activeValue}
          />

          <FiltersArea
            open={moreFiltersOpen}
            duration={200}
            transitions={["height", "opacity", "background", "display"]}
          >
            <FilterComponent
              filters={moreFiltersData}
              selectedFiltersState={selectedFiltersState}
              setSelectedFiltersState={setSelectedFiltersState}
            />
          </FiltersArea>
        </DivMoreFilters>
      )}
    </>
  );
};

export default Search;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import apiJson from "../api/callApi";
import CardReward from "../Components/CardReward";

const EditReward = ({ location }) => {
  const [reward, setReward] = useState(null);
  let history = useHistory();

  const handleSaveData = async (data) => {
    const pathname = location.pathname;
    await apiJson.updateSpecificReward(pathname.split("/")[3], data);
    history.push("/customizacao");
  };

  const getDefaultData = async (reward_id) => {
    const result = await apiJson.getSpecificReward(reward_id);

    //console.log(result.data);
    const rewardObject = {};

    rewardObject["nome_recompensa"] = result.data.nome_recompensa;
    rewardObject["valor_recompensa"] = result.data.valor_recompensa;
    rewardObject["descricao"] = result.data.descricao;

    setReward(rewardObject);
  };

  useEffect(() => {
    const pathname = location.pathname;
    getDefaultData(pathname.split("/")[3]);
  }, []);

  return (
    <>
      {reward && (
        <CardReward
          label_reward="Independente"
          title="Recompensas:"
          label_button="Editar"
          handleSaveData={handleSaveData}
          defaultValues={reward}
        />
      )}
    </>
  );
};

export default EditReward;

import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { mask } from "remask";

import apiJson from "../api/callApi";

import { NavLink } from "react-router-dom";

const Container = styled.div`
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #dedede;
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 2%;
  height: 380px;
  position: relative;
  justify-content: space-between;

  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 1400px) {
    width: 60%;
  }

  @media (max-width: 1080px) {
    width: 80%;
  }
`;

const Status = styled.div`
  height: 90px;
  width: 100px;
  background-color: #1bba1f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 15px;
  font-family: "Poppins", sans-serif;

  & > p {
    margin: 0px;
    color: white;
    font-weight: bold;
  }
`;

const ContainerInfo = styled.div`
  & > h2 {
    margin: 10px 0px;
  }
  & > h6 {
    margin: 0px;
  }
  & > p {
    margin: 10px 0px;
  }
`;

const Button = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 45%;
  font-family: "Poppins", sans-serif;
`;

const ButtonNav = styled(NavLink)`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 45%;
  text-align: center;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
`;

const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Description = styled.p`
  white-space: pre-line;
  line-height: 25px;
  font-family: "Inconsolata", monospace;
  height: 150px;
  overflow: scroll;
`;

const SearchMemberCard = ({ dataUser }) => {
  let history = useHistory();
  const handleCreateAcessControl = async (member_id) => {
    const data = {
      member_user: member_id,
      description: "",
    };
    await apiJson.createAccessControl(data);
    history.push("/entradas");
  };

  const handleCheckUser = (member_id) => {
    console.log(member_id);
  };

  return (
    <Container>
      <Status>
        <p>{dataUser.signature_status}</p>
      </Status>
      <ContainerInfo>
        <h2>{dataUser.name}</h2>
        <h2>
          CPF:{" "}
          {dataUser.document && mask(dataUser.document, '999.999.999-99')}
        </h2>
        <h3>{dataUser.reward_name}</h3>
        <Description> {dataUser.reward_description}</Description>
      </ContainerInfo>
      <ContainerButtons>
        <ButtonNav to={`/usuarios/${dataUser.user_id}`} exact={true}>
          Ver Usuário
        </ButtonNav>
        <Button onClick={() => handleCreateAcessControl(dataUser.member_id)}>
          Entrou no parque
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default SearchMemberCard;

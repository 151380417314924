import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: black;
  border: 0.5px solid black;
  width: 14%;
  cursor: pointer;
  padding: 3px 0;
  font-weight: 600;
  font-size: 1rem;
  color: white;
  font-family: "Poppins", sans-serif;

  /*&.active {
    background-color: white;
    color: black;
  }*/

  &:hover {
    border: 1px solid black;
    background-color: white;
    color: black;
  }


  @media (max-width: 800px) {
    width: 48%;
    margin-bottom: 20px;
    padding: 5px 0;
  }
`;

const MoreFiltersButton = ({
  activeValue,
  label,
  primary,
  handleClickBtn,
  value,
}) => {
  return (
    <Button
      className={label === activeValue && "active"}
      onClick={() => handleClickBtn(label, value)}
      primary={primary}
    >
      {label}
    </Button>
  );
};

export default MoreFiltersButton;

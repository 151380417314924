import React from "react";

import LinkNavbar from "./LinkNavbar";

import json from "../json_objects/json_navLinks";
import styled from "styled-components";

const ScrollableWrapper = styled.div`
  overflow-y: scroll;
  max-height: 650px;
  width: 100%;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    background: transparent; /* Optional: just make scrollbar invisible */
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const LinksNavbar = ({ type_user, handleClickMenu }) => {
  return (
    <ScrollableWrapper>
      {json.data_navLinks[0][type_user].map((data) => (
        <LinkNavbar
          key={data.id}
          key_id={data.id}
          Icon={data.Icon}
          texto={data.label}
          url={data.url}
          is_exact={data.is_exact}
          font_size={type_user === "admin" ? "1.2rem" : "1.8rem"}
          handleClickMenu={handleClickMenu}
        />
      ))}
    </ScrollableWrapper>
  );
};

export default LinksNavbar;

import React, { createContext, useState, useContext } from "react";

const LanguageContext = createContext({
    language: "pt",
    setLanguage: () => {}
});

export default function LanguageProvider ({ children }) {
    const [language, setLanguage] = useState("pt");

    return (
        <LanguageContext.Provider
            value={{ 
                language, 
                setLanguage
            }}
        >
        {children}
        </LanguageContext.Provider>
    );
};

export function useLanguageState() {
    const context = useContext(LanguageContext);
    const { language, setLanguage } = context;

    return { language, setLanguage };
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import animation_data from "../json_objects/animations/loading_json.json";

import { FaTools } from 'react-icons/fa';

import apiJson from "../api/callApi";

import TableMembership from "../Components/TableMembership";
import TableMembershipMobile from "../Components/TableMembershipMobile";
import Contact from "../Components/Contact";
import Greetings from "../Components/Greetings";
import PaginationTables from "../Components/PaginationTables";
import { useUserObjectData } from "../Context/UserObjectData";

const columns_names_memberTransactions = [
  {
    id: 1,
    name: "Valor Assinatura",
    width: "16%",
    key_value: "valor_contribuicao",
  },
  {
    id: 2,
    name: "Recompensa",
    width: "16%",
    key_value: "reward_value",
  },
  {
    id: 3,
    name: "Data de pagamento",
    width: "12%",
    key_value: "payment_date",
  },
  {
    id: 4,
    name: "Tipo de pagamento",
    width: "12%",
    key_value: "payment_method",
  },
  {
    id: 5,
    name: "Pagamento com I.R?",
    width: "10%",
    key_value: "mecenato_quero",
  },
  {
    id: 6,
    name: "Status",
    width: "12%",
    key_value: "signature_status",
  },
  {
    id: 7,
    name: "Proximo pagamento",
    width: "12%",
    key_value: "next_payment",
  },
  {
    id: 8,
    name: "",
    width: "10%",
    key_value: "id",
  },
];



const Container = styled.div`
  padding-bottom: 50px;
  height: 1vh;
  max-height: 1vh;
  overflow: hidden;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const OverlayEmBreve = styled.div`
  height: 100vh;
  width: 90%;
  background-color: #fff;
  position: absolute;
  z-index: 100;

  @media (max-width: 800px) {
    width: 95%;
  }
`;

const InfoText = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`;

const MemberTransactions = () => {
  const defaultOptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animation_data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesPagination, setTotalPagesPagination] = useState(1);
  const { userObjectData } = useUserObjectData();

  const [rowsData, setRowsData] = useState([]);

  const getData = async () => {
    const result = await apiJson.getMembersContributions(
      userObjectData["user_id"],
      currentPage
    );

    const total_pages = parseInt(result.data.count / 20 + 1);
    if (totalPagesPagination !== total_pages)
      setTotalPagesPagination(total_pages);

    setRowsData(result.data.results);
    setDataIsLoaded(true);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
    <OverlayEmBreve>
      <FaTools style={{ fontSize: "30px", marginTop: "20px" }}/>
      <h1 style={{ color: "#000" }}>Essa página estará disponível em breve!</h1>
      <h3>Enquanto isso qualquer dado, informação ou ação necessária pode ser solicitado para contato@evoe.cc</h3>
    </OverlayEmBreve>
      
      {dataIsLoaded ? (

        <>
        <Greetings />
          {rowsData.length > 0 ? (
            <>
              <TableMembershipMobile
                columns={columns_names_memberTransactions}
                rows={rowsData}
                url_button="transacoes"
              />
              <TableMembership
                columns={columns_names_memberTransactions}
                rows={rowsData}
                url_button="transacoes"
                hasRowTotal={true}
              />
            </>
          ) : (
            <InfoText>Nenhum resultado encontrado</InfoText>
          )}
        </>
      ) : (
        <Lottie options={defaultOptionsLottie} height={400} width={400} />
      )}
      {totalPagesPagination > 1 && (
        <PaginationTables
          numberPages={totalPagesPagination}
          handlePageClick={handlePageClick}
        />
      )}
      <Contact />
      

    </Container>
  );
};

export default MemberTransactions;

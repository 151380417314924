import moment from 'moment';

const isValidCPF = cpf => {
    if (!cpf) return false;

    let sum = 0;
    let mod;

    if(cpf.length > 11) return false;

    // Verifica se todos os digitos não não iguais
    let checkRepeat = 0;
    let lastChar = '';
    for (const char of cpf) {
        if (char === lastChar || lastChar === '')
            checkRepeat++;
        else
            checkRepeat = 0;

        lastChar = char;
    }
    if (checkRepeat === 11)
        return false;


    // Primeiro digito verificador
    let i = 1;
    while(i <= 9) {
        sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
        i++;
    }

    mod = (sum * 10) % 11;

    if ((mod === 10) || (mod === 11)) mod = 0;
    if (mod !== parseInt(cpf.substring(9, 10), 10)) return false;

    // Segundo digito verificador
    sum = 0;
    i = 1
    while(i <= 10) {
        sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
        i++;
    }

    mod = (sum * 10) % 11;

    if ((mod === 10) || (mod === 11)) mod = 0;
    if (mod !== parseInt(cpf.substring(10, 11), 10)) return false;

    return true;
};

const isValidCNPJ = _cnpj => {
    const cnpj = _cnpj.replace(/[^\d]+/g, '');

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14) {
        return false;
    }

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) {
        return false;
    }

    // Cáculo de validação
    const t = cnpj.length - 2,
    d = cnpj.substring(t),
    d1 = parseInt(d.charAt(0)),
    d2 = parseInt(d.charAt(1)),
    calc = x => {
        const n = cnpj.substring(0, x);
        let y = x - 7,
        s = 0,
        r = 0

        for (let i = x; i >= 1; i--) {
            s += n.charAt(x - i) * y--;
            if (y < 2)
            y = 9
        }

        r = 11 - s % 11
        return r > 9 ? 0 : r
    }

    return calc(t) === d1 && calc(t + 1) === d2;
};

export const isDocumentValid = _document => {
    const document = _document.replace(/[^\d]+/g, '');
    return  document.length === 11 ? 
         isValidCPF(document) : isValidCNPJ(document); 
};

export const isValidCEP = _cep => {
    const cep = _cep.replace(/\D/g, '');

    //Verifica se campo cep possui valor informado.
    if (cep !== "") {
        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        return validacep.test(cep)
    } else {
        return false;
    }
}

export const isValidBirthday = date => {
    let fieldValue = moment(date).format('DD/MM/YYYY');

    //console.log(fieldValue)
    return moment(date).isValid();
}

export const isEmail = _email => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(_email);
}
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import apiJson from "../api/callApi";
import { useUserObjectData } from "../Context/UserObjectData";
import { inhotimProjectData } from "../utils/constants";

import { FaTools } from 'react-icons/fa';

import Reward from "../Components/Reward";
import PaymentForm from "../Components/PaymentForm";
import DependentsShow from "../Components/DependentsShow";

const Container = styled.div`
  padding-bottom: 100px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Inconsolata", monospace;
`;

const OverlayEmBreve = styled.div`
  height: 100vh;
  width: 90%;
  background-color: #fff;
  position: absolute;
  z-index: 100;

  @media (max-width: 800px) {
    width: 95%;
  }
`;

const EditSubscription = () => {
  const [actualReward, setActualReward] = useState([]);
  const { userObjectData } = useUserObjectData();

  const getDataMemberReward = async () => {
    const result = await apiJson.getMemberReward(userObjectData["user_id"]);
    setActualReward(result.data);
  };

  useEffect(() => {
    getDataMemberReward();
  }, []);

  return (
    <Container>
    <OverlayEmBreve>
      <FaTools style={{ fontSize: "30px", marginTop: "20px" }}/>
      <h1 style={{ color: "#000" }}>Essa página estará disponível em breve!</h1>
      <h3>Enquanto isso qualquer dado, informação ou ação necessária pode ser solicitado para contato@evoe.cc</h3>
    </OverlayEmBreve>
      

      {actualReward && (
        <>
        <h1>Recompensa</h1>
          <Reward
            title={actualReward.nome_recompensa}
            description={actualReward.descricao}
          ></Reward>

          <PaymentForm />
          {actualReward.total_dependentes > 0 && (
            <DependentsShow total_dependents={actualReward.total_dependentes} />
          )}
        </>
      )}
    </Container>
  );
};

export default EditSubscription;

// import ArrowIcon from "../Icons/caret.svg";
// const Select = styled.select`
//   border-radius: 5px;
//   border: 1px solid black;
//   font-size: 1.2rem;
//   padding: 10px 10px;
//   display: block;
//   width: 100%;
//   margin: 10px 0;

//   background-image: url(${ArrowIcon});
//   background-repeat: no-repeat;
//   background-position: right 15px bottom 50%;
//   background-size: 15px 20px;
//   -webkit-appearance: none;
//   cursor: pointer;
//   outline: 0px;
// `;

{
  /* <Button type="button" onClick={() => handleActivateReward()}>
Trocar Recompensa
</Button> */
}
// const [selectedReward, setSelectedReward] = useState([]);
// const [rewards, setRewards] = useState([]);

// const [activateChangeReward, setActivateChangeReward] = useState(false);

// const handleActivateReward = () => {
//   setActivateChangeReward(!activateChangeReward);
// };

// const handleChangeReward = () => {
//   actualReward.id !== selectedReward.id && console.log(selectedReward);
// };
// const getDataRewards = async () => {
//   const result = await apiJson.getRewards(inhotimProjectData.projectId);
//   setRewards(result.data.results);
//   setSelectedReward(result.data.results[0]);
// };

// const changeSelectedReward = (e) => {
//   const objectReward = rewards.find((data) => data.id == e.target.value);

//   setSelectedReward(objectReward);
// };

// {activateChangeReward && (
//   <>
//     {selectedReward && (
//       <Reward
//         title={selectedReward.valor_recompensa}
//         description={selectedReward.descricao}
//       >
//         <Label>Recompensa Nova:</Label>

//         <Select onChange={(e) => changeSelectedReward(e)}>
//           {rewards.map((data) => (
//             <option key={data.id} value={data.id}>
//               {data.valor_recompensa}
//             </option>
//           ))}
//         </Select>
//       </Reward>
//     )}
//     <Button onClick={() => handleChangeReward()}>
//       Alterar Recompensa
//     </Button>
//   </>
// )}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import apiJson from "../api/callApi";

import FormEditProfile from "../Components/FormEditProfile";
import FormEditPassword from "../Components/FormEditPassword";

const Container = styled.div`
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const EditProfile = () => {
  const [profileDefaultData, setProfileDefaultData] = useState(null);

  const getData = async () => {
    const data = await apiJson.getUserProfileData(44234);
    setProfileDefaultData(data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      {profileDefaultData && (
        <>
          <FormEditProfile defaultValues={profileDefaultData} />
          <FormEditPassword />
        </>
      )}
    </Container>
  );
};

export default EditProfile;

{
  /* <Button type="button" onClick={() => handleActivatePassword()}>
Trocar Senha
</Button>
{activateChangePassword && (
<FormPassword>
  <FormField
    label="Senha:"
    value=""
    handleChange={handleChange}
    width="48%"
    margin_bottom="2%"
    type="password"
  />

  <ButtonPassword>Salvar</ButtonPassword>
</FormPassword>
)} */
}

{
  /* <Button type="button" onClick={() => handleActivateDepends()}>
Dependentes
</Button>
{activateDepends && (
<>
  <FormContainer>
    <FormField
      label="Nome:"
      value=""
      width="48%"
      margin_bottom="10px"
      handleChange={handleChange}
    />
    <FormField
      label="CPF:"
      value=""
      width="48%"
      margin_bottom="10px"
      handleChange={handleChange}
    />
  </FormContainer>
  <FaPlus
    size={40}
    style={{
      margin: "15px 5px",
      color: "#544bff",
      cursor: "pointer",
    }}
  />
</>
)} */
}

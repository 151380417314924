import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import FormField from "../Components/FormField";
import HeaderLink from "../Components/HeaderLink";
import apiJson from "../api/callApi";
import { useUserObjectData } from "../Context/UserObjectData";

const Container = styled.div`
  padding-bottom: 50px;
  font-family: "Inconsolata", monospace;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const CardDataBank = styled.div`
  display: flex;
  width: 80%;
  min-height: 300px;
  border: 1px solid black;
  margin-bottom: 50px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
`;

const CardWithdrawal = styled.div`
  display: flex;
  width: 40%;
  height: 200px;
  border: 1px solid black;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 70%;
    height: auto;
    padding-bottom: 20px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ContainerDataBank = styled.div`
  width: 48%;
  padding: 3%;
  box-sizing: border-box;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ContainerValues = styled.div`
  width: 40%;
  padding: 3%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 10px;
`;

const Value = styled.h2`
  margin: 0;
  margin-bottom: 10px;
  color: #544bff;
`;

const Label = styled.p`
  margin: 0;
  margin-right: 10px;
`;
const ValueBank = styled.p`
  margin: 0;
  font-weight: bold;
`;

const InfoBank = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ContainerWithdrawal = styled.form``;

const Button = styled.button`
  font-size: 1rem;
  min-width: 10%;
  padding: 10px 20px;
  border: none;
  background-color: #544bff;
  color: white;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const data_bank = [
  {
    key_id: "banco",
    label: "Banco:",
    value: "Nome do banco",
  },
  {
    key_id: "agencia",
    label: "Agência:",
    value: "0000",
  },
  {
    key_id: "conta",
    label: "Conta:",
    value: "0000-0",
  },
  {
    key_id: "titular",
    label: "Titular:",
    value: "Nome",
  },
  {
    key_id: "cpf",
    label: "CPF/CNPJ:",
    value: "000.000.000-00",
  },
];

const Withdrawal = ({ location }) => {
  let history = useHistory();
  const [financialInfo, setFinancialInfo] = useState(null);
  const [bankData, setBankData] = useState(data_bank);
  const [typeAccount, setTypeAccount] = useState(data_bank);
  const { userObjectData } = useUserObjectData();

  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;
  const submitForm = async (data) => {
    const post_data = {};

    if (
      data.valueWithdrawal == 0 ||
      data.valueWithdrawal > financialInfo.total_saque
    ) {
      //console.log("error");
    } else {
      post_data.valor = data.valueWithdrawal * -1;
      post_data.chave_projeto = userObjectData["project_id"];
      post_data.label = "Saque";
      post_data.valor_fee = financialInfo.fee * -1;
    }

    if (typeAccount === "direct") {
      await apiJson.postFinancialTransactions(
        userObjectData["project_id"],
        data
      );
    } else {
      await apiJson.postFinancialTransactionsBlockAccount(
        userObjectData["project_id"],
        data
      );
    }
    history.push("/financeiro");
  };

  const getData = async (type_account) => {
    const objectData_bank = [...bankData];
    let result = null;
    let bank_data = null;
    if (type_account === "direct") {
      result = await apiJson.getFinancialTransactions(
        userObjectData["project_id"]
      );
      bank_data = await apiJson.getBankData(userObjectData["project_id"]);
    } else {
      result = await apiJson.getFinancialTransactionsBlockAccount(
        userObjectData["project_id"]
      );
      bank_data = await apiJson.getBankData(userObjectData["project_id"], 1);
    }

    objectData_bank.map(
      (data) => (data["value"] = bank_data.data[data["key_id"]])
    );

    setBankData(objectData_bank);
    setFinancialInfo(result.data);
  };

  useEffect(() => {
    const queryString = location.search;
    setTypeAccount(queryString.split("?account=")[1]);
    getData(queryString.split("?account=")[1]);
  }, []);

  return (
    <Container>
      <HeaderLink
        url="/financeiro"
        label_link="Financeiro"
        label_info="Saque conta direta"
        valueSelected="direct_account"
      />
      <h2>Realizar o saque:</h2>
      {financialInfo && (
        <ContainerWithdrawal onSubmit={handleSubmit(submitForm)}>
          <CardDataBank>
            <ContainerDataBank>
              <Title>Total disponível</Title>
              <Value>R$ {financialInfo.soma.toFixed(2)}</Value>
              <Title style={{ marginTop: "40px" }}>
                Valor que deseja sacar:
              </Title>
              <FormField
                label=""
                width="90%"
                name="valueWithdrawal"
                margin_bottom="10px"
                registro={register}
              />
            </ContainerDataBank>
            <ContainerDataBank>
              <Title>Conta bancária:</Title>
              {bankData.map((data) => (
                <InfoBank key={data.key_id}>
                  <Label>{data.label}</Label>
                  <ValueBank>{data.value}</ValueBank>
                </InfoBank>
              ))}

              <NavLink
                to={{
                  pathname: "/configuracoes",
                  valueSelected: "bank_data",
                }}
                exact={true}
              >
                <Button>Não são seus dados? Edite aqui</Button>
              </NavLink>
            </ContainerDataBank>
          </CardDataBank>

          <CardWithdrawal>
            <ContainerValues>
              <Title>Taxa:</Title>
              <Value>
                R$ {financialInfo.fee.toFixed(2)}
                <span style={{ color: "black", fontSize: "0.8rem" }}>
                  (12%)
                </span>
              </Value>
            </ContainerValues>
            <ContainerValues>
              <Title>Total líquido:</Title>
              <Value>R$ {financialInfo.total_saque.toFixed(2)}</Value>
            </ContainerValues>
            <Button style={{ width: "95%", margin: "auto" }}>
              Solicitar Saque
            </Button>
          </CardWithdrawal>
        </ContainerWithdrawal>
      )}
    </Container>
  );
};

export default Withdrawal;

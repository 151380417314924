import React from "react";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.margin_top};
  margin-bottom: ${(props) => props.margin_bottom};
  width: ${(props) => props.width};

  @media (max-width: 800px) {
    min-width: 100%;
  }
`;

const LabelInput = styled.label`
  padding-left: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;

  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  box-sizing: border-box;
  resize: vertical;
  font-family: "Inconsolata", monospace;
`;

const FormFieldTextArea = ({
  registro,
  name,
  label = null,
  width = "100%",
  margin_top = "0",
  margin_bottom = "0",
  placeholder = "",
}) => {
  return (
    <Container
      width={width}
      margin_top={margin_top}
      margin_bottom={margin_bottom}
    >
      {label && <LabelInput>{label}</LabelInput>}

      <TextArea rows="10" placeholder={placeholder} {...registro(name)} />
    </Container>
  );
};

export default FormFieldTextArea;

import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import FormField from "./FormField";

const Container = styled.form`
  width: 100%;

  padding: 2% 0;

  @media (max-width: 800px) {
    border: 1px solid black;
    margin-bottom: 50px;
  }
`;

const SubTitle = styled.h2`
  margin: 20px 5%;
`;

const Button = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 15%;
  margin-left: 30px;
  margin-top: 15px;

  @media (max-width: 800px) {
    width: 90%;
    margin-bottom: 20px;
    margin-left: 5%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
`;

const FormBankData = ({ handleData, title, defaultValues = {} }) => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: defaultValues,
  });

  const submitForm = async (data) => {
    handleData(data);
  };
  return (
    <Container onSubmit={handleSubmit(submitForm)}>
      <SubTitle>{title}</SubTitle>
      <FormContainer>
        <FormField
          label="Titular"
          width="48%"
          margin_bottom="10px"
          name={"titular"}
          registro={register}
        />

        <FormField
          label="CPF/CNPJ"
          width="48%"
          margin_bottom="10px"
          name={"cpf"}
          registro={register}
        />
        <FormField
          label="Banco"
          width="100%"
          margin_bottom="10px"
          name={"banco"}
          registro={register}
        />
        <FormField
          label="Agência"
          width="48%"
          margin_bottom="10px"
          name={"agencia"}
          registro={register}
        />
        <FormField
          label="Conta"
          width="48%"
          margin_bottom="10px"
          name={"conta"}
          registro={register}
        />
      </FormContainer>
      <Button>Salvar</Button>
    </Container>
  );
};

export default FormBankData;

import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormField from "../Components/FormField";
import apiJson from "../api/callApi";

const Button = styled.button`
  width: 20%;
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  display: block;
  margin: 20px 0;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const ButtonPassword = styled(Button)`
  width: 10%;
  margin-left: 30px;
  margin-top: 20px;
`;

const FormPassword = styled.form`
  display: flex;
  align-items: center;
`;

const schema = yup.object().shape({
  new_password: yup.string().min(7, "Senha não pode ser menor que 8 dígitos"),
});

const FormEditPassword = () => {
  const [activateChangePassword, setActivateChangePassword] = useState(false);
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const handleActivatePassword = () => {
    setActivateChangePassword(!activateChangePassword);
  };

  const submitForm = async (data) => {
    await apiJson.updateUserPassword(44234, data);
    window.location.reload();
  };

  return (
    <>
      <Button type="button" onClick={() => handleActivatePassword()}>
        Trocar Senha
      </Button>
      {activateChangePassword && (
        <FormPassword onSubmit={handleSubmit(submitForm)}>
          <FormField
            label="Senha:"
            width="48%"
            margin_bottom="2%"
            type="password"
            name="new_password"
            registro={register}
            errorMsg={errors.new_password?.message}
          />
          <ButtonPassword>Salvar</ButtonPassword>
        </FormPassword>
      )}
    </>
  );
};

export default FormEditPassword;

import React from "react";
import { useHistory } from "react-router-dom";

import apiJson from "../api/callApi";
import { inhotimProjectData } from "../utils/constants";
import CardReward from "../Components/CardReward";

const CreateReward = () => {
  let history = useHistory();
  const handleSaveData = async (data) => {
    data["projeto"] = inhotimProjectData.projectId;

    await apiJson.createReward(data);
    history.push("/customizacao");
  };

  return (
    <CardReward
      label_reward="Nova Recompensa"
      title="Criar nova recompensa:"
      label_button="Criar Recompensa"
      handleSaveData={handleSaveData}
    />
  );
};

export default CreateReward;

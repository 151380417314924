import React, { useEffect, useState } from "react";
import styled from "styled-components";

import auth from "../api/authService";
import { useUserObjectData } from "../Context/UserObjectData";
import LoadingComponent from "../Components/LoadingComponent";
import { useHistory } from "react-router-dom";

const ContainerLinks = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  & > a:first-child {
    text-align: end;
  }

  @media (max-width: 600px) {
    justify-content: space-evenly;
  }
`;

const Form = styled.form`
  //border-radius: 5px;
  box-sizing: border-box;
  //box-shadow: 0px 0px 10px #000000;
  
  -webkit-box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.59);

  width: 45%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 3%;
    background-color: white;

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 980px) {
    width: 70%;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const FormBtn = styled.button`
  padding: 15px 0;
  width: 50%;
  color: white;
  background-color: #544bff;
  font-weight: bold;
  cursor: pointer;
  border: none;
  margin-top: 5%;
  margin-bottom: 10%;
  box-sizing: border-box;
  font-size: 1.1rem;
  outline: none;
  font-family: "Poppins", sans-serif;

  &:hover {
    box-shadow: 5px 5px 10px #00000029;
  }

  @media (max-width: 600px) {
    width: 100%;
    font-size: 1.5rem;
  }
`;

const Link = styled.a`
  cursor: pointer;

  &:hover {
    color: #544bff;
    text-decoration: underline;
  }
`;

const LinkResetPassword = styled(Link)`
  margin-top: 10px;
`;

const LinkLGPD = styled(Link)`
  width: 45%;
  font-family: "Inconsolata", monospace;

  @media (max-width: 600px) {
    width: auto;
  }
`;

const FormTitle = styled.h1`
  text-align: center;
  font-size: 3rem;
  margin-top: 10%;
  margin-bottom: 5%;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-weight: black;

  @media (max-width: 600px) {
    font-size: 2.3rem;
  }
`;

const Separator = styled.div`
  border-left: 2px solid black;
  height: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.margin_top};
  margin-bottom: ${(props) => props.margin_bottom};
  width: ${(props) => props.width};
`;

const LabelInput = styled.label`
  padding-left: 5px;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 0.9rem;
  font-family: Inconsolata;

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;

  @media (max-width: 600px) {
    padding: 15px 15px;
  }
`;

const ContainerInput = styled.div`
  width: 100%;
  position: relative;
`;

const FormLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const { userobjectData, setUserObjectData } = useUserObjectData();
  let history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    setShowLoading(true);

    try {
      const result = await auth.login(username, password);
      setUserObjectData(result);
      // Aqui é quando a pessoa loga e troca pra tab principal
      history.push("/editar-perfil");
    } catch (e) {
      if (typeof e === "string") {
        console.log(e);
      } else {
        console.log(e.response);
      }
    }

    setShowLoading(false);
  };

  const handleChangeUsername = (e) => {
    e.persist();
    setUsername(e.target.value);
  };

  const handleChangePassword = (e) => {
    e.persist();
    setPassword(e.target.value);
  };

  return (
    <Form>
      {showLoading && (<LoadingComponent heigth={300} width={300}/>)}
      <FormTitle>Bem Vindo ao Membership Evoé!</FormTitle>

      <Container width="100%" margin_bottom="20px">
        <LabelInput>Email:</LabelInput>

        <ContainerInput>
          <Input value={username} onChange={(e) => handleChangeUsername(e)} />
        </ContainerInput>
      </Container>
      <Container width="100%" margin_bottom="20px">
        <LabelInput>Senha:</LabelInput>

        <ContainerInput>
          <Input
            value={password}
            type="password"
            onChange={(e) => handleChangePassword(e)}
          />
        </ContainerInput>
      </Container>

      {/* <LinkResetPassword>Esqueci a senha</LinkResetPassword> */}
      <FormBtn onClick={(e) => handleLogin(e)}>Log in</FormBtn>
      <ContainerLinks>
        <LinkLGPD>Termos de uso</LinkLGPD>
        <Separator />
        <LinkLGPD>Politica de privacidade</LinkLGPD>
      </ContainerLinks>
    </Form>
  );
};

export default FormLogin;

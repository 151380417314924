import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import TableMembership from "../Components/TableMembership";
import TableMembershipMobile from "../Components/TableMembershipMobile";
import ExportSelect from "./ExportSelect";

const Container = styled.div`
  width: 100%;
  padding: 2% 0;

  @media (max-width: 800px) {
    border: 0.5px solid black;
    margin-bottom: 50px;
  }
`;

const Reward = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 2%;
  border-bottom: 1.5px solid #c4c4c4;
  margin-top: 30px;
`;

const ContainerDescription = styled.div`
  width: 80%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ContainerButtons = styled.div`
  width: 12%;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const ContainerAddUpdate = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  margin-bottom: 50px;
  padding: 3% 5%;
  box-sizing: border-box;
`;

const ContainerUpdates = styled.div`
  width: 100%;
  border-top: 1px solid black;
  margin-top: 30px;
  padding: 3% 5%;
  box-sizing: border-box;
`;

const TitleReward = styled.h4`
  margin: 0;
  margin-bottom: 10px;

  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

const DescReward = styled.p`
  margin: 0;
`;

const Button = styled.button`
  font-size: 1rem;
  width: 100%;
  padding: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  margin-top: 10px;
  cursor: pointer;
`;

const ButtonAddUpdate = styled(Button)`
  width: 30%;
  padding: 10px 0;

  @media (max-width: 1024px) {
    width: 40%;
    margin-bottom: 20px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  width: 95%;
  margin: auto;
`;

const columns_Atualizacoes = [
  {
    id: 1,
    name: "Assunto",
    width: "26%",
    key_value: "subject",
  },
  {
    id: 2,
    name: "Tipo Recompensa",
    width: "22%",
    key_value: "type_reward",
  },
  {
    id: 3,
    name: "Data",
    width: "14%",
    key_value: "date",
  },
  {
    id: 4,
    name: "Hora",
    width: "14%",
    key_value: "time",
  },
  {
    id: 5,
    name: "Status",
    width: "14%",
    key_value: "status",
  },
  {
    id: 6,
    name: "",
    width: "10%",
    key_value: "button",
  },
];

const rows_Atualizacoes = [
  {
    subject: "Email 1 atualização",
    type_reward: "Independente",
    date: "01/01/2022",
    time: "15:33",
    status: "Enviado",
    id: 8,
  },
  {
    subject: "Email 1 atualização",
    type_reward: "Independente",
    date: "01/01/2022",
    time: "15:33",
    status: "Enviado",
    id: 8,
  },
];

const Updates = () => {
  return (
    <Container>
      <ContainerAddUpdate>
        <TitleReward>Criar novo template atualização</TitleReward>
        <NavLink to={`/customizacao/criar-atualizacao`} exact={true}>
          <ButtonAddUpdate>Adicionar</ButtonAddUpdate>
        </NavLink>
      </ContainerAddUpdate>
      <Title>Rascunhos:</Title>
      <Reward>
        <ContainerDescription>
          <TitleReward>Atualização 2</TitleReward>
          <DescReward>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scram
          </DescReward>
        </ContainerDescription>
        <ContainerButtons>
          <Button style={{ backgroundColor: "#1bba1f" }}>Enviar</Button>
          <NavLink to={`/customizacao/atualizacoes/3`} exact={true}>
            <Button>Editar</Button>
          </NavLink>
          <Button style={{ backgroundColor: "#ff0000" }}>Deletar</Button>
        </ContainerButtons>
      </Reward>
      <Reward style={{ border: "none" }}>
        <ContainerDescription>
          <TitleReward>Atualização 1</TitleReward>
          <DescReward>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scram
          </DescReward>
        </ContainerDescription>
        <ContainerButtons>
          <Button style={{ "background-color": "#1bba1f" }}>Enviar</Button>
          <NavLink to={`/customizacao/atualizacoes/3`} exact={true}>
            <Button>Editar</Button>
          </NavLink>
          <Button style={{ "background-color": "#ff0000" }}>Deletar</Button>
        </ContainerButtons>
      </Reward>
      <ContainerUpdates>
        <h2>Historico das atualizações:</h2>
        <ExportSelect />
        <TableMembershipMobile
          columns={columns_Atualizacoes}
          rows={rows_Atualizacoes}
          url_button="customizacao/atualizacoes"
          label_button="Atualização"
        />
        <TableMembership
          columns={columns_Atualizacoes}
          rows={rows_Atualizacoes}
          url_button="customizacao/atualizacoes"
          label_button="Atualização"
        />
      </ContainerUpdates>
    </Container>
  );
};

export default Updates;

import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { FaLongArrowAltRight } from "react-icons/fa";

const Header = styled.div`
  display: flex;
  align-items: center;
  font-family: "Inconsolata", monospace;

  & > p {
    margin: 0px;
  }
  & > a {
    color: black;
    text-decoration: none;
  }
  & > a:hover {
    color: #544bff;
    text-decoration: underline;
  }
`;

const ArrowIcon = styled(FaLongArrowAltRight)`
  align-self: flex-end;
  padding: 0px 10px;
`;

const HeaderLink = ({
  url,
  label_link,
  label_info,
  is_exact = true,
  valueSelected = "",
}) => {
  return (
    <Header>
      <NavLink
        to={{
          pathname: url,
          valueSelected: valueSelected,
        }}
        exact={is_exact}
      >
        {label_link}
      </NavLink>
      <ArrowIcon />
      <p>{label_info}</p>
    </Header>
  );
};

export default HeaderLink;

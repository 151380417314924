import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

import animation_data from "../json_objects/animations/loading_json.json";

import apiJson from "../api/callApi";
import { inhotimProjectData } from "../utils/constants";

import TableMembership from "../Components/TableMembership";
import TableMembershipMobile from "../Components/TableMembershipMobile";
import Greetings from "../Components/Greetings";
import Contact from "../Components/Contact";
import Search from "../Components/Search";
import ExportSelect from "../Components/ExportSelect";
import InfoCards from "../Components/InfoCards";
import PaginationTables from "../Components/PaginationTables";

const data_simpleCards = [
  {
    key_id: "count",
    title: "Numero de Colaboradores",
    value: "",
    color: "#544afb",
  },
];

const columns_names_Staff = [
  {
    id: 1,
    name: "Nome",
    width: "16%",
    key_value: "name",
  },
  {
    id: 2,
    name: "CPF",
    width: "16%",
    key_value: "document",
  },
  {
    id: 3,
    name: "Email",
    width: "16%",
    key_value: "email",
  },
  {
    id: 4,
    name: "Emprego",
    width: "16%",
    key_value: "job",
  },
  {
    id: 5,
    name: "Ultima Venda",
    width: "14%",
    key_value: "last_sale_date",
  },
  {
    id: 6,
    name: "Total Vendas",
    width: "12%",
    key_value: "number_sales",
  },
  {
    id: 7,
    name: "",
    width: "10%",
    key_value: "id",
  },
];

const Container = styled.div`
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 95%;
    margin: auto;
  }
`;

const Staffs = () => {
  const defaultOptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animation_data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesPagination, setTotalPagesPagination] = useState(1);

  const [rowsData, setRowsData] = useState([]);
  const [detailsStaff, setDetailsStaff] = useState(data_simpleCards);

  const getData = async () => {
    const objectData_detailsStaff = [...detailsStaff];
    setDataIsLoaded(false);
    const result = await apiJson.getAllStaffMembers(
      inhotimProjectData.projectId
    );

    objectData_detailsStaff.map(
      (data) => (data["value"] = result.data[data["key_id"]])
    );

    const total_pages = parseInt(result.data.count / 20 + 1);
    if (totalPagesPagination !== total_pages)
      setTotalPagesPagination(total_pages);

    setDetailsStaff(objectData_detailsStaff);
    setRowsData([]);
    setRowsData(result.data.results);
    setDataIsLoaded(true);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <Container>
      <Greetings />
      <Search />
      {dataIsLoaded ? (
        <>
          <InfoCards info_cards={detailsStaff} />
          {/* <ExportSelect /> */}
          <TableMembershipMobile
            columns={columns_names_Staff}
            rows={rowsData}
            url_button="colaboradores"
            label_button="Colaborador"
          />
          <TableMembership
            columns={columns_names_Staff}
            rows={rowsData}
            url_button="colaboradores"
            label_button="Colaborador"
          />
        </>
      ) : (
        <Lottie options={defaultOptionsLottie} height={400} width={400} />
      )}

      {totalPagesPagination > 1 && (
        <PaginationTables
          numberPages={totalPagesPagination}
          handlePageClick={handlePageClick}
        />
      )}

      {/* <Contact /> */}
    </Container>
  );
};

export default Staffs;

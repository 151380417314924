export const labelsLanguage = {
  pt: {
    //Labels do Formulário do usuário
    name: "Nome: ",
    confirmEmail: "Confirmar Email:",
    document: "CPF/CNPJ: ",
    phone_number: "Telefone: ",
    address: "Endereço: ",
    complement: "Complemento: ",
    number: "Número: ",
    neighborhood: "Bairro: ",
    city: "Cidade: ",
    state: "Estado: ",
    country: "País: ",
    nationality: "Nacionalidade: ",
    cep: "CEP: ",
    birthday: "Data de Nascimento: ",

    // langs
    langPt: "Português",
    langEn: "Inglês",

    //Labels do Formuláro de Pagamento
    labelCreditCard: "Cartão de Crédito",
    cardName: "Nome no Cartão: ",
    cardNumber: "Número do Cartão: ",
    cardDueDate: "Validade: ",
    cardCvv: "Código de Segurança: ",
    suportIR: "Pagamento usando I.R.",
    renewPlan: "Renovar Plano Anualmente",
    provideSupport: "Realizar Apoio",
    finalisePayment: "Finalizar pagamento",
    installments: "Parcelas",
    brazilian: "Brasileiro",
    other: "Outro",

    //Titulos das Tabs do Formulário de Pagamento
    welcome: "Bem-Vindo",
    rewards: "Plano",
    "user-data": "Detalhes Usuário",
    payment: "Pagamento",
    exportTxt: "Exportar TXT",

    //Textos soltos
    agreement: "Ao continuar você está concordando com nossos",
    termsAndConditions: "Termos e Condições",
    faq: "Dúvidas Frequentes",
    year: "ano",
    moreInfos0: "Mais informações, fale conosco pelo",
    moreInfos1: " ou WhatsApp",
    selectYourPlan: "Selecione seu plano",
    notRobot: "Não sou um robô",
    projectName: "Amigos do Inhotim",
    projectDescription: "Entrada gratuita e sem filas o ano todo, descontos em serviços e eventos no Inhotim, experiências e conteúdos exclusivos e muito mais. Ser Amigo do Inhotim é fazer parte de uma comunidade conectada com Instituto, é ajudar na manutenção e sustentabilidade do museu e Jardim Botânico. Para fazer parte dos Amigos do Inhotim é muito fácil e o valor total da adesão pode ser deduzido do Imposto de Renda.",
    chooseAnOption: "Escolha uma opção",
    parcela: "parcela de",
    parcelas: "parcelas de",
    aVista: "à vista",
    juros: "com Juros",
    knowMoreAboutIR: "Saiba mais sobre apoio via I.R",

    //Botões
    next: "Próxima",
    continue: "Continuar",
    support: "Clique aqui e torne-se agora um Amigo do Inhotim",

    //Página de Sucesso no Pagamento
    titleBillet: "Boleto enviado para o seu email",
    titleCreditCard: "Pagamento realizado com Sucesso!",
    labelDownload: "Ou você pode realizar o download aqui",

    subTitleBillet:
      "Após a confirmação do pagamento você receberá um e-mail confirmando sua adesão, pode levar até 72horas.",
    subTitleCard: "Agora você faz parte do Amigos do Inhotim",
    body: "Você receberá por e-mail sua carteirinha e link para preencher os dados dos seus dependentes(caso seu plano tenha esse benefício).",
    buttonDependents: "Adicionar Dependentes",
    billetDownload: "Baixar Boleto",

    importantLabel: "Importante",
    importantBillet:
      "O beneficiário do boleto gerado é o Ebanx Pagamentos / Curitiba-PR",
    importantCreditCard: "Em sua fatura aparecerá a descrição EBANX*EVOE",
    question: "Qualquer dúvida enviar e-mail para",

    errorSave:
      "Não foi possível salvar os dependentes neste momento, tente novamente mais tarde!",
    successSave: "Dependentes Salvo com Sucesso.",

    irContributionTitle: "Aviso importante para contribuintes via I.R",
    irContributionContent: "As contribuições que serão deduzidas no IRPF 2022 (ano-base 2021), se encerraram via sistema, mas podem ser realizadas via depósito",
    irContributionSteps: "Siga o passo a passo aqui",
  },
  en: {
    //Labels do Formulário do usuário
    name: "Name: ",
    confirmEmail: "Confirm Email:",
    document: "CPF/CNPJ: ",
    phone_number: "Phone: ",
    address: "Address: ",
    complement: "House name/ Appartment number: ",
    number: "Number: ",
    neighborhood: "Neighborhood: ",
    city: "City: ",
    state: "State: ",
    country: "Country: ",
    nationality: "Nationality: ",
    cep: "Zip Code: ",
    birthday: "Birthday: ",

    //Labels do Formuláro de Pagamento
    labelCreditCard: "Credit Card",
    cardName: "Name on Credit Card: ",
    cardNumber: "Card Number: ",
    cardDueDate: "Expiry date: ",
    cardCvv: "Security code: ",
    suportIR: "Payment using I.R.",
    renewPlan: "Renew My Plan Annually",
    provideSupport: "Finalize Payment",
    installments: "Installments",
    finalisePayment: "Finalize Payment",
    brazilian: "Brazilian",
    other: "Other",
    exportTxt: "Export as TXT",

    //Titulos das Tabs do Formulário de Pagamento
    welcome: "Welcome",
    rewards: "Plan",
    "user-data": "User Details",
    payment: "Payment",

    // langs
    langPt: "Portuguese",
    langEn: "English",

    //Botões
    next: "Next",
    support: "Click here and become a Friend of Inhotim",
    continue: "Continue",

    //Textos soltos
    agreement: "By continuing you recognize that you are accepting our",
    termsAndConditions: "terms and conditions",
    faq: "Fequently Asked Questions",
    year: "year",
    moreInfos0:
      "For more informations, contact us by",
    moreInfos1: "",
    selectYourPlan: "Select your plan",
    notRobot: "I'm not a robot",
    projectName: "Amigos do Inhotim",
    projectDescription: "Free entry without queues by whole year, discounts in services and events at Inhotim, experiences, exclusive content and much more. Being a Friend of Inhotim is to be part of a community connected to the Institute, it's to help the maintenance and sustainability of the Museum and Jardim Botânico. To become part of Inhotim's Friends it's really easy and the total adhesion value can be deducted from the Income tax.",
    chooseAnOption: "Choose an Option",
    parcela: "parcel of",
    parcelas: "parcels of",
    aVista: "in cash",
    juros: "with Interest",
    knowMoreAboutIR: "Know more about payment with Income Tax",

    //Página de Sucesso no Pagamento
    titleBillet: "Billet sent to your email",
    titleCreditCard: "Payment successful",
    labelDownload: "Or you can download it here",

    subTitleBillet:
      "After payment confirmation you will receive an email confirming your membership, it may take up to 72 hours.",
    subTitleCard: "You are now part of Amigos do Inhotim",
    body: "The beneficiary of the ticket generated is the Ebanx Payments / Curitiba-PR",
    buttonDependents: "add dependents",
    billetDownload: "Download Billet",

    importantLabel: "Important",
    importantBillet:
      "The beneficiary of the ticket generated is Ebanx Payments / Curitiba-PR",
    importantCreditCard:
      "The description EBANX*EVOE will appear on your invoice",
    question: "Any questions, send an email to",

    errorSave:
      "Unable to save dependents at this time, please try again later!",
    successSave: "Dependents Saved Successfully.",

    irContributionTitle: "Important advice about I.R contributions",
    irContributionContent: "Contributions that are deducted from the IRPF 2022 (based on the year 2021), have been closed via the system. If you would still like to contribute you can do so via deposit.",
    irContributionSteps: "Follow the step-by-step guide here",
  },
};

import React from "react";

import CardUpdate from "../Components/CardUpdate";

const CreateUpdate = () => {
  return (
    <CardUpdate
      label_update="Nova atualização"
      title="Criar Atualização:"
      label_button="Criar atualização"
    />
  );
};

export default CreateUpdate;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import apiJson from "../api/callApi";

// Components
import PaymentPlan from "../Components/PaymentPlan";
import PaymentUserData from "../Components/PaymentUserData";
import FinalizedPayment from "../Components/FinalizedPayment";
import ContainerOptions from "../Components/ContainerOptions";
import LanguageSwitcher from "../Components/LanguageSwitcher";
import Footer from "../Components/Footer";

import { shade } from "polished";

import projectLogo from "../assets/placeholder-projeto.png";

import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { labelsLanguage } from "../utils/language";
import { useLanguageState } from "../Context/Language";

import { ExternalLink } from "react-external-link";

import Helmet from "react-helmet";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { isIRBlocked } from "../utils/utils";

const customizable_options = [
  {
    key_value: "welcome",
  },
  {
    key_value: "rewards",
  },
  {
    key_value: "user-data",
  },
  {
    key_value: "payment",
  },
];

const Container = styled.div`
  padding: 2%;

  padding-bottom: 50px;

  width: 70%;
  margin: auto;
  margin-top: 45px;
  margin-bottom: 45px;
  background-color: white;

  // shadow na box
  -webkit-box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.59);

  @media (max-width: 1200px) {
    width: 95%;
    //margin: auto;
    margin-top: 2%;
  }

  @media (max-width: 1024px) {
    width: 95%;
    //margin: auto;
    margin-top: 2%;
  }

  .customModal {
    color: red;
  }
`;

const ContainerMobile = styled.div`
  //margin-top: 30px;
  display: none;
  font-family: "Inconsolata", monospace;

  @media (max-width: 1024px) {
    display: block;
    border: 1px solid black;
  }
`;
const ContainerOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const TitleMobile = styled.h3`
  margin: 0;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  cursor: pointer;
`;

const ArrowIconDown = styled(FaChevronDown)`
  padding: 0px 10px;
`;

const ArrowIconRight = styled(FaChevronRight)`
  padding: 0px 10px;
`;

const ContentContainer = styled.div`
  width: 100%;
  //min-height: 40vh;
  border: 0.5px solid black;
  border-top: none;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
  min-height: 623px;
  display: flex;

  @media (max-width: 1024px) {
    border: 0.5px solid black;
    margin-top: 20px;
    display: none;
  }
`;

/*const TextFooter = styled.h1`
  font-size: 1.2rem;
  font-family: Poppins;
  font-weight: 900;
  margin: 0;
  padding-right: 20px;
  display: inline-block;
`;*/

/*const TextFooterRight = styled.h1`
  font-size: 1.2rem;
  font-family: Poppins;
  font-weight: 900;
  margin: 0;
  right: 0;
  float: left;
`;*/

const ButtonSubscribe = styled.button`
  margin: 0;
  margin-top: 50px;
  font-size: 1.3rem;
  background-color: #148a80;
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
  font-weight: bold;
  color: white;
  min-height: 65px;

  // Transição
  transition: background-color 0.3s;

  // Hover no botão
  &:hover {
    background-color: ${shade(0.2, "#148a80")};
  }

  @media (max-width: 1024px) {
    //width: 100%;
  }
  margin-bottom: 44px;
`;

const ContainerWelcomeTab = styled.div`
  //border: 1px solid red;
  width: 100%;
  min-height: 50vh;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  //flex-wrap: wrap;

  //padding: 50px 0; // padding no topo

  // Tela menor que 800px
  @media (max-width: 800px) {
    margin-bottom: 50px;
  }

  // div filho
  div.info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    //border: 1px solid red;
    padding: 30px; // padding ao redor da page

    // tornar em lista quando no celular
    @media (max-width: 1024px) {
      margin-top: 0;
      flex-direction: column-reverse;

      div.project {
        min-width: 100%;
      }
    }

    div.project {
      //border: 1px solid red;
      width: 55%;

      h1 {
        font-size: 50px;
        text-transform: uppercase;
        font-family: "Inconsolata", monospace;
        font-weight: bold;
      }

      div.description {
        font-family: "Inconsolata";
        font-size: 15px;
        line-height: 1.2rem;
      }

      a {
        margin-left: 5px;
        color: #f97778;
        text-decoration: none;

        // Remover decorations do link
        &:hover,
        &:visited,
        &:focus,
        &:active {
          color: #f97778;
          text-decoration: inherit;
        }

        &:hover {
          color: black;
          font-weight: bold;
        }
      }
    }
  }
`;

const LogoArea = styled.div`
  //border: 1px solid orange;

  flex-direction: column;
  text-align: center;
  padding-top: 11px;

  min-width: 300px;
  height: fit-content;

  //flex-wrap: wrap;

  img {
    width: 300px;
  }

  h1 {
    color: #3aa479;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  // changes the logo on smaller devices
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 20px;

    img {
      width: 150px;
    }
  }
`;

/*const changeTab = (tab) => {
  this.setActiveValue(tab);
};*/

const WebPaymentPage = ({ location }) => {
  const [activeValue, setActiveValue] =
    useState("welcome"); /*useState("welcome");*/
  const [projectId, setProjectId] = useState();
  const [isProjectValid, setIsProjectValid] = useState(false);
  const { language } = useLanguageState();

  const [irModalOpen, setIrModalOpen] = useState(false);

  /**
   * Cuida da parte de troca de tab
   * @param {string} key A tab que estamos clicando
   */
  const handleChangeOption = (key) => {
    // Evitar que a aba bugue tentando clicar na
    if (activeValue === key) {
      //console.log("Não precisa trocar aba pois já é a mesma");
      return;
    }

    // Cuida do bloqueio de IR
    const paymentMethod = localStorage.getItem("paymentMethod");
    if (
      key === "payment" &&
      activeValue !== key &&
      isIRBlocked(paymentMethod)
    ) {
      setIrModalOpen(true);
    }

    // evitar q a pessoa só transicione da aba de bem vindo caso clique no botão
    //if (key !== "welcome" && activeValue === "welcome") return;

    //if (activeValue === "user-data" && key === "payment") return;

    key === activeValue ? setActiveValue() : setActiveValue(key);
  };

  /**
   * Checa se o projeto é válido
   * @param {number} project_id id do projeto
   */
  const validateProject = async (project_id) => {
    //console.log(project_id);
    const result = await apiJson.getValidateProject(project_id);
    result.data && setIsProjectValid(true);
  };

  // Welcome tab
  const WelcomeTab = ({ projectId, handleChangeOption }) => {
    return (
      <ContainerWelcomeTab>
        <div className="info-container">
          <div className="project">
            <h1>{labelsLanguage[language]["projectName"]}</h1>
            <div className="description">
              {labelsLanguage[language]["projectDescription"]}
            </div>
            <ButtonSubscribe
              onClick={() => {
                setActiveValue("rewards");
                handleChangeOption("rewards");
              }}
            >
              {labelsLanguage[language]["support"]}
            </ButtonSubscribe>
            {labelsLanguage[language]["moreInfos0"]}
            <ExternalLink href="mailto:amigos@inhotim.org.br">
              <span>amigos@inhotim.org.br</span>
            </ExternalLink>
            {labelsLanguage[language]["moreInfos1"] !== "" &&
              labelsLanguage[language]["moreInfos1"] + ":"}
            <ExternalLink href="https://wa.me/31997652470">
              <span>
                {labelsLanguage[language]["moreInfos1"] !== "" && (
                  <>(31) 99765-2470</>
                )}
              </span>
            </ExternalLink>
          </div>
          {/**<ProjectLogo
            img={projectLogo}
            name={"Amigos do INHOTIM"}
            imgSize={{ width: "350px", height: "85%" }}
            fontSize={" 2em"}
          />**/}
          <LogoArea>
            <img alt="alt" src={projectLogo} />
            <h1>{labelsLanguage[language]["projectName"]}</h1>
          </LogoArea>
        </div>
      </ContainerWelcomeTab>
    );
  };

  useEffect(() => {
    const pathname = location.pathname;
    setProjectId(pathname.split("/")[2]);
    validateProject(pathname.split("/")[2]);
  }, []);

  return (
    <>
      <Helmet>
        <title>Amigos do Inhotim</title>
        {/* Add o background nessas paginas */}
        <style>{`body {background-position:0;}`}</style>
      </Helmet>

      {isProjectValid && (
        <Container>
          <Modal
            open={irModalOpen}
            onClose={() => setIrModalOpen(false)}
            center
            classNames={{
              modal: "customModal",
            }}
          >
            <h2>{labelsLanguage[language]["irContributionTitle"]}</h2>
            <p>
              {labelsLanguage[language]["irContributionContent"]}
               <span/> <a href="https://suporteevoe.zendesk.com/hc/pt-br/articles/4412897116179">{labelsLanguage[language]["irContributionSteps"]}</a>
            </p>
          </Modal>

          <LanguageSwitcher />
          <ContainerMobile>
            {customizable_options.map((data) => (
              <div key={data.key_value}>
                <ContainerOption>
                  <TitleMobile
                    onClick={() => handleChangeOption(data.key_value)}
                  >
                    {labelsLanguage[language][data.key_value]}
                  </TitleMobile>
                  {data.key_value === activeValue ? (
                    <ArrowIconDown />
                  ) : (
                    <ArrowIconRight />
                  )}
                </ContainerOption>
                {activeValue === "welcome" &&
                  data.key_value === activeValue && (
                    <WelcomeTab
                      handleChangeOption={handleChangeOption}
                      projectId={projectId}
                    />
                  )}
                {activeValue === "rewards" &&
                  data.key_value === activeValue && (
                    <PaymentPlan
                      handleChangeOption={handleChangeOption}
                      projectId={projectId}
                    />
                  )}
                {activeValue === "user-data" &&
                  data.key_value === activeValue && (
                    <PaymentUserData handleChangeOption={handleChangeOption} />
                  )}
                {activeValue === "payment" &&
                  data.key_value === activeValue && (
                    <FinalizedPayment
                      handleChangeOption={handleChangeOption}
                      setIrModalOpen={setIrModalOpen}
                    />
                  )}
              </div>
            ))}
          </ContainerMobile>

          <ContainerOptions
            options={customizable_options}
            activeValue={activeValue}
            handleChangeOption={handleChangeOption}
          />

          <ContentContainer>
            {activeValue === "welcome" && (
              <WelcomeTab
                handleChangeOption={handleChangeOption}
                projectId={projectId}
              />
            )}
            {activeValue === "rewards" && (
              <PaymentPlan
                handleChangeOption={handleChangeOption}
                projectId={projectId}
              />
            )}
            {activeValue === "user-data" && (
              <PaymentUserData handleChangeOption={handleChangeOption} />
            )}
            {activeValue === "payment" && (
              <FinalizedPayment
                handleChangeOption={handleChangeOption}
                setIrModalOpen={setIrModalOpen}
              />
            )}
          </ContentContainer>
          <Footer />
        </Container>
      )}
    </>
  );
};

export default WebPaymentPage;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 50px;
`;

const Text = styled.p`
  margin: 0px;
  font-family: "Poppins", sans-serif;
`;

const Button = styled.button`
  margin-top: 10px;
  color: white;
  background-color: black;
  border: none;
  padding: 5px 30px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;

  &:hover {
    box-shadow: 0px 0px 5px black;
  }
`;

const Contact = () => {
  return (
    <Container>
      <Text>Tem uma pergunta para nós?</Text>
      <Button>Fale conosco</Button>
    </Container>
  );
};

export default Contact;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 32%;
  height: 230px;
  margin: 20px 0;
  margin-right: 10px;
  display: block;
  border: 0.5px solid black;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  box-shadow: 5px 5px 10px #00000029;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Card = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Card;

import React from "react";
import styled from "styled-components";
import { ExternalLink } from "react-external-link";

const Container = styled.h1`
  font-size: 1.2rem;
  //font-weight: 900;
  margin: 0;
  right: 0;
  float: left;

  margin-top: 9px;
  font-family: "Inconsolata";
  font-weight: normal;

  a {
    text-decoration: none;
    color: black;

    // Expandir area clicável do botão
    display: block;
    width: 100%;
    height: 100%;

    // Remover decorations do link
    &:hover,
    &:visited,
    &:focus,
    &:active {
      color: inherit; 
      text-decoration: inherit; 
    }

    // Deixar o texto evoe.cc em negrito
    span {
      font-weight: bold;
      font-family: "Poppins";
      font-weight: 900;
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <ExternalLink href="https://www.evoe.cc">
        Powered by <span>evoe.cc</span>
      </ExternalLink>
    </Container>
  );
};

export default Footer;

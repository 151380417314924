import React from "react";
import styled from "styled-components";

import { useUserObjectData } from "../Context/UserObjectData";

const Title = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
`;

const SubTitle = styled.p`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 100;
  font-family: Verdana, sans-serif;
`;

const Greetings = ({ has_subtitle = true }) => {
  const { userObjectData } = useUserObjectData();
  return (
    <>
      <Title>Oi, {userObjectData.name}</Title>
    </>
  );
};

export default Greetings;

import React, { useState } from "react";
import styled from "styled-components";
import { AiOutlineForm } from "react-icons/ai";
import { mask, unMask } from "remask";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.margin_top};
  margin-bottom: ${(props) => props.margin_bottom};
  width: ${(props) => props.width};

  @media (max-width: 920px) {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
    padding: 10px;
  }
`;

const LabelInput = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "Inconsolata", monospace;

  @media (max-width: 920px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const LabelError = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  color: red;
  @media (max-width: 920px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid black;
  font-size: 1.2rem;
  padding: 10px 10px;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
`;

const ContainerInput = styled.div`
  width: 100%;
  position: relative;
`;

const IconEdit = styled(AiOutlineForm)`
  position: absolute;
  top: 0;
  right: 10px;
  top: 10px;
`;

const FormField = ({
  registro,
  name,
  has_icon = true,
  label = null,
  errorMsg = "",
  width = "100%",
  margin_top = "0",
  margin_bottom = "0",
  type = "text",
  placeholder,
  readOnly= false,
  maskParser = [],
  defaultValue = "",
  setValueFormState = () => {},
  handleCep,
}) => {
  const [value, setValue] = useState(() => {
    const newValue = maskParser.length
      ? mask(defaultValue, maskParser)
      : defaultValue;
    setValueFormState(name, newValue);

    return newValue;
  });

  const onChange = (event, name) => {
    if (maskParser.length) {
      const valueWithMask = mask(unMask(event.target.value), maskParser);

      setValue(valueWithMask);
      setValueFormState(name, valueWithMask);
    } else {
      setValueFormState(name, event.target.value);
      setValue(event.target.value);
    }

    if (handleCep) {
      handleCep(unMask(event.target.value));
    }
  };

  return (
    <Container
      width={width}
      margin_top={margin_top}
      margin_bottom={margin_bottom}
    >
      {label && <LabelInput>{label}</LabelInput>}
      <ContainerInput>
        <Input
          type={type}
          placeholder={placeholder === undefined ? "" : placeholder}
          {...registro(name)}
          value={value}
          readOnly={readOnly}
          onChange={(event) => onChange(event, name)}
        />
        {errorMsg && <LabelError>{errorMsg}</LabelError>}
      </ContainerInput>
    </Container>
  );
};

export default FormField;

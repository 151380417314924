import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaPlus } from "react-icons/fa";

import FormField from "./FormField";
import apiJson from "../api/callApi";

const Container = styled.form`
  width: 100%;

  padding: 2% 0;

  @media (max-width: 800px) {
    border: 1px solid black;
    margin-bottom: 50px;
  }
`;

const Title = styled.h4`
  margin: 20px 3%;
  font-size: 2rem;

  @media (max-width: 800px) {
    margin-top: 0;
  }
`;

const SubTitle = styled.h2`
  margin: 20px 5%;
`;

const Button = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #544bff;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  width: 15%;
  margin-left: 30px;
  margin-top: 15px;

  @media (max-width: 800px) {
    width: 90%;
    margin-bottom: 20px;
    margin-left: 5%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
`;

const FormProfileProject = ({ handleData, defaultValues = {} }) => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: defaultValues,
  });

  const submitForm = async (data) => {
    handleData(data);
  };
  return (
    <Container onSubmit={handleSubmit(submitForm)}>
      <Title>Dados Titular do Projeto</Title>

      <FormContainer>
        <FormField
          label="Nome"
          width="70%"
          margin_bottom="10px"
          name={"nome"}
          registro={register}
        />

        <FormField
          label="CPF/CNPJ"
          width="70%"
          margin_bottom="10px"
          name={"cpf"}
          registro={register}
        />
      </FormContainer>
      <SubTitle>Endereço:</SubTitle>
      <FormContainer>
        <FormField
          label="CEP:"
          width="28%"
          margin_bottom="10px"
          name={"cep"}
          registro={register}
        />
        <FormField
          label="Endereço (Rua, Avenida, etc...):"
          width="68%"
          margin_bottom="10px"
          name={"logradouro"}
          registro={register}
        />
        <FormField
          label="Número:"
          width="28%"
          margin_bottom="10px"
          name={"numero"}
          registro={register}
        />
        <FormField
          label="Complemento:"
          width="32%"
          margin_bottom="10px"
          name={"complemento"}
          registro={register}
        />
        <FormField
          label="Bairro:"
          width="32%"
          margin_bottom="10px"
          name={"complemento"}
          registro={register}
        />
        <FormField
          label="Cidade:"
          width="64%"
          margin_bottom="10px"
          name={"cidade"}
          registro={register}
        />
        <FormField
          label="Estado:"
          width="32%"
          margin_bottom="10px"
          name={"estado"}
          registro={register}
        />
      </FormContainer>

      <Button>Salvar</Button>
    </Container>
  );
};

export default FormProfileProject;

import {
  FaSignal,
  FaUserEdit,
  FaPen,
  FaAddressCard,
  FaSearch,
  FaShareSquare,
  FaUsers,
  FaUser,
  FaMoneyBillAlt,
  FaCog,
  FaSlidersH,
  FaTv,
} from "react-icons/fa";

import { AiOutlineLogout } from "react-icons/ai";

const data_navLinks = [
  {
    member: [
      {
        id: 1,
        Icon: FaSignal,
        label: "Transações",
        url: "/transacoes",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 2,
        Icon: FaUserEdit,
        label: "Editar Perfil",
        url: "/editar-perfil",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
      {
        id: 3,
        Icon: FaPen,
        label: "Editar Apoio",
        url: "/editar-apoio",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
      {
        id: 4,
        Icon: FaAddressCard,
        label: "Carteirinha",
        urls: [],
        url: "/carteirinha",
        is_exact: true,
        is_multiple: false,
      },
      {
        id: 5,
        Icon: AiOutlineLogout,
        label: "Logout",
        url: "/logout",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
    ],

    staff: [
      /*{
        id: 1,
        Icon: FaTv,
        label: "Dashboard",
        url: "/",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },*/
      {
        id: 1,
        Icon: FaSearch,
        label: "Busca CPF",
        url: "/busca-cpf",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
      {
        id: 2,
        Icon: FaSignal,
        label: "Transações",
        url: "/transacoes",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 3,
        Icon: FaShareSquare,
        label: "Entradas",
        url: "/entradas",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 4,
        Icon: FaUsers,
        label: "Usuários",
        url: "/usuarios",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 5,
        Icon: FaUser,
        label: "Colaboradores",
        url: "/colaboradores",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 6,
        Icon: FaUserEdit,
        label: "Editar Perfil",
        url: "/editar-perfil",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
      {
        id: 7,
        Icon: FaMoneyBillAlt,
        label: "Financeiro",
        url: "/financeiro",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 8,
        Icon: AiOutlineLogout,
        label: "Logout",
        url: "/logout",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
    ],
    admin: [
      /*{
        id: 1,
        Icon: FaTv,
        label: "Dashboard",
        url: "/",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },*/
      {
        id: 1,
        Icon: FaSearch,
        label: "Busca CPF",
        url: "/busca-cpf",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
      {
        id: 3,
        Icon: FaSignal,
        label: "Transações",
        url: "/transacoes",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 2,
        Icon: FaShareSquare,
        label: "Entradas",
        url: "/entradas",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 3,
        Icon: FaUsers,
        label: "Usuários",
        url: "/usuarios",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 4,
        Icon: FaUser,
        label: "Colaboradores",
        url: "/colaboradores",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 5,
        Icon: FaUserEdit,
        label: "Editar Perfil",
        url: "/editar-perfil",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
      {
        id: 6,
        Icon: FaSlidersH,
        label: "Customização",
        url: "/customizacao",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 7,
        Icon: FaCog,
        label: "Configurações",
        url: "/configuracoes",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 8,
        Icon: FaMoneyBillAlt,
        label: "Financeiro",
        url: "/financeiro",
        urls: [],
        is_exact: false,
        is_multiple: false,
      },
      {
        id: 9,
        Icon: AiOutlineLogout,
        label: "Logout",
        url: "/logout",
        urls: [],
        is_exact: true,
        is_multiple: false,
      },
    ],
  },
];

export default {
  data_navLinks,
};

import Lottie from "react-lottie";
import animation_data from "../json_objects/animations/loading_json.json";
import styled from "styled-components";

const ContainerLoading = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    width:100%;
    heigth: 100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
`;

const defaultOptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animation_data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
};

const LoadingComponent = ({ 
    heigth=400, 
    width=400 
}) => {
    return (
        <ContainerLoading>
            <Lottie 
                options={defaultOptionsLottie} 
                height={heigth} 
                width={width}
            />
        </ContainerLoading>
    );
};

export default LoadingComponent;
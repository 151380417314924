import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1px solid ${(props) => props.color};
  margin-bottom: 5%;
  padding: 20px 10px;
  box-sizing: border-box;

  @media (max-width: 1180px) {
    min-width: 49%;
  }

  @media (max-width: 1000px) {
    min-width: 100%;
  }
`;

const ContainerDetailsCard = ({
  children,
  color = "#000000",
  width = "46%",
  height = "auto",
  min_heigth = "auto",
}) => {
  return (
    <Container
      color={color}
      height={height}
      width={width}
      min_heigth={min_heigth}
    >
      {children}
    </Container>
  );
};

export default ContainerDetailsCard;

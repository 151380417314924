import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: ${(props) => (props.primary ? "white" : "black")};
  border: 0.5px solid black;
  width: 14%;
  cursor: pointer;
  padding: 3px 0;
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => (props.primary ? "black" : "white")};
  font-family: "Poppins", sans-serif;

  &.active {
    background-color: black;
    color: white;
  }

  &:hover {
    background-color: black;
    color: white;
  }


  @media (max-width: 800px) {
    width: 48%;
    margin-bottom: 20px;
    padding: 5px 0;
  }
`;

const FilterButton = ({
  activeValue,
  label,
  primary,
  handleClickBtn,
  value,
}) => {
  return (
    <Button
      className={label === activeValue && "active"}
      onClick={() => handleClickBtn(label, value)}
      primary={primary}
    >
      {label}
    </Button>
  );
};

export default FilterButton;

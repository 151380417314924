import React from "react";
import styled from "styled-components";

import FormLogin from "../Components/FormLogin";

import Footer from "../Components/Footer";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  margin: 25px;

  @media (max-width: 600px) {
    padding: 80px 0;
  }
`;

/*const Footer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  left: 0;
  margin-bottom: 20px;
  margin-left: 20px;

  @media (max-width: 980px) {
    justify-content: center;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
    position: relative;
  }
`;*/

/*const Logo = styled.img`
  width: 100px;
  height: auto;

  @media (max-width: 980px) {
    width: 25%;
  }

  @media (max-width: 600px) {
    width: 40%;
    height: auto;
  }
`;

const TextFooter = styled.h1`
  margin: 0;
  margin-right: 10px;
  font-size: 1em;
  font-family: Inconsolata;
`;*/

const Login = () => {
  return (
    <Container>
      <FormLogin />
      <Footer/>
    </Container>
  );
};

export default Login;
